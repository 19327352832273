import { ReactComponent as ALERT_HANDSUP } from './icons-2_export_ALERT-HANDSUP-06.svg'
import { ReactComponent as HANGUP } from './icon-2_export-HANGUP-RED.svg'
import { ReactComponent as ABORT } from './icons-2_export-ABORT-23.svg'
import { ReactComponent as ADD } from './icons-2_export-ADD-27.svg'
import { ReactComponent as ALERT } from './icons-2_export-ALERT-06.svg'
import { ReactComponent as ANONYMOUS } from './icons-2_export-ANONYMOUS-33.svg'
import { ReactComponent as ATTACH } from './icons-2_export-ATTACH-21.svg'
import { ReactComponent as AUDIO_HEADSET } from './icons-2_export-AUDIO-HEADSET-61.svg'
import { ReactComponent as CALENDER_BLACK } from './icons-2_export-CALENDER-BLACK.svg'
import { ReactComponent as CALENDER_WHITE } from './icons-2_export-CALENDER-WHITE.svg'
import { ReactComponent as CALL } from './icons-2_export-CALL-60.svg'
import { ReactComponent as CAMERA } from './icons-2_export-CAMERA-66.svg'
import { ReactComponent as CIRCLE } from './icons-2_export-CIRCLE.svg'
import { ReactComponent as CLOCK_BLACK } from './icons-2_export-CLOCK-BLACK.svg'
import { ReactComponent as CLOCK_WHITE } from './icons-2_export-CLOCK-WHITE.svg'
import { ReactComponent as CLOSE } from './close.svg'
import { ReactComponent as CLOSE_RED } from './icons-2_export-CLOSE-RED.svg'
import { ReactComponent as CURRENCY } from './icons-2_export-CURRENCY-34.svg'
import { ReactComponent as DOWN } from './down.svg'
import { ReactComponent as DOWNLOAD } from './download.svg'
import { ReactComponent as EXCHANGE } from './icons-2_export-EXCHANGE-35.svg'
import { ReactComponent as FACEBOOK } from './icons-2_export-FACEBOOK-51.svg'
import { ReactComponent as FAVOURITES } from './icons-2_export-FAVOURITES-22.svg'
import { ReactComponent as FAVOURITES_WHITE } from './icons-2_export-FAVOURITES-WHITE.svg'
import { ReactComponent as FAVOURITES_fill } from './icons-2_export-FAVOURITES-fill-50.svg'
import { ReactComponent as FULLSCREEN } from './icons-2_export-FULLSCREEN-64.svg'
import { ReactComponent as HANDSUP } from './icons-2_export-HANDSUP-68.svg'
import { ReactComponent as HANDSUP_FILLED } from './icons-2_export-HANDSUP-FILLED.svg'
import { ReactComponent as HD } from './icons-2_export-HD-62.svg'
import { ReactComponent as IMAGE } from './icons-2_export-IMAGE-29.svg'
import { ReactComponent as INFO_BLACK } from './icon-2_export-INFO-BLACK.svg'
import { ReactComponent as INFO_WHITE } from './icon-2_export-INFO-WHITE.svg'
import { ReactComponent as INSTA } from './icons-2_export-INSTA-52.svg'
import { ReactComponent as LANG_A } from './icons-2_export-LANG-A-24.svg'
import { ReactComponent as LANG_B } from './icons-2_export-LANG-B-25.svg'
import { ReactComponent as LINKEDIN } from './icons-2_export-LINKEDIN-54.svg'
import { ReactComponent as LIVESTREAM } from './icons-2_export-LIVESTREAM-65.svg'
import { ReactComponent as LOCKED } from './icons-2_export-LOCKED-28.svg'
import { ReactComponent as LOGOUT } from './icons-2_export-LOGOUT-13.svg'
import { ReactComponent as MAIL } from './icons-2_export-MAIL-31.svg'
import { ReactComponent as MESSAGES_video } from './icons-2_export-MESSAGES-video-59.svg'
import { ReactComponent as MIC_OFF } from './icons-2_export-MIC-OFF-15.svg'
import { ReactComponent as MIC_OFF_RED } from './icons-2_export-MIC-OFF-RED.svg'
import { ReactComponent as MIC_ON } from './icons-2_export-MIC-ON-14.svg'
import { ReactComponent as MIC_ON_RED } from './icons-2_export-MIC-ON-RED.svg'
import { ReactComponent as MOBILE } from './icons-2_export-MOBILE-20.svg'
import { ReactComponent as MYEVENTS } from './icons-2_export-MYEVENTS-55.svg'
import { ReactComponent as PRINT } from './icon-PRINT.svg'
import { ReactComponent as QUESTION } from './icons-2_export-QUESTION-40.svg'
import { ReactComponent as REMINDER } from './icons-2_export-REMINDER-10.svg'
import { ReactComponent as REMINDER_FILLED } from './icon-2_export-REMINDER-FILLED.svg'
import { ReactComponent as SCREEN } from './icons-2_export-SCREEN-19.svg'
import { ReactComponent as SCREENSHARE } from './icons-2_export-SCREENSHARE-63.svg'
import { ReactComponent as SEND } from './icons-2_export-SEND-08.svg'
import { ReactComponent as SHARE } from './icons-2_export-SHARE-07.svg'
import { ReactComponent as SORTING } from './icons-2_export-SORTING-41.svg'
import { ReactComponent as SOUND_MUTE } from './icons-2_export-SOUND-MUTE-01.svg'
import { ReactComponent as SOUND_ON_BLACK } from './icons-2_export-SOUND-ON-BLACK.svg'
import { ReactComponent as SOUND_ON_WHITE } from './icons-2_export-SOUND-ON-WHITE.svg'
import { ReactComponent as TICK } from './icons-2_export-TICK-32.svg'
import { ReactComponent as TICK_GREEN } from './icons-2_export-TICK-GREEN.svg'
import { ReactComponent as TRASH_DELETE } from './icons-2_export-TRASH-DELETE-17.svg'
import { ReactComponent as TWITTER } from './icons-2_export-TWITTER-53.svg'
import { ReactComponent as UP } from './up.svg'
import { ReactComponent as UPLOAD } from './icons-2_export-UPLOAD-30.svg'
import { ReactComponent as USER_BLACK } from './icons-2_export-USER-BLACK.svg'
import { ReactComponent as USER_WHITE } from './icons-2_export-USER-WHITE.svg'
import { ReactComponent as VIDEO_BLACK } from './icons-2_export-VIDEO-BLACK.svg'
import { ReactComponent as VIDEO_WHITE } from './icons-2_export-VIDEO-WHITE.svg'
import { ReactComponent as VIDEO_MUTED } from './icons-2_export-VIDEO-MUTED.svg'
import { ReactComponent as VIEW } from './icons-2_export-VIEW-56.svg'
import { ReactComponent as EXITFULLSCREEN } from './icon-2_export-EXITFULLSCREEN.svg'
import { ReactComponent as CREATOR } from './icons-2_export-CREATOR-42.svg'
import { ReactComponent as COLLABORATORS } from './icons-2_export-COLLABORATORS-43.svg'
import { ReactComponent as MESSAGES } from './icons-2_export-MESSAGES-45.svg'
import { ReactComponent as MESSAGES_UNREAD } from './icon-2_export-UNREAD-MESSAGE.svg'
import { ReactComponent as ADMIN } from './admin.svg'
import { ReactComponent as AVATAR_PLACEHOLDER } from './avatar_placeholder.svg'
import { ReactComponent as CANCEL } from './cancel.svg'
import { ReactComponent as CANCELLATION_POLICY } from './cancellation_policy.svg'
import { ReactComponent as COMMENT } from './comment.svg'
import { ReactComponent as EDIT } from './edit.svg'
import { ReactComponent as EMAIL } from './email.svg'
import { ReactComponent as EVENTS } from './events.svg'
import { ReactComponent as HELP } from './help.svg'
import { ReactComponent as MEETINGS } from './meetings.svg'
import { ReactComponent as SETTINGS } from './settings.svg'
import { ReactComponent as TERMS_CONDITIONS } from './terms_conditions.svg'
import { ReactComponent as TRANSACTION } from './transaction.svg'
import { ReactComponent as USERS_SLASH } from './users_slash.svg'
import { ReactComponent as USERS } from './users.svg'
import { ReactComponent as RIGHT } from './right.svg'
import { ReactComponent as LEFT } from './left.svg'
import { ReactComponent as SEARCH } from './search.svg'
import { ReactComponent as GLOBE } from './globe.svg'
import { ReactComponent as FILTER } from './filter.svg'
import { ReactComponent as DELETE } from './delete.svg'
import { ReactComponent as VERIFIED } from './verified.svg'
import { ReactComponent as EYE } from './eye.svg'
import { ReactComponent as EYE_SLASH } from './eye-slash.svg'
import { ReactComponent as UP_AND_DOWN } from './up_and_down.svg'
import { ReactComponent as DONE } from './tick.svg'
import { ReactComponent as FACE_RECOGNITION } from './facial-recognition-scan.svg'
import { ReactComponent as DOUBLE_DOWN } from './DoubleDown.svg'

export const icons = {
  ABORT,
  ADD,
  ADMIN,
  ALERT,
  ALERT_HANDSUP,
  ANONYMOUS,
  ATTACH,
  AUDIO_HEADSET,
  AVATAR_PLACEHOLDER,
  CALENDER_BLACK,
  CALENDER_WHITE,
  CALL,
  CAMERA,
  CANCEL,
  CANCELLATION_POLICY,
  CIRCLE,
  CLOCK_BLACK,
  CLOCK_WHITE,
  CLOSE,
  CLOSE_RED,
  COLLABORATORS,
  COMMENT,
  CREATOR,
  CURRENCY,
  DELETE,
  DOWN,
  DONE,
  DOUBLE_DOWN,
  DOWNLOAD,
  EDIT,
  EMAIL,
  EVENTS,
  EXCHANGE,
  EXITFULLSCREEN,
  EYE,
  EYE_SLASH,
  FACE_RECOGNITION,
  FACEBOOK,
  FAVOURITES,
  FAVOURITES_WHITE,
  FAVOURITES_fill,
  FILTER,
  FULLSCREEN,
  GLOBE,
  HANDSUP,
  HANDSUP_FILLED,
  HANGUP,
  HD,
  HELP,
  IMAGE,
  INFO_BLACK,
  INFO_WHITE,
  INSTA,
  LANG_A,
  LANG_B,
  LEFT,
  LINKEDIN,
  LIVESTREAM,
  LOCKED,
  LOGOUT,
  MAIL,
  MEETINGS,
  MESSAGES,
  MESSAGES_UNREAD,
  MESSAGES_video,
  MIC_OFF,
  MIC_OFF_RED,
  MIC_ON,
  MIC_ON_RED,
  MOBILE,
  MYEVENTS,
  PRINT,
  QUESTION,
  REMINDER,
  REMINDER_FILLED,
  RIGHT,
  SCREEN,
  SCREENSHARE,
  SEARCH,
  SEND,
  SETTINGS,
  SHARE,
  SORTING,
  SOUND_MUTE,
  SOUND_ON_BLACK,
  SOUND_ON_WHITE,
  TERMS_CONDITIONS,
  TICK,
  TICK_GREEN,
  TRANSACTION,
  TRASH_DELETE,
  TWITTER,
  UP,
  UP_AND_DOWN,
  UPLOAD,
  USERS,
  USERS_SLASH,
  USER_BLACK,
  USER_WHITE,
  VERIFIED,
  VIDEO_BLACK,
  VIDEO_MUTED,
  VIDEO_WHITE,
  VIEW,
}
