import { useForm } from "react-hook-form";
import { Outlet, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Button } from "../../Components";
import Field from "../../Components/base/field/field";
import {
  useDeleteMeetingDataMutation,
  useGetMeetingByIdQuery,
  useSaveMeetingMutation,
} from "../../Services/meetingApi";
import NavRight from "../Navbar/NavRight";
import Title from "../Navbar/Title";
import Delete from "../../Components/Buttons/Delete";
import { meetingPartcicipantApi } from "../../Services/meetingParticipantApi";
import { useAppDispatch } from "../../Store/hooks";
import { toastSuccess } from "../../Utils/toast";
import { Meeting } from "../../Models/meeting.model";
import { transactionApi } from "../../Services/transactionApi";
import { formatDateToTimezone2, formatTimeToTimezone2 } from "../../Utils/datetime";
import Icon from "../../Components/base/icon/icon";
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from "react";


interface IViewMeeting {
  paidToHost: string;
}

function ViewMeeting() {
  
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<IViewMeeting>();
  const dispatch = useAppDispatch();
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  
  const { state, pathname} = useLocation()
  
  const isViewParticipants =pathname.includes('viewParticipants') 
  const [prevUrll , setPrevUrll] = useState();
 useEffect(() => {
   if(state)
   {
     setPrevUrll(state?.prevUrl)
   }}, [])

  const [saveMeetingData] =
    useSaveMeetingMutation();

  const [deleteMeetingData, { isSuccess: deleteMeetingSuccess }] =
    useDeleteMeetingDataMutation();
  const {
    data: meetingData,
    isLoading: meetingLoading
  } = useGetMeetingByIdQuery(id ? id : "", { skip: !id });

  const missPayment = (id: any) => {
    dispatch(
      meetingPartcicipantApi.endpoints.getMeetingParticpantByMeetingId.initiate(
        id
      )
    ).then((value) => {
      let userId;
      if (value.data) {
        value.data.map((item: any) => {
          userId = item.user.id;
        });
        const args = {
          meetingId: id,
          userId: userId,
        };
        dispatch(
          transactionApi.endpoints.getCheckPaymentById.initiate({ args })
        ).then((values) => {
          if (values.data) {
            if (values.data.length > 0) {
              toastSuccess("No MissPayments Done");
            }
          }
        });
      }
    });
  };
  const isFreeAudioMeeting =
    meetingData?.meetingDetails?.isFreeAudioMeeting === true;

  let meetingDataCopy: any = meetingData
    ? Object.assign({}, meetingData)
    : ({} as Meeting);

  const onSubmit = (values: any) => {
    meetingDataCopy.paidToHost = values?.paidToHost;
    meetingDataCopy.cost = Number(meetingData?.cost);
    saveMeetingData(meetingDataCopy).then((value: any) => {
    });
  };
  if (meetingLoading) return <div>Loading...</div>;

  return (
    <div className="flex flex-col h-screen">
      <div className="h-16 bg-gray-200 flex items-center w-full justify-between p-2">
        <Title title="View Meeting" prePage={prevUrll} />
        <NavRight />
      </div>
      <div className="flex flex-row items-start h-screen w-full  font-semibold mb-4 rounded-lg bg-white overflow-y-auto p-4 ">
        <div className="flex flex-row h-full w-1/2">
          <form onSubmit={handleSubmit(onSubmit)} className="w-full">
            <div className="grid grid-cols-2 gap-y-6">
              <FieldTitle title="Event Title" />
              <div className="relative">
                <div className="absolute w-full h-full z-10">
                {meetingData?.meetingDetails?.videoType === 'Audio' && (
                  <Icon
                    icon="SOUND_ON_BLACK"
                    size="x-small"
                    className="absolute right-2 top-2.5"
                  />
                )}
                {meetingData?.meetingDetails?.videoType === 'Audio And Video' && (
                  <Icon
                  icon="VIDEO_BLACK"
                  size="x-small"
                  className="absolute right-2 top-2.5"
                />
                )}
                </div>
                <Field
                  name="eventTitle"
                  type="text"
                  className="p-2 h-9"
                  defaultValue={meetingData?.meetingDetails?.title}
                  disabled={true}
                />
              </div>

              <FieldTitle title="Hosted By" />
              <div className="relative">
                <div className="absolute w-full h-full z-10">
                  <Icon
                    icon="EYE"
                    size="x-small"
                    className="fill-black absolute right-2 top-2.5"
                    onClick={() => {
                      navigate(`/s/users/userView/${meetingData?.user?.id}`);
                    }}
                  />
                </div>
                <Field
                  name="hostedBy"
                  type="text"
                  className="p-2 h-9 w-full flex flex-1"
                  disabled={true}
                  defaultValue={
                    meetingData?.user.firstName +
                    " " +
                    meetingData?.user.lastName
                  }
                />
              </div>

              <FieldTitle title="Updated Date" />
              <Field
                name="updatedDate"
                type="text"
                className="p-2 h-9"
                defaultValue={
                  meetingData?.updatedAt
                    ? formatDateToTimezone2(
                        timezone,
                        meetingData?.updatedAt?.toString()
                      )
                    : "Null"
                }
                disabled={true}
              />
              <FieldTitle title="Updated Time" />

              <Field
                name="updatedTime"
                type="text"
                className="p-2 h-9"
                defaultValue={
                  meetingData?.updatedAt
                    ? formatTimeToTimezone2(
                        timezone,
                        meetingData?.updatedAt?.toString()
                      )
                    : "Null"
                }
                disabled={true}
              />

              <FieldTitle title="Seats available" />

              <Field
                name="seatsAvailable"
                type="text"
                className="p-2 h-9"
                defaultValue={meetingData?.seatsAvailable}
                disabled={true}
              />

              <FieldTitle title="Scheduled At" />
              <Field
                name="scheduleTime"
                type="text"
                className="p-2 h-9"
                defaultValue={formatDateToTimezone2(
                  timezone,
                  meetingData?.fromDateTime.toString()
                )}
                disabled={true}
              />

              <FieldTitle title="Start At" />
              <Field
                name="startAt"
                type="text"
                className="p-2 h-9"
                defaultValue={formatTimeToTimezone2(
                  timezone,
                  meetingData?.fromDateTime.toString()
                )}
                disabled={true}
              />

              <FieldTitle title="End At" />
              <Field
                name="endAt"
                type="text"
                placeholder={"dob"}
                className="p-2 h-9"
                defaultValue={formatTimeToTimezone2(
                  timezone,
                  meetingData?.toDateTime.toString()
                )}
                disabled={true}
              />

              {meetingData?.type === "Type 1" && (
                <>
                  <FieldTitle title="Language" />
                  <Field
                    name="language"
                    type="text"
                    className="p-2 h-9"
                    defaultValue={meetingData?.language}
                    disabled={true}
                  />
                </>
              )}

              <FieldTitle title="Participants" />
              <Field
                name="participants"
                type="text"
                className="p-2 h-9"
                defaultValue={meetingData?.meetingParticipant?.length}
                disabled={true}
              />

              <FieldTitle title="Minimum Participants" />
              <Field
                name="minParticpants"
                type="text"
                className="p-2 h-9"
                defaultValue={meetingData?.minimumNoOfParticipants}
                disabled={true}
              />

              <FieldTitle title="Paid Status" />
              <Field
                defaultValue={meetingData?.paidToHost}
                type="select"
                disabled={true}
                error={(errors?.paidToHost as any)?.message}
                {...register("paidToHost")}
              >
                <option value="">{`Select`}</option>
                <option
                  className="text-green-600 font-medium"
                  value="Yes"
                >{`Yes`}</option>
                <option
                  className="text-red-600 font-medium"
                  value="No"
                >{`No`}</option>
                <option
                  className="text-BeeMG-yellow font-medium"
                  value="Hold"
                >{`Hold`}</option>
                <option
                  className="text-BeeMG-yellow font-medium"
                  value="Processed"
                >{`Processed`}</option>
              </Field>

              <div className="col-span-2 overflow-visible relative">
                <div className="flex absolute gap-2">
                  <Delete
                    mode="Meeting"
                    deleteFunction={deleteMeetingData}
                    id={meetingData ? meetingData?.id : ""}
                    title={meetingData ? meetingData.meetingDetails?.title : ""}
                    success={deleteMeetingSuccess}
                    afterSuccess="/s/meetings"
                  />
                  <Button
                    className="px-5 py-1.5 uppercase whitespace-nowrap"
                    color="warning"
                    size="sm"
                    submit
                  >
                    <span className="font-bold">Save</span>
                  </Button>

                  {meetingData &&
                    meetingData?.noOfParticipants -
                      meetingData?.seatsAvailable >=
                      1 && (
                      <Button
                        className="px-5 py-1.5 uppercase whitespace-nowrap"
                        color="warning"
                        size="sm"
                        disabled={isViewParticipants}
                        onClick={() => navigate("viewParticipants/" + id)}
                      >
                        <span className="font-bold">View Participants</span>
                      </Button>
                    )}

                  {!isFreeAudioMeeting && (
                    <Button
                      className="px-5 py-1.5 uppercase whitespace-nowrap"
                      color="warning"
                      size="sm"
                      onClick={() => missPayment(id)}
                    >
                      <span className="font-bold">
                        Refund for Miss Payments
                      </span>
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>

        <div className="w-1/2 p-1 overflow-scroll h-[100%]">
          <Outlet />
        </div>
      </div>
    </div>
  );
  function FieldTitle({ title }: { title: string }) {
    return <><div className="flex-1 w-1/2 font-semibold">{title} </div></>
  }
}

export default ViewMeeting;