import defaultImage from "../Assets/img/image-logo.png";
import NavRight from "./Navbar/NavRight";
import Title from "./Navbar/Title";
import logo from '../Assets/img/main_logo.svg'


function SignIn() {
  return (
    <div className='flex flex-col h-screen'>
      <div className='h-16 bg-gray-200 flex items-center w-full justify-between p-2'>
        <Title title="Dashboard" />
        <NavRight />
      </div>
      <div className="flex flex-col items-center justify-center h-screen w-full text-2xl font-semibold mb-4 rounded-lg bg-white overflow-auto overflow-x-hidden">
        {/* <div className="text-center"><p className='text-6xl'>BeeMG</p></div> */}
        <img src={logo} alt="BeeMg Admin" className='w-full h-24 px-5' />

        <img src={defaultImage} />
      </div>
    </div>
  )
}

export default SignIn
