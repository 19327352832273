import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import Icon from '../../Components/base/icon/icon';
import useFetchDataHooks from '../../Hooks/FetchDataHooks';
import { useGetUserHistoryQuery } from '../../Services/userApi'
import NavRight from '../Navbar/NavRight'
import Title from '../Navbar/Title'
import TableFormat from '../TableFormat';

function UserHistory() {
  const { id } = useParams();
  const { pageData, isLoading, fetchData, pagination, error } = useFetchDataHooks<any>(useGetUserHistoryQuery, { id: id ? id : '' } as any);

  const defaultColumn = React.useMemo(() => ({
    width: 150,
  }), []);

  const data = useMemo(() => {

    const displayData: any[] = [];
    pageData?.data?.forEach((history: any) => {
      const tempData = { ...history };

      displayData.push({
        col1: tempData.firstName,
        col2: tempData.paymentAccountActive,
        col3: tempData.currency,
        col4: tempData.ipAddress,
        col5: tempData.country ? tempData.country.name : '',
        col6: tempData.phoneNo ? tempData.phoneNo : '',
        col7: tempData.createdAt,
        col8: tempData.updatedBy,
      });
    });
    return displayData;
  }, [pageData]);

  const columns = React.useMemo(() => [{
    Header: 'First Name',
    accessor: 'col1',
    width: 150,
  }, {
    Header: 'Host Bank Active',
    accessor: 'col2',
    width: 150,
  }, {
    Header: 'currency',
    accessor: 'col3',
    width: 150,
  }, {
    Header: 'ipAddress',
    accessor: 'col4',
    width: 150,
  }, {
    Header: 'Country',
    accessor: 'col5',
    width: 150,
  }, {
    Header: 'PhoneNo',
    accessor: 'col6',
    width: 150,
  }, {
    Header: 'Created At',
    accessor: 'col7',
    width: 150,
  }, {
    Header: 'Updated By',
    accessor: 'col8',
    width: 150,
  },

  ], []);

  if (isLoading) {
    return <> "Loading..."</>
  }

  return (
    <div className='flex flex-col h-screen'>
      <div className='h-16 bg-gray-200 flex  w-full justify-between p-2'>
        <Title title="User History" prePage='/s/users' />
        <NavRight />
      </div>
      <div className="items-center justify-center h-screen w-full font-semibold mb-4 rounded-lg bg-white overflow-auto overflow-x-hidden">
        {((pageData?.data?.length === 0) || pageData === undefined) && <div className="h-full flex justify-center items-center text-lg">No Data to Display</div>}
        {pageData?.data?.length > 0 && <TableFormat columns={columns} data={data} defaultColumn={defaultColumn} fetchData={fetchData} loading={isLoading} page={pagination} error={error} />}
      </div>
    </div>
  )
}

export default UserHistory