import React from 'react'
import { Action, ActionType } from '../../Components/Action';
import { ComponentType } from '../../Components/Buttons/Delete';
import useFetchDataHooks from '../../Hooks/FetchDataHooks';
import { ReportStatus } from '../../Models/report.model';
import { useGetAllReportsQuery } from '../../Services/reportApi';
import { formatDateToTimezone2 } from '../../Utils/datetime';
import NavRight from '../Navbar/NavRight'
import Title from '../Navbar/Title'
import TableFormat, { SelectColumnFilter, TextColumnFilter } from '../TableFormat';

function Verification({ status }: { status: any }) {
  return (
    <>
      {status === ReportStatus.Reported &&
        <span className="bg-red-600 text-white text-xs font-semibold mr-2 px-2.5 py-0.5 rounded-lg">{status}</span>
      }
      {status === ReportStatus.UnderReview &&
        <span className="bg-BeeMG-yellow text-xs font-bold mr-2 px-2.5 py-0.5 rounded-lg">Under Review</span>
      }
      {status === ReportStatus.Closed &&
        <span className="bg-green-600 text-white text-xs font-semibold mr-2 px-2.5 py-0.5 rounded-lg">{status}</span>
      }
    </>
  );
}

function ReportedUsers() {
  const { pageData, isLoading, fetchData, pagination, error } = useFetchDataHooks(useGetAllReportsQuery);
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const defaultColumn = React.useMemo(() => ({
    width: 100,
  }), []);

  const columns = React.useMemo(() => [
  {
    Header: 'Reported By',
    accessor: 'reportedBy',
    width: 100,
    Filter: TextColumnFilter,
    filter: 'fuzzyText',
    Cell: ({ row }: any) => row.original.reportedByUser?.firstName + ' ' + row.original.reportedByUser?.lastName,
  },
  {
    Header: 'Date',
    accessor: 'reportedDate',
    width: 80,
    Filter: TextColumnFilter,
    filter: 'fuzzyText',
    Cell: ({ row }: any) => formatDateToTimezone2(timezone, row.original.createdAt),
  },
  {
    Header: 'Report Status',
    accessor: 'status',
    width: 80,
    Filter: TextColumnFilter,
    filter: 'fuzzyText',

    Cell: ({ row: { original } }: any) => <Verification status={original.reportStatus} />
  },
  {
    Header: 'Action',
    accessor: 'action',
    width: 80,
    Filter: SelectColumnFilter,

  },
  {
    Header: <div className='pb-8'> View Profile</div>,
    accessor:'view profile',
    width: 80,
    Cell: ({ row: { original } }: any) => (<Action id={original.id} types={[ActionType.View]} navigation='s/reportedUsers/view' title="view" componenetType={ComponentType.Button} />),

  }], []);
  return (
    <div className='flex flex-col h-screen'>
      <div className='h-16 bg-gray-200 flex items-center w-full justify-between p-2'>
        <Title icon="USERS_SLASH" title="Reported Users" />
        <NavRight />
      </div>
      <div className="items-center justify-center h-screen w-full font-semibold mb-4 rounded-lg bg-white relative overflow-auto overflow-x-hidden">
        {pageData && (
          <TableFormat
            columns={columns} data={pageData?.data} defaultColumn={defaultColumn} loading={isLoading} fetchData={fetchData}
            page={pagination} error={error}
          />
        )}
      </div>
    </div>
  )
}

export default ReportedUsers