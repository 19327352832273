import { PagedData } from './../Models/pagedData.model';
import { User } from "../Models/user.model";
import { allApis } from "./allApis";
import { UserDetail } from '../Models/userDetail.model';
import { UserHistory } from '../Models/userHistory.model';

export const userApi = allApis.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
        getAllUsers: builder.query<PagedData<User>, any>({
            query: page => {
                return {
                    url: "users",
                    params: page
                };
            },            
            providesTags: ['AdminUserTag'],
        }),
        getUsersById: builder.query<User, any>({
            query: id => {
                return {
                    url: `users/getUser/${id}`,
                };
            },
            providesTags: ['AdminUserByIDTag'],
        }),
        getUsersBySub: builder.query<UserDetail, any>({
            query: sub => {
                return {
                    url: `users/cognito/userDetail/${sub}`,
                };
            }
        }),
        getUserHistory: builder.query<PagedData<UserHistory[]>, any>({
            query: (page) => {
                console.log("idmm",page)
                return {
                    url: `userHistory/viewHistory/${page.id}`,
                    params: page,
                };
            }
        }),
        deleteUserbyId: builder.mutation<boolean, any>({
            query: (args) => {
                const {id} = args;
                return {
                    url: `users/${id}`,
                    method: 'DELETE'
                };
            },
            invalidatesTags: ['AdminUserTag'],
        }),

    })
});

export const { useGetAllUsersQuery, useGetUsersByIdQuery, useGetUsersBySubQuery, useGetUserHistoryQuery, useDeleteUserbyIdMutation } = userApi;
