import { Menu, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { NavLink } from 'react-router-dom'
import Icon from '../../Components/base/icon/icon'

export default function ModeDropdown() {
  return (
    <div className="mt-1.5">
      <Menu as="div" className="relative inline-block text-left">
        <Menu.Button>
          <div className="p-1 hover:bg-slate-100 rounded-full">
            <Icon icon='ADMIN' size='medium' className='fill-black' />
          </div>
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 mt-2 w-52 whitespace-nowrap origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="px-1 py-1 ">
              <Menu.Item>
                <NavLink to={`/s/adminUser`} >
                  <div className="group gap-2 flex w-full items-center rounded-md px-2 py-2 text-sm text-black hover:bg-BeeMG-yellow font-semibold">
                    <Icon icon='ADMIN' size='small' className='fill-black' />
                    <span>Admin</span>
                  </div>
                </NavLink>
              </Menu.Item>
              <Menu.Item>
                <NavLink to={`/s/email`} >
                  <div className="group gap-2 flex w-full items-center rounded-md px-2 py-2 text-sm text-black hover:bg-BeeMG-yellow font-semibold">
                    <Icon icon='EMAIL' size='small' className='fill-black' />
                    <span>Email</span>
                  </div>
                </NavLink>
              </Menu.Item>
              <Menu.Item>
                <NavLink to={`/s/settings`} >
                  <div className="group gap-2 flex w-full items-center rounded-md px-2 py-2 text-sm text-black hover:bg-BeeMG-yellow font-semibold">
                    <Icon icon='SETTINGS' size='small' className='fill-black' />
                    <span>Settings</span>
                  </div>
                </NavLink>
              </Menu.Item>
              <Menu.Item>
                <NavLink to={`/s/TermsAndPolicies`} >
                  <div className="group gap-2 flex w-full items-center rounded-md px-2 py-2 text-sm text-black hover:bg-BeeMG-yellow font-semibold">
                    <Icon icon='TERMS_CONDITIONS' size='small' className='fill-black' />
                    <span>Terms {`&`} Policies</span>
                  </div>
                </NavLink>
              </Menu.Item>
              <Menu.Item>
                <NavLink to={`/s/cancellationPolicy`} >
                  <div className="group gap-2 flex w-full items-center rounded-md px-2 py-2 text-sm text-black hover:bg-BeeMG-yellow font-semibold">
                    <Icon icon='CANCELLATION_POLICY' size='small' className='fill-black' />
                    <span>Cancellation Policies</span>
                  </div>
                </NavLink>
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  )
}
