import { Verified } from "./adminUser.model";
import { CommonEntity } from "./commonEntity.model";
import { User } from "./user.model";


export enum ReportStatus {
  Reported = 'Reported',
  UnderReview = 'UnderReview',
  Closed = 'Closed',
}

export enum Action {
  None = 'None',
  Deleted = 'Profile Deleted',
  Fake = 'Fake Report',
}

export interface Report extends CommonEntity {
  reportedUser: User;

  reportedByUser: User;

  reason: string;

  reportStatus: ReportStatus;

  action: Action;

  verified: Verified;
}
