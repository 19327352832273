import React from 'react'
import { Outlet } from 'react-router-dom';
import { Action, ActionType } from '../../Components/Action';
import useFetchDataHooks from '../../Hooks/FetchDataHooks';
import { useGetAllSettingsListQuery } from '../../Services/settingApi';
import NavRight from '../Navbar/NavRight'
import Title from '../Navbar/Title'
import TableFormat, { TextColumnFilter } from '../TableFormat';

function Settings() {
    const { pageData, isLoading, fetchData, pagination, error } = useFetchDataHooks(useGetAllSettingsListQuery);


    const defaultColumn = React.useMemo(() => ({
    }), []);

    const columns = React.useMemo(() => [
        {
            Header: 'Key',
            accessor: 'key',
            Filter: TextColumnFilter,
            filter: 'fuzzyText',
        }, {
            Header: <div className='pb-8'> Value </div>,
            accessor: 'value',
        }, {
            Header: <div className='pb-8'> Action </div>,
            accessor: 'action',
            Cell: ({ row: { original } }: any) => <Action id={original.id} types={[ActionType.Edit]} />,
            width: 70,
        }], []);

    return (
        <div className='flex flex-col h-screen'>
            <div className='h-16 bg-gray-200 flex items-center w-full justify-between p-2'>
                <Title title="Settings" prePage='/s/home' />
                <NavRight />
            </div>

            <div className="grid grid-cols-12 flex-row lg:flex-col justify-center w-full mb-4 rounded-lg bg-white h-full lg:overflow-x-hidden">
                <div className="lg:col-span-7 col-span-12 border-r">
                    {pageData && (
                        <TableFormat
                            columns={columns} data={pageData?.data} defaultColumn={defaultColumn} loading={isLoading} fetchData={fetchData}
                            page={pagination} error={error}
                        />
                    )}
                </div>
                <div className="lg:col-span-5 col-span-12 p-3"><Outlet /></div>
            </div>
        </div>
    )
}

export default Settings