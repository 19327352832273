import React from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import Button from '../../Components/base/button/button';
import Icon from '../../Components/base/icon/icon';
import { Report, ReportStatus } from '../../Models/report.model';
import { FaceApi } from '../../Services/FaceApi';
import { useGetAllReportedUserByIdQuery, useUpdateFakeProfileMutation, useUpdateFakeReportMutation, useUpdateVerifyProfileMutation } from '../../Services/reportApi';
import { useAppDispatch } from '../../Store/hooks';
import { getUTFormattedDate } from '../../Utils/datetime';
import { toastError, toastSuccess } from '../../Utils/toast';
import NavRight from '../Navbar/NavRight'
import Title from '../Navbar/Title'

function ViewReportedUser() {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { data: reportedUser, isLoading: reportedUserLoading } = useGetAllReportedUserByIdQuery(id ? id : '', { skip: !id });
    const [updateFakeReport] = useUpdateFakeReportMutation();
    const [updateVerifyProfile] = useUpdateVerifyProfileMutation();
    const [updateFakeProfile] = useUpdateFakeProfileMutation();

    const fakeReport = (reportedUser: Report) => {
        updateFakeReport(reportedUser)
    }
    const verifyProfile = (reportedUser: Report) => {
        updateVerifyProfile(reportedUser)
    }
    const fakeProfile = (reportedUser: Report) => {
        updateFakeProfile(reportedUser).then((val: any) => {
            if (val.data) {
                toastSuccess("Profile Blocked!")
            } else {
                toastError("Refund failed")
            }
        })
    }
    const getFaceData = (id: string) => {

        dispatch(FaceApi.endpoints.getFaceById.initiate(id)).then((val) => {
            if (val.data?.status === 200) {
                const uint8array = new Uint8Array(val.data.data.Body.data)
                const blob = new Blob([uint8array], { type: 'application/zip' });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = `${id}.zip`;
                link.click();
                toastSuccess(val.data?.message)
            } else {
                toastError("File Download Failed!")
            }
        });
    }
    if (reportedUserLoading)
        return <div>Loading...</div>

    return (
        <div className='flex flex-col h-screen'>
            <div className='h-16 bg-gray-200 flex items-center w-full justify-between p-2'>
                <Title prePage='/s/reportedUsers' title="View Reported User" />
                <NavRight />
            </div>
            <div className="flex flex-col  h-screen w-full px-5  bg-gray-200 overflow-hidden">
                <div className="bg-white h-full w-full p-10 mb-4 rounded-lg overflow-y-auto">

                    <div className="grid grid-cols-12 gap-y-6 ">
                        <div className="col-span-2 font-semibold">
                            <span>Reported By</span>
                        </div>
                        <div className="col-span-1 font-semibold text-center">:</div>
                        <div className="col-span-9 w-fit flex gap-x-3 items-center">
                            {reportedUser?.reportedByUser && <span>{reportedUser.reportedByUser.firstName} {reportedUser.reportedByUser.lastName}</span>}
                            {reportedUser?.reportedByUser?.verified === 'Complete' && <Icon icon="VERIFIED" color='green' />}
                            {reportedUser?.reportedByUser?.verified === 'Complete' && <span className="font-bold flex cursor-pointer" onClick={() => getFaceData(reportedUser.reportedByUser.id)}> <Icon icon="FACE_RECOGNITION" /></span>}
                            <Button className="text-xs bg-BeeMG-yellow py-1" size='sm' disabled={reportedUser?.action !== "None"} onClick={() => navigate("/s/users/userView/" + reportedUser?.reportedByUser.id + "?back=reported&id=" + id)}>
                                <span className="font-bold">View Reporters Profile</span>
                            </Button>
                        </div>
                        <div className="col-span-2 font-semibold">
                            <span>Reported Profile User</span>
                        </div>
                        <div className="col-span-1 font-semibold text-center">:</div>
                        <div className="col-span-9 w-fit flex gap-x-3 items-center">
                            {reportedUser?.reportedUser && <span>{reportedUser.reportedUser.firstName} {reportedUser.reportedUser.lastName}</span>}
                            {reportedUser?.reportedUser?.verified === 'Complete' && <Icon icon="VERIFIED" color='green' />}
                            {reportedUser?.reportedUser?.verified === 'Complete' && <span className="font-bold flex cursor-pointer" onClick={() => getFaceData(reportedUser.reportedUser.id)}> <Icon icon="FACE_RECOGNITION" /></span>}
                        </div>
                        <div className="col-span-2 font-semibold">
                            <span>Reported On</span>
                        </div>
                        <div className="col-span-1 font-semibold text-center">:</div>
                        <div className="flex-wrap col-span-9">
                            {reportedUser && getUTFormattedDate(reportedUser.createdAt.toString())}
                        </div>
                        <div className="col-span-2 font-semibold">
                            <span>Reason </span>
                        </div>
                        <div className="col-span-1 font-semibold text-center">:</div>
                        <div className="flex-wrap col-span-9">
                            {reportedUser?.reason}
                        </div>
                        <div className="col-span-2 font-semibold">
                            <span>Report Status </span>
                        </div>
                        <div className="col-span-1 font-semibold text-center">:</div>
                        <div className="col-span-9 w-fit border rounded-xl p-2">
                            {reportedUser?.reportStatus === ReportStatus.UnderReview ? "Under Review" : reportedUser?.reportStatus}
                        </div>
                        <div className="col-span-2 font-semibold">
                            <span>Action Status</span>
                        </div>
                        <div className="col-span-1 font-semibold text-center">:</div>
                        <div className="col-span-9 w-fit border rounded-xl p-2">
                            {reportedUser?.action}
                        </div>
                    </div>
                    <div className='flex gap-x-3 mt-5 justify-center'>
                        {reportedUser &&
                            <>
                                <Button className="text-xs  bg-BeeMG-yellow py-1" disabled={reportedUser.reportedUser.verified == "Complete"} size='sm' onClick={() => verifyProfile(reportedUser)}>
                                    <span className="font-bold">Verify Profile</span>
                                </Button>
                                <Button className="text-xs  bg-BeeMG-yellow py-1" disabled={reportedUser.action !== "None"} size='sm' onClick={() => fakeReport(reportedUser)}>
                                    <span className="font-bold">Fake Report</span>
                                </Button>
                                <Button className="text-xs  bg-BeeMG-yellow py-1" disabled={reportedUser.action !== "None"} size='sm' onClick={() => fakeProfile(reportedUser)}>
                                    <span className="font-bold">Fake Profile</span>
                                </Button>
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewReportedUser