import { Menu, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import Icon from '../../Components/base/icon/icon'

export default function LanguageDropdown() {
  return (
    <div className="mt-1.5">
      <Menu as="div" className="relative inline-block text-left">
        <Menu.Button>
        <div className="p-1 hover:bg-slate-100 rounded-full"><Icon icon='GLOBE' size='medium' /></div>
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 mt-2 w-44 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="px-1 py-1 ">
              <Menu.Item>
                <div className="group flex w-full items-center rounded-md px-2 py-2 text-sm text-black hover:bg-BeeMG-yellow font-semibold">EN - English US</div>
              </Menu.Item>
              <Menu.Item>
                <div className="group flex w-full items-center rounded-md px-2 py-2 text-sm text-black hover:bg-BeeMG-yellow font-semibold">JP - Japanese</div>
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  )
}
