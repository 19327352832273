import React, { useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom';
import { Action, ActionType } from '../../Components/Action';
import { ComponentType } from '../../Components/Buttons/Delete';
import useFetchDataHooks from '../../Hooks/FetchDataHooks';
import { useGetAllMeetingsQuery } from '../../Services/meetingApi';
import { formatDateToTimezone, formatTimeToTimezone, getUTCformat } from '../../Utils/datetime';
import NavRight from '../Navbar/NavRight'
import Title from '../Navbar/Title'
import TableFormat, { TextColumnFilter } from '../TableFormat';

function Meetings() {
  
  const [upcomingMeetings, setUpcomingMeetings] = useState<boolean>(false)
  const [pastMeetings, setPastMeetings] = useState<boolean>(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const { pageData, isLoading, fetchData, pagination, error } = useFetchDataHooks(useGetAllMeetingsQuery, { upcomingMeetings : upcomingMeetings ? upcomingMeetings : undefined, pastMeetings: pastMeetings ? pastMeetings : undefined } as any);

useEffect(() => {

  if (searchParams?.get('upcomingMeetings') === 'true' && !upcomingMeetings) {
    setUpcomingMeetings(true)
  }
  if (searchParams?.get('pastMeetings') === 'true' && !pastMeetings) {
    setPastMeetings(true)
  }

}, [searchParams])

useEffect(() => {

  if (pastMeetings) {
    searchParams.set('pastMeetings', 'true')
  } else {
    searchParams.delete('pastMeetings')
  }

  if (upcomingMeetings) {
    searchParams.set('upcomingMeetings', 'true')
  } else {
    searchParams.delete('upcomingMeetings')
  }
  setSearchParams(searchParams)
}, [pastMeetings, upcomingMeetings])

  const data = useMemo(() => {
    const displayData: any[] = [];

    pageData?.data?.forEach((meeting: any) => {
      const MeetingsData = { ...meeting };

      displayData.push({
        firstName: MeetingsData.user.firstName,
        title: MeetingsData.meetingDetails?.title,
        utcDateTime: getUTCformat(MeetingsData.fromDateTime, MeetingsData.toDateTime),
        hostDateTime: formatDateToTimezone(MeetingsData.user.preference.timezone, MeetingsData.fromDateTime) + formatTimeToTimezone(MeetingsData.user.preference.timezone, MeetingsData.fromDateTime) + ' - ' + formatTimeToTimezone(MeetingsData.user.preference.timezone, MeetingsData.toDateTime),
        timezone: MeetingsData.user.preference.timezone,
        participants: MeetingsData.meetingParticipant.length,
        action: MeetingsData.id,
        meetingHistory: MeetingsData.id
      });
    });

    return displayData;
  }, [pageData]);

  const defaultColumn = React.useMemo(() => ({
    width: 100,
  }), []);

  const columns = React.useMemo(() => [
  {
    Header: 'Host Name',
    accessor: 'firstName',
    width: 100,
    Filter: TextColumnFilter,
    filter: 'fuzzyText',
  },
  {
    Header: 'Event title',
    accessor: 'title',
    width: 150,
    Filter: TextColumnFilter,
    filter: 'fuzzyText',
  },
  {
    Header: <div className='pb-8'>UTC Date and Time</div> ,
    accessor: 'utcDateTime',
    width: 190,
  },
  {
    Header: <div className='pb-8'>Host Date and Time</div>,
    accessor: 'hostDateTime',
    width: 150,

  },
  {
    Header: <div className='pb-8'> Host Timezone </div>,
    accessor: 'timezone',
    width: 100,
  },
  {
    Header: <div className='pb-8'> Participants </div>,
    accessor: 'participants',
    width: 80,
  },
  {
    Header: <div className='pb-8'> Action </div>,
    accessor: 'action',
    width: 80,
    Cell: ({ row: { original } }: any) => (<Action id={original.action} types={[ActionType.View]} navigation='s/viewMeeting' title="view" componenetType={ComponentType.Button} />),
  },
  {
    Header: <div className='pb-8'> Meeting  History </div>,
    accessor: 'meetingHistory',
    Cell: ({ row: { original } }: any) => (<Action id={original.action} types={[ActionType.View]} navigation='s/meetingHistory' title="view History" componenetType={ComponentType.Button} />),
    width: 120,
  }], []);

  return (
    <div className='flex flex-col h-screen'>
      <div className='h-16 bg-gray-200 flex items-center w-full justify-between p-2'>
        <Title icon="MEETINGS" title="Meetings" />
        <div className='w-full flex justify-end mx-6'>
          <div className="flex items-center ">
            <input id="default-checkbox" type="checkbox" className="w-5 h-5 cursor-pointer accent-BeeMG-yellow mr-2" checked={upcomingMeetings} onChange={(e: any) => setUpcomingMeetings(e.target.checked)}></input>
            <label className="font-semibold flex items-center mr-8">Upcoming meetings</label>
          </div>
          <div className="flex items-center ">
            <input id="default-checkbox" type="checkbox" className="w-5 h-5 cursor-pointer accent-BeeMG-yellow mr-2" checked={pastMeetings} onChange={(e: any) => setPastMeetings(e.target.checked)}></input>
            <label className="font-semibold flex items-center mr-5 ">Past meetings</label>
          </div>
        </div>
        <NavRight />
      </div>
      <div className="items-center justify-center h-screen w-full font-semibold mb-4 rounded-lg bg-white overflow-auto overflow-x-hidden">
          <TableFormat
            columns={columns} data={data} defaultColumn={defaultColumn} loading={isLoading} fetchData={fetchData}
            error={error}
            page={pagination}
          />
      </div>
    </div>
  )
}

export default Meetings