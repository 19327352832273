import { useState } from "react";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import OpenLogo from "../Assets/img/admin_logo.svg";
import CloseLogo from "../Assets/img/beemg_logo.svg";
import Icon from "../Components/base/icon/icon";

const Sidebar = () => {

    const [open, setOpen] = useState<boolean>(true);
    const navigate = useNavigate();
    const Menus = [
        { title: "users", icon: "USERS", url: "users" },
        { title: "events", icon: "EVENTS", url: "events" },
        { title: "meetings", icon: "MEETINGS", url: "meetings" },
        { title: "Cancellation Requests ", icon: "CANCEL", url: "cancellationRequest" },
        { title: "Transactions", icon: "TRANSACTION", url: "transactions" },
        { title: "Reported Users", icon: "USERS_SLASH", url: "reportedUsers" },
        { title: "Support", icon: "COMMENT", url: "feedbacks" },
    ];
    const style = {
        navLink: `flex rounded-lg rounded-r-none p-2 py-3 cursor-pointer hover:text-black text-BeeMG-dark-gray text-sm items-center gap-x-4 hover:bg-gradient-to-r from-BeeMG-yellow/50 border-BeeMG-yellow font-semibold ${!open && 'justify-center'} `,
        active: `flex rounded-lg rounded-r-none p-2 py-3 cursor-pointer hover:text-black text-BeeMG-dark-gray text-sm items-center gap-x-4 bg-gradient-to-r from-BeeMG-yellow/50 border-r-4 border-BeeMG-yellow text-black font-bold ${!open && 'justify-center'} `,
    }

    return (
        <div className="flex">
            <div className={` ${open ? "lg:w-72 md:w-52" : "lg:w-20 md:w-16 w-16"} bg-white h-screen pt-6 relative duration-300 shadow-md group`}>
                <div className={`absolute cursor-pointer -right-3 inset-y-1/2 h-6 w-6  flex items-center justify-center bg-white hover:bg-BeeMG-yellow hover:text-black rounded-full  ${!open && "rotate-180"} shadow-md opacity-0 group-hover:opacity-100`}
                    onClick={() => setOpen(!open)}>
                    <Icon icon="LEFT" size="x-small" />
                </div>
                <div className="flex gap-x-4 items-center justify-center" onClick={() => navigate("/s/home")}>
                    <img
                        src={open ? OpenLogo : CloseLogo}
                        className={`cursor-pointer duration-500 flex justify-center ${open ? `lg:w-52 md:w-40 w-36 h-14` : `w-16 h-16`}`}
                        alt="BeeMG Admin"
                    />
                </div>
                <ul className="pl-2 pt-6 flex flex-col gap-1  ">
                    {Menus.map((Menu, index) => (
                        <NavLink to={`/s/${Menu.url}`}
                            key={index}
                            className={(navData: any) => navData.isActive ? style.active : style.navLink}
                        >
                            <div className="flex flex-row flex-none gap-1">
                                <Icon icon={Menu.icon} className="fill-black stroke-black	" />
                                <span className={`${!open && "hidden"} origin-left duration-200 capitalize truncate`}>
                                    {Menu.title}
                                </span>
                            </div>
                        </NavLink>
                    ))}
                </ul>
            </div>
            <div className="flex-1 w-4/5 px-4 bg-gray-200">
                <div className="w-full h-full">
                    <Outlet />
                </div>
            </div>
        </div>
    );
};
export default Sidebar;