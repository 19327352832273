import { yupResolver } from '@hookform/resolvers/yup';
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form';
import { Button } from '../../Components'
import Field from '../../Components/base/field/field'
import { avatarValidationSchema } from '../../Utils/validation';
import 'react-image-crop/dist/ReactCrop.css';
import Compressor from 'compressorjs';
import { useGetSettingValue } from '../../Services/settingReducer';
import { useUploadAvatarFileMutation } from '../../Services/avatarApi';
import ReactCrop from 'react-image-crop';
import { toastError, toastSuccess } from '../../Utils/toast';

interface ICreateAvatar {
    id: string;
    name: string;
}

function formatBytesAvatar(bytes: number, decimals?: number): string {
    if (bytes === 0) {
        return '0 Bytes'
    }

    const k = 1024, dm = decimals || 2, sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

function CreateAvatar() {
    const {
        register, handleSubmit, trigger, getValues, formState: { errors }, setValue, reset
    } = useForm<ICreateAvatar>({ resolver: yupResolver(avatarValidationSchema) });

    const imgRefAvatar = useRef(null);
    const previewCanvasRefAvatar = useRef(null);

    const imageSizeDataAvatar = useGetSettingValue('EVENT_IMAGE_SIZE');
    let imageSizeAvatar = Number(imageSizeDataAvatar)
    let imageSizeFormatAvatar = formatBytesAvatar(imageSizeAvatar);
    const [addImageAvatar] = useUploadAvatarFileMutation<any>()

    const [fileName] = React.useState();
    const [isFilePickedAvatar, setIsFilePickedAvatar] = useState(false);
    const [upImgAvatar, setUpImgAvatar] = useState({} as any);
    const [cropAvatar, setCropAvatar] = useState({ width: 250, aspect: 1 });
    const [completedCropAvatar, setCompletedCropAvatar] = useState({} as any);

    const onSubmit = (values: any) => { }

    useEffect(() => {
        if (!completedCropAvatar || !previewCanvasRefAvatar.current || !imgRefAvatar.current) {
            return
        }

        const imageAvatar: any = imgRefAvatar.current
        const canvasAvatar: any = previewCanvasRefAvatar.current
        const cropAvatar = completedCropAvatar
        const scaleXAvatar = imageAvatar.naturalWidth / imageAvatar.width
        const scaleYAvatar = imageAvatar.naturalHeight / imageAvatar.height
        const ctxAvatar = canvasAvatar.getContext('2d')
        const pixelRatioAvatar = window.devicePixelRatio;
        canvasAvatar.width = cropAvatar.width * pixelRatioAvatar * scaleXAvatar
        canvasAvatar.height = cropAvatar.height * pixelRatioAvatar * scaleYAvatar
        ctxAvatar.setTransform(pixelRatioAvatar, 0, 0, pixelRatioAvatar, 0, 0)
        ctxAvatar.imageSmoothingQuality = 'high'

        ctxAvatar.drawImage(
            imageAvatar,
            cropAvatar.x * scaleXAvatar,
            cropAvatar.y * scaleYAvatar,
            cropAvatar.width * scaleXAvatar,
            cropAvatar.height * scaleYAvatar,
            0,
            0,
            cropAvatar.width * scaleXAvatar,
            cropAvatar.height * scaleYAvatar,
        )
    }, [completedCropAvatar])

    const onUploadAvatarFileSelected = (event: any) => {
        if (imageSizeAvatar > event.target.files[0].size && event.target.files[0].type.match('image.*')) {
            setIsFilePickedAvatar(true)
            const readerAvatar = new FileReader()
            readerAvatar.addEventListener('load', () => setUpImgAvatar(readerAvatar.result))
            readerAvatar.readAsDataURL(event.target.files[0]);
            setValue('name', event.target.files.item(0).name);
        } else {
            if (event.target.files[0].type.match('image.*')) {
                toastError(`File size is too large. Max size is ${imageSizeFormatAvatar}`)
            }
            else {
                toastError(`File is not an image.`)
            }
            reset();
            cancelAvatar();
        }
    }

    const onLoadAvatar = useCallback((img: any) => {
        imgRefAvatar.current = img
    }, [])

    const generateDownloadAvatar = (canvas: any, crop: any) => {
        trigger();

        if (!crop || !canvas) {
            return
        }

        const readerAvatar = new FileReader()

        canvas.toBlob((blob: any) => {
            readerAvatar.readAsDataURL(blob)

            readerAvatar.onloadend = () => {
                dataURLtoFileAvatar(readerAvatar.result, 'cropped.png')
            }
        })
    }

    const dataURLtoFileAvatar = (dataurl: any, filename: any) => {
        let arrAvatar = dataurl.split(',')
        let mimeAvatar = arrAvatar[0].match(/:(.*?);/)[1]
        let bstrAvatar = atob(arrAvatar[1])
        let nAvatar = bstrAvatar.length
        let u8arrAvatar = new Uint8Array(nAvatar)

        while (nAvatar--) {
            u8arrAvatar[nAvatar] = bstrAvatar.charCodeAt(nAvatar)
        }

        let file: any = new File([u8arrAvatar], filename, { type: mimeAvatar });

        if (file.size > imageSizeAvatar) {
            new Compressor(file, {
                quality: 0.8,
                success: (compressedResult) => {
                    const newFile = new File([compressedResult], "cropped.png");
                    saveImageAvatar(newFile);
                },
            });
        } else {
            saveImageAvatar(file);
        }
    }

    const saveImageAvatar = (file: any) => {
        if (!errors.id) {
            const values = getValues();
            addImageAvatar({ file, id: values.id, name: values.name }).then((response: any) => {
                if (!response.data) {
                    toastError(response.error.data.message);
                } else {
                    toastSuccess('Avatar was successfully updated');
                }

                reset();
                cancelAvatar()
            });
        }
    }

    const cancelAvatar = () => {
        setIsFilePickedAvatar(false)
        setUpImgAvatar({} as any)
        setCompletedCropAvatar({} as any)
    }

    return (
        <div className='w-full flex flex-col items-center'>
            <div className="font-bold">Add new avatar</div>
            <div className='w-8/12'>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="flex flex-col gap-y-3">
                        <div>
                            <label htmlFor="avatartID" className='text-xs uppercase font-semibold'>ID</label>
                            <Field
                                {...register('id')} error={errors?.id?.message} type="text"
                                placeholder={'ID'} id="id" className='p-2 h-9'
                            />
                        </div>
                        <div>
                            <label htmlFor="avatarName" className='text-xs uppercase font-semibold'>Avatar Name</label>
                            <Field
                                {...register('name')} error={errors?.name?.message} type="text"
                                placeholder={`Avatar Name`} defaultValue={fileName} id="name" className='p-2 h-9'
                            />
                        </div>
                        {!isFilePickedAvatar && (
                            <div>
                                <label className="block">
                                    <span className="sr-only">Choose profile photo</span>
                                    <input
                                        type="file" accept="image/x-png,image/gif,image/jpeg" onChange={(e: any) => {
                                            onUploadAvatarFileSelected(e)
                                        }}
                                        className="block w-full text-sm text-slate-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-orange-50 file:text-BeeMG-orange hover:file:bg-orange-100"
                                    />
                                </label>
                            </div>
                        )}
                        {isFilePickedAvatar && (
                            <div className="col-span-2 flex justify-center">
                                <ReactCrop
                                    src={upImgAvatar} onImageLoaded={onLoadAvatar} crop={cropAvatar}
                                    onChange={(c: any) => setCropAvatar(c)} onComplete={(c: any) => setCompletedCropAvatar(c)}
                                />
                            </div>
                        )}
                    </div>
                    <div className='flex flex-col lg:flex-row gap-4 mt-5 justify-center'>
                        <Button
                            className="shadow-md text-sm py-1 bg-BeeMG-yellow" id="saveAndUpload" disabled={!isFilePickedAvatar}
                            onClick={() => generateDownloadAvatar(previewCanvasRefAvatar.current, completedCropAvatar)} submit
                        >
                            <div>Save {`&`} Upload</div>
                        </Button>
                        <Button
                            className="bg-BeeMG-yellow hover:bg-yellow-600 rounded px-2 py-1 text-sm font-semibold " onClick={() => { reset(); cancelAvatar() }}>
                            cancel
                        </Button>
                        <canvas
                            ref={previewCanvasRefAvatar} hidden
                            style={{ width: Math.round(completedCropAvatar?.width ?? 0), height: Math.round(completedCropAvatar?.height ?? 0) }}
                        />
                    </div>
                </form>
            </div>
        </div>
    )
}

export default CreateAvatar