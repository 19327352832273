import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQueryWithInterceptor } from "./api";

export const allApis = createApi({
  reducerPath: "allApi",
  baseQuery: baseQueryWithInterceptor,
  tagTypes: [
    "Avatar",
    "AdminUserTag",
    "updateAdminUserTag",
    "deleteAdminUserTag",
    "AdminUserByIDTag",
    "ReportProfile",
    "FakeProfile",
    "VerifyProfile",
    "FeedbackCategory",
    "TermsAndPolicies",
    "CancellationPolicy",
    "Settings",
    "nicknameList",
    "nicknameByID",
    "nicknameCancel",
    "interestsList",
    "interestsByID",
    "interests",
    "meetingData",
    "deleteInterests",
    "adminProfileList",
  ],
  endpoints: (builder) => ({}),
});
