import React from 'react'
import { Action, ActionType } from '../../Components/Action';
import { ComponentType } from '../../Components/Buttons/Delete';
import useFetchDataHooks from '../../Hooks/FetchDataHooks';
import { useGetAllTransactionsQuery } from '../../Services/transactionApi';
import { formatDateToTimezone2, formatTimeFullToTimezone } from '../../Utils/datetime';
import NavRight from '../Navbar/NavRight'
import Title from '../Navbar/Title'
import TableFormat, { SelectColumnFilter, TextColumnFilter } from '../TableFormat';

function Verification({ status }: { status: any }) {
  return (
    <>
      {status === 'Failed' &&
        <span className="bg-red-600 text-white text-xs font-semibold mr-2 px-2.5 py-0.5 rounded-lg">{status}</span>
      }
      {status === 'Completed' &&
        <span className="bg-green-600 text-white text-xs font-semibold mr-2 px-2.5 py-0.5 rounded-lg">{status}</span>
      }
      {status === 'Submitted' &&
        <span className="bg-blue-600 text-white text-xs font-semibold mr-2 px-2.5 py-0.5 rounded-lg">{status}</span>
      }
    </>
  );
}

function Transactions() {
  const { pageData, isLoading, fetchData, pagination, error } = useFetchDataHooks(useGetAllTransactionsQuery);
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const defaultColumn = React.useMemo(() => ({
    width: 200,
  }), []);

  const columns = React.useMemo(() => [
  {
    Header: 'Name',
    accessor: 'name',
    width: 170,
    Cell: ({ row: { original } }: any) => original.user?.firstName,
    Filter: TextColumnFilter,
    filter: 'fuzzyText',
  },
  {
    Header: <div className='pb-8'> Date </div>,
    accessor: 'date',
    width: 150,
    Cell: ({ row: { original } }: any) => formatDateToTimezone2(timezone, original.transactionDateTime),
  },
  {
    Header: 'Time',
    accessor: 'time',
    width: 150,
    Cell: ({ row: { original } }: any) => formatTimeFullToTimezone(timezone, original.transactionDateTime),
    Filter: TextColumnFilter,
    filter: 'fuzzyText',
  },
  {
    Header: 'Amount',
    accessor: 'transactionAmount',
    width: 150,
    Filter: TextColumnFilter,
    filter: 'fuzzyText',

  },
  {
    Header: 'Currency',
    accessor: 'currency',
    width: 100,
    Filter: SelectColumnFilter,
  },
  {
    Header: 'Description',
    accessor: 'transactionDescription',
    width: 220,
    Filter: TextColumnFilter,
    filter: 'fuzzyText',
  },
  {
    Header: 'Guest',
    accessor: 'guestCount',
    width: 80,
    Filter: TextColumnFilter, 
  },
  {
    Header: 'Status',
    accessor: 'status',
    width: 150,
    Filter: TextColumnFilter,
    filter: 'fuzzyText',
    Cell: ({ row: { original } }: any) => <Verification status={original.transactionStatus} />
  },
  {
    Header: <div className='pb-8'> Action </div>,
    accessor: 'action',
    width: 150,
    Cell: ({ row: { original } }: any) => (<Action id={original.id} types={[ActionType.View]} navigation='s/transactions/view' title="view" componenetType={ComponentType.Button} />),
  }], []);

  return (
    <div className='flex flex-col h-screen'>
      <div className='h-16 bg-gray-200 flex items-center w-full justify-between p-2'>
        <Title icon="TRANSACTION" title="Transactions" />
        <NavRight />
      </div>
      <div className="items-center justify-center h-screen w-full font-semibold mb-4 rounded-lg bg-white overflow-auto overflow-x-auto">
        {pageData && (
          <TableFormat
            columns={columns} data={pageData?.data} defaultColumn={defaultColumn} loading={isLoading} fetchData={fetchData}
            page={pagination} error={error}
            
          />
        )}
      </div>
    </div>
  )
}

export default Transactions