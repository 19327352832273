import PropTypes from "prop-types";
import {icons} from '../../../Assets/icons/index';
// import useWindowDimensions from "../carousel/whirligig/utils";

const Icon = ({size, color="gray", stroke, icon, onClick, className}: {size: 'x-small' | 'small' | 'medium' | 'large', color?: string, stroke?: string, icon: string, onClick?: any, className?:string}) => {
  // icon = icon.replaceAll('_', '-');
  let sizeWidth: string
  let sizeHeight: string
  // const {width} = useWindowDimensions()
  // if (width > 1050) {
    sizeWidth = size === 'small' ? '20' : size === 'medium' ? '30' : size === 'large' ? '40' : '15'
    // sizeHeight = height === 'small' ? '20' : size === 'medium' ? '30' : '40'
  // } else {
  //   sizeWidth = size === 'small' ? '15' : size === 'medium' ? '25' : '35'
  //   sizeHeight = height === 'small' ? '15' : size === 'medium' ? '25' : '35'
  // }

  // console.log("ICON 1 ", icon, bgColor)
  // const style = {
  //   bg: {
  //     backgroundColor: bgColor ? bgColor : 'transparent'
  //   }
  // }
  const IconComp = icons[icon as keyof typeof icons];
  return <div style={{color: `${color}`}}><IconComp stroke={stroke} width={sizeWidth} height={sizeWidth} onClick={onClick} className={className}/></div> 

};

Icon.defaultProps = {
  size: 'small',
  height: 'small',
  bgColor: "transparent",
};

Icon.propTypes = {
  size: PropTypes.oneOf(['x-small','small', 'medium', 'large']),
  color: PropTypes.string,
  icon: PropTypes.string.isRequired,
//   viewBox: PropTypes.string.isRequired,
//   style: PropTypes.shape({}),
  // className: PropTypes.string,
};

export default Icon;
