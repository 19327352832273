import { useGetAllSettingsQuery } from "./settingApi";

export function useGetSettingValue(key: string): string {
    const { data } = useGetAllSettingsQuery(true, { pollingInterval: 1000 * 60 * 60 * 5 });

    if (data) {
        return data[key];
    }

    return '';
}