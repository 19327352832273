import { useLocation, useNavigate } from "react-router-dom";
import Button from "./base/button/button";
import Icon from "./base/icon/icon";
import Delete, { ComponentType } from "./Buttons/Delete";

export enum ActionType { "Edit", "Delete", "View" }

interface IAction {
    id: string;
    types: ActionType[];
    deleteFunc?: any;
    mode?: string;
    title?: string;
    success?: any;
    navigation?: any;
    componenetType?: ComponentType;
}


export function Action({ id, types, deleteFunc, mode = "", title = "", success, navigation = 'view', componenetType = ComponentType.Icon }: IAction) {

    const navigate = useNavigate();
    const location = useLocation();
    return (
        <div className="flex gap-x-3">
            {types.includes(ActionType.Edit) && id && <Icon icon="EDIT" onClick={() => navigate("edit/" + id)} className="cursor-pointer" />}
            {types.includes(ActionType.Delete) && <Delete id={id} deleteFunction={deleteFunc} mode={mode} type={ComponentType.Icon} title={title} success={success} />}
            {componenetType === ComponentType.Button && <Button className="text-xs bg-BeeMG-yellow hover:scale-105 py-1" size='sm' onClick={() => navigate("/" + navigation + "/" + id,{state: { prevUrl: location }})}>{title}</Button>}
            {componenetType === ComponentType.Icon && types.includes(ActionType.View) && <Icon icon="CAMERA" onClick={() => navigate("/" + navigation + "/" + id)} className="cursor-pointer" />}
        </div>
    );
}
