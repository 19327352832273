import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import Icon from "../../Components/base/icon/icon";
import useFetchDataHooks from "../../Hooks/FetchDataHooks";
import { useGetMeetingDetailsHistoryByIdQuery } from "../../Services/meetingDetailsApi";
import { getUTFormattedDate, getUTFormattedTime } from "../../Utils/datetime";
import NavRight from "../Navbar/NavRight";
import Title from "../Navbar/Title";
import TableFormat from "../TableFormat";

function ViewEventHistory() {
  const { id } = useParams();
  const { pageData, isLoading, fetchData, pagination, error } = useFetchDataHooks<any>(useGetMeetingDetailsHistoryByIdQuery, { id: id ? id : '' } as any);
  const defaultColumn = React.useMemo(() => ({
    width: 150,
  }), []);

  const data = useMemo(() => {

    const displayData: any[] = [];
    pageData?.data?.forEach((history: any) => {
      const tempData = { ...history };
      if (history.categories) {
        let category = '';

        history.categories.forEach((cat: any, index: any) => {
          category += cat;

          if (index !== history.categories.length - 1) {
            category += ', ';
          }
        });

        tempData.categoriesDisplay = category;
      } else {
        tempData.categoriesDisplay = '';
      }

      displayData.push({
        col1: ' ',
        col2: tempData?.title,
        col3: tempData?.description,
        col4: tempData?.help,
        col5: tempData?.categoriesDisplay,
        col6: tempData?.updatedAt ? getUTFormattedDate(tempData?.updatedAt?.toString()) : '',
        col7: tempData?.updatedAt ? getUTFormattedTime(tempData?.updatedAt?.toString()) : '',
      });
    });
    return displayData;
  }, [pageData]);

  const columns = React.useMemo(() => [{
    Header: <Icon icon="FILTER" />,
    accessor: 'col1',
    width: 30,
  }, {
    Header: 'Title',
    accessor: 'col2',
    width: 100,
  }, {
    Header: 'Description',
    accessor: 'col3',
    width: 150,
  }, {
    Header: 'Help',
    accessor: 'col4',
    width: 100,
  }, {
    Header: 'Categories',
    accessor: 'col5',
    width: 100,
  }, {
    Header: 'Updated Date',
    accessor: 'col6',
    width: 100,
  }, {
    Header: 'Updated Time',
    accessor: 'col7',
    width: 100,
  },
  ], []);

  if (isLoading) {
    return <> "Loading..."</>
  }

  return (
    <div className='flex flex-col h-screen'>
      <div className='h-16 bg-gray-200 flex items-center w-full justify-between p-2'>
        <Title title="View Events History " prePage='/s/events' />
        <NavRight />
      </div>
      <div className="items-center justify-center h-screen w-full font-semibold mb-4 rounded-lg bg-white overflow-auto overflow-x-hidden">
        {((pageData?.data?.length === 0) || pageData === undefined) && <div className="h-full flex justify-center items-center text-lg">No Data to Display</div>}
        {pageData?.data?.length > 0 && <TableFormat columns={columns} data={data} defaultColumn={defaultColumn} fetchData={fetchData} loading={isLoading} page={pagination} error={error} />}
      </div>
    </div>
  );
}
export default ViewEventHistory;