import "./App.css";
import { Provider } from "react-redux";
import { persistor, store } from "./Store";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import SignIn from "./Containers/SignIn";
import ScrollToTop from "./Components/ScrollToTop";
import { Toaster } from "react-hot-toast";
import Home from "./Containers/Home";
import User from "./Containers/Users/User";
import Sidebar from "./Containers/Sidebar";
import Events from "./Containers/Events/Events";
import Meetings from "./Containers/Meetings/Meetings";
import CancellationRequest from "./Containers/CancellationRequest/CancellationRequest";
import Transactions from "./Containers/Transactions/Transactions";
import ReportedUsers from "./Containers/ReportedUsers/ReportedUsers";
import Feedbacks from "./Containers/Feedback/Feedbacks";
import Avatars from "./Containers/Avatars/Avatars";
import NickName from "./Containers/Users/NickName/NickName";
import FeedbackCategory from "./Containers/Feedback/FeedbackCategory";
import ViewAvatar from "./Containers/Avatars/ViewAvatar";
import CreateAvatar from "./Containers/Avatars/CreateAvatar";
import ViewUser from "./Containers/Users/ViewUser";
import UserHistory from "./Containers/Users/UserHistory";
import AdminList from "./Containers/Users/Admin/AdminList";
import AdminView from "./Containers/Users/Admin/AdminView";
import EditNickName from "./Containers/Users/NickName/EditNickName";
import ViewUserEvents from "./Containers/Users/ViewUserEvents";
import ViewTransaction from "./Containers/Transactions/ViewTransaction";
import ViewFeedback from "./Containers/Feedback/ViewFeedback";
import ViewMeeting from "./Containers/Meetings/ViewMeeting";
import ViewReportedUser from "./Containers/ReportedUsers/ViewReportedUser";
import ViewCancellationRequest from "./Containers/CancellationRequest/ViewCancellationRequest";
import ViewEvents from "./Containers/Events/ViewEvents";
import ViewEventMeetings from "./Containers/Events/ViewEventMeetings";
import ViewMeetingHistory from "./Containers/Meetings/ViewMeetingHistory";
import ViewEventHistory from "./Containers/Events/ViewEventHistory";
import Email from "./Containers/Email/Email";
import ViewEmail from "./Containers/Email/ViewEmail";
import EditFeedBackCategory from "./Containers/Feedback/EditFeedBackCategory";
import TermsAndPolicies from "./Containers/Policies/Terms/TermsAndPolicies";
import ViewTermsPolicies from "./Containers/Policies/Terms/ViewTermsPolicies";
import CancellationPolicy from "./Containers/Policies/Cancellation/CancellationPolicy";
import EditCancellationPolicy from "./Containers/Policies/Cancellation/EditCancellationPolicy";
import Settings from "./Containers/Settings/Settings";
import EditSetting from "./Containers/Settings/EditSetting";
import ViewTransactions from "./Containers/Users/ViewTransactions";
import EditInterests from "./Containers/Users/Interests/EditInterests";
import InterestList from "./Containers/Users/Interests/Interests";
import ViewMeetingPartcipant from "./Containers/Meetings/ViewMeetingsPartcipants";
import ViewAdminProfile from "./Containers/Users/Admin/AdminProfile";
import EditAdminProfile from "./Containers/Users/Admin/EditAdminProfile";

function AppLoginLoader() {
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<SignIn />} />
          <Route path="s" element={<Sidebar />}>
            <Route path="home" element={<Home />} />
            <Route path="users" element={<User />} />
            <Route path="users/userView/:id" element={<ViewUser />} />
            <Route path="users/userHistory/:id" element={<UserHistory />} />
            <Route path="users/eventList/:id" element={<ViewUserEvents />} />
            <Route
              path="users/transactions/:id"
              element={<ViewTransactions />}
            />
            <Route path="users/interests" element={<InterestList />}>
              <Route path="" element={<EditInterests />} />
              <Route path="edit/:id" element={<EditInterests />} />
            </Route>
            <Route path="users/avatars" element={<Avatars />}>
              <Route path="view/:id" element={<ViewAvatar />} />
              <Route path="create" element={<CreateAvatar />} />
            </Route>
            <Route path="users/nickName" element={<NickName />}>
              <Route path="" element={<EditNickName />} />
              <Route path="edit/:id" element={<EditNickName />} />
            </Route>
            <Route path="users/nickName" element={<NickName />} />
            <Route path="events" element={<Events />} />
            <Route path="viewEvent/:id" element={<ViewEvents />} />
            <Route path="meetings" element={<Meetings />} />
            <Route path="eventMeetings/:id" element={<ViewEventMeetings />} />
            <Route path="eventHistory/:id" element={<ViewEventHistory />} />
            <Route path="viewMeeting/:id" element={<ViewMeeting />} >
              <Route path="viewParticipants/:id" element={<ViewMeetingPartcipant />} />
            </Route>
            <Route path="meetingHistory/:id" element={<ViewMeetingHistory />} />
            <Route path="cancellationRequest" element={<CancellationRequest />} />
            <Route path="cancellationRequest/edit/:id" element={<ViewCancellationRequest />} />
            <Route path="transactions" element={<Transactions />} />
            <Route path="transactions/view/:id" element={<ViewTransaction />} />
            <Route path="reportedUsers" element={<ReportedUsers />} />
            <Route path="reportedUsers/view/:id" element={<ViewReportedUser />} />
            <Route path="feedbacks" element={<Feedbacks />} />
            <Route path="feedbacks/feedbackCategory" element={<FeedbackCategory />} >
              <Route path="" element={<EditFeedBackCategory />} />
              <Route path="edit/:id" element={<EditFeedBackCategory />} />
            </Route>
            <Route path="feedbacks/view/:id" element={<ViewFeedback />} />
            <Route path="adminUser" element={<AdminList />} />

            <Route path="adminUser/userView/:id" element={<AdminView />} />
            <Route path="adminUser/userCreate" element={<AdminView />} />
            <Route path="adminUser/adminProfile" element={<ViewAdminProfile />} >
              <Route path="" element={<EditAdminProfile />} />
              <Route path="edit/:id" element={<EditAdminProfile />} />
            </Route>
            <Route path="email" element={<Email />} />
            <Route path="email/view/:id" element={<ViewEmail />} />
            <Route path="TermsAndPolicies" element={<TermsAndPolicies />} />
            <Route path="TermsAndPolicies/createTermsAndPolicy" element={<ViewTermsPolicies />} />
            <Route path="TermsAndPolicies/edit/:id" element={<ViewTermsPolicies />} />
            <Route path="cancellationPolicy" element={<CancellationPolicy />}>
              <Route path="" element={<EditCancellationPolicy />} />
              <Route path="edit/:id" element={<EditCancellationPolicy />} />
            </Route>
            <Route path="settings" element={<Settings />}>
              <Route path="edit/:id" element={<EditSetting />} />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Toaster />
        <AppLoginLoader />
      </PersistGate>
    </Provider>
  );
}

export default App;
