import React, { useEffect,useMemo,useState } from 'react'
import { useSearchParams } from 'react-router-dom';
import { Action, ActionType } from '../../Components/Action';
import { ComponentType } from '../../Components/Buttons/Delete';
import useFetchDataHooks from '../../Hooks/FetchDataHooks';
import { useGetAllEventsQuery } from '../../Services/meetingDetailsApi';
import NavRight from '../Navbar/NavRight'
import Title from '../Navbar/Title'
import TableFormat, { SelectColumnFilter, TextColumnFilter } from '../TableFormat';

function Events() {
  const [type1, setType1] = useState<boolean>(false)
  const [type2, setType2] = useState<boolean>(false)
  const[chatVideoRequest, setChatVideoRequest] = useState<boolean>(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const { pageData, isLoading, fetchData, pagination, error } = useFetchDataHooks(
  useGetAllEventsQuery,
  {
    meetingTypeFilter: chatVideoRequest ? 'Chat Video Request' : type1 ? 'type 1' : type2 ? 'type 2' : undefined
  } as any
  );
useEffect(() => {

  if (searchParams?.get('type1') === 'true' ) {
    setType1(true)
  }
  if (searchParams?.get('type2') === 'true' ) {
    setType2(true)
  }
  if (searchParams?.get('chatVideoRequest') === 'true' ) {
    setChatVideoRequest(true)
  }
}, [searchParams])  

useEffect(() => {

  if (type1) {
    searchParams.set('type1', 'true')
  } else {
    searchParams.delete('type1')
  }
  if (type2) {
    searchParams.set('type2', 'true')
  } else {
    searchParams.delete('type2')
  }

  if (chatVideoRequest) {
    searchParams.set('chatVideoRequest', 'true')
  } else {
    searchParams.delete('chatVideoRequest')
  }
  
  setSearchParams(searchParams)
}, [type1, type2, chatVideoRequest])

  const data = useMemo(() => {
    const displayData: any[] = [];

    pageData?.data?.forEach((event: any) => {
      const eventData = { ...event };
      if (event.categories) {
        let category = '';

        event.categories.forEach((cat: any, index: any) => {
          category += cat;

          if (index !== event.categories.length - 1) {
            category += ', ';
          }
        });

        eventData.categoriesDisplay = category;
      } else {
        eventData.categoriesDisplay = '';
      }

      displayData.push({
        title: eventData.title,
        videoType: eventData.videoType,
        isFreeAudioMeeting: (eventData.isFreeAudioMeeting).toString(),
        kidsOnly: (eventData.kidsOnly).toString(),
        categories: eventData.categoriesDisplay,
        action: eventData.id,
        meetings: eventData.id,
        eventHistory: eventData.id
      });
    });

    return displayData;
  }, [pageData]);
  
  const defaultColumn = React.useMemo(() => ({
    width: 200,
   }), []);
  
const columns = React.useMemo(() => [ 
    {
      Header: 'Event Title ',
      accessor: 'title',
      width: 250,
      Filter: TextColumnFilter,
      filter: 'fuzzyText',
     }, 
    {
      Header: 'Audio/Video',
      accessor: 'videoType',
      width: 200,
      Filter: SelectColumnFilter, 
    }, 
    {
      Header: 'Free Event',
      accessor: 'isFreeAudioMeeting',
      width: 150,
      Filter: SelectColumnFilter,
     
    }, 
    {
      Header: 'Kids Only',
      accessor: 'kidsOnly',
      width: 150,
     Filter: SelectColumnFilter,   
    }, 
    {
      Header: 'Categories',
      accessor: 'categories',
      width: 250,
      Filter: TextColumnFilter,
      filter: 'fuzzyText',
    }, 
    {
      Header:<div className='pb-8'>Action</div> ,
      accessor: 'action',
      width:90,
      Cell: ({ row: { original } }: any) => (<Action id={original.action} types={[ActionType.View]} navigation="s/viewEvent" title="view" componenetType={ComponentType.Button} />),
      }, 
      {
      Header: <div className='pb-8'>Meetings</div>,
      accessor: 'meetings',
      width:150,
      Cell: ({ row: { original } }: any) => (<Action id={original.action} types={[ActionType.View]} navigation='s/eventMeetings' title="view meetings" componenetType={ComponentType.Button} />),
       }, 
    {
      Header: <div className='pb-8'>Event History</div>,
      accessor: 'eventHistory ',
      width:150,
     Cell: ({ row: { original } }: any) => (<Action id={original.action} types={[ActionType.View]} navigation='s/eventHistory' title="view History" componenetType={ComponentType.Button} />),
    }], []);

  return (
    <div className='flex flex-col h-screen'>
     <div className='h-16 bg-gray-200 flex items-center justify-between p-2'>
        <Title icon="EVENTS" title="Events" />  
        <div className='w-full flex justify-end mx-6'>
          <div className="flex items-center ">
            <input id="default-checkbox" type="checkbox" className="w-5 h-5 cursor-pointer accent-BeeMG-yellow mr-2" checked={type1} onChange={(e: any) => setType1(e.target.checked)}></input>
            <label className="font-semibold flex items-center mr-8">Event</label>
          </div>
          <div className="flex items-center ">
            <input id="default-checkbox" type="checkbox" className="w-5 h-5 cursor-pointer accent-BeeMG-yellow mr-2" checked={type2} onChange={(e: any) => setType2(e.target.checked)}></input>
            <label className="font-semibold flex items-center mr-5 ">RFM</label>
          </div>
          <div className="flex items-center ">
            <input id="default-checkbox" type="checkbox" className="w-5 h-5 cursor-pointer accent-BeeMG-yellow mr-2" checked={chatVideoRequest} onChange={(e: any) => setChatVideoRequest(e.target.checked)}></input>
            <label className="font-semibold flex items-center mr-5 ">Chat Video</label>
          </div>
        </div>
        <NavRight />
      </div>
      <div className="items-center justify-center w-full font-semibold mb-4 rounded-lg bg-white overflow-auto overflow-x-auto">
      {pageData && (
          <TableFormat
            columns={columns} data={data} defaultColumn={defaultColumn} loading={isLoading} fetchData={fetchData} page={pagination} error={error}
          />
        )}
        </div>
      </div>
   )
}

export default Events