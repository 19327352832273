import { PagedData } from '../Models/pagedData.model';
import { Contact } from "../Models/contact.model";
import { allApis } from "./allApis";

export const transactionApi = allApis.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
        getAllContact: builder.query<PagedData<Contact>, any>({
            query: page => {
                return {
                    url: "contact",
                    params: page,
                };
            }
        }),
        getAllContactById: builder.query<Contact, any>({
            query: id => {
                return {
                    url: `contact/feedback/${id}`,
                };
            }
        }),
    })
});

export const { useGetAllContactQuery, useGetAllContactByIdQuery } = transactionApi;
