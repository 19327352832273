import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { Button } from '../../../Components'
import Field from '../../../Components/base/field/field'
import Delete from '../../../Components/Buttons/Delete'
import UpdateButton from '../../../Components/Buttons/UpdateButton'
import { CancellationPolicy } from '../../../Models/cancellationPolicy.model'
import { useCreateCancellationPolicyMutation, useDeleteCancellationPolicyMutation, useGetCancellationPolicyByIdQuery, useUpdateCancellationPolicyMutation } from '../../../Services/cancellationPolicyApi'
import { toastError, toastSuccess } from '../../../Utils/toast'
import { cancellationPolicyValidationSchema } from '../../../Utils/validation'

function EditCancellationPolicy() {

    const navigate = useNavigate();
    const { id } = useParams();

    const [serverError, setServerError] = useState<string>('');

    const { register, handleSubmit, setValue, reset, getValues, formState: { errors } } = useForm<CancellationPolicy>({ resolver: yupResolver(cancellationPolicyValidationSchema) });

    const [createCancellationPolicy] = useCreateCancellationPolicyMutation();
    const [updateCancellationPolicy] = useUpdateCancellationPolicyMutation();
    const [deleteCancellationPolicy, { isSuccess: deleteCancellationPolicySuccess }] = useDeleteCancellationPolicyMutation();
    const { data: cancellationPolicy } = useGetCancellationPolicyByIdQuery(id ? id : '', { skip: !id });

    const onSubmit = (values: any) => {

        if (cancellationPolicy?.id && id) {
            updateCancellationPolicy(values).then((res: any) => {
                if (res?.error) {
                    toastError(res.error.data.message)
                    setServerError(res.error.data.message);
                } else {
                    toastSuccess('Cancellation Policy Updated Successfully')
                    reset();
                    navigate('/s/cancellationPolicy');
                }
            })
        } else if (!id) {
            createCancellationPolicy(values).then((res: any) => {
                if (res?.error) {
                    toastError(res.error.data.message)
                    setServerError(res.error.data.message);
                } else {
                    toastSuccess('Cancellation Policy Created Successfully')
                    reset();
                    navigate('/s/cancellationPolicy');
                }
            })
        }
    }

    const navigateToList = () => navigate("/s/cancellationPolicy");

    useEffect(() => {
        if (cancellationPolicy && id) {
            reset();
            setValue('id', cancellationPolicy.id);
            setValue('policyType', cancellationPolicy.policyType);
            setValue('chargePeriodFromTime', cancellationPolicy.chargePeriodFromTime);
            setValue('chargePeriodToTime', cancellationPolicy.chargePeriodToTime);
            setValue('deductionPercentage', cancellationPolicy.deductionPercentage);
        } else {
            reset();
            setValue('id', '');
            setValue('policyType', '');
            setValue('chargePeriodFromTime', '');
            setValue('chargePeriodToTime', '');
            setValue('deductionPercentage', '');
        }

    }, [cancellationPolicy, id]);

    return (
        <div className='w-full flex flex-col items-center'>
            <div className="font-bold">{cancellationPolicy?.id && id ? "Edit Cancellation Policy" : "Create Cancellation Policy"}</div>
            <div className='w-8/12'>
                <form onSubmit={handleSubmit(onSubmit)} id="CancellationPolicyForm">
                    <div className="flex flex-col gap-y-3">
                        <div>
                            <label htmlFor="categoryId" className='text-xs uppercase font-semibold'>Policy ID</label>
                            <Field
                                {...register('id')} error={errors?.id?.message} type="text"
                                placeholder={'ID'} id="categoryId" className='p-2 h-9' readOnly={cancellationPolicy?.id && id ? true : false}
                            />
                        </div>
                        <div>
                            <label htmlFor="policyType" className='text-xs uppercase font-semibold'>policy Type</label>
                            <Field
                                {...register('policyType')} error={errors?.policyType?.message} type="text"
                                placeholder={`Policy Type`} id="policyType" className='p-2 h-9'
                            />
                        </div>
                        <div>
                            <label htmlFor="chargePeriodFromTime" className='text-xs uppercase font-semibold'>Charge period from</label>
                            <Field
                                {...register('chargePeriodFromTime')} error={errors?.chargePeriodFromTime?.message} type="text"
                                placeholder={`Charge Period From`} id="chargePeriodFromTime" className='p-2 h-9'
                            />
                        </div>
                        <div>
                            <label htmlFor="chargePeriodToTime" className='text-xs uppercase font-semibold'>Charge period to</label>
                            <Field
                                {...register('chargePeriodToTime')} error={errors?.chargePeriodToTime?.message} type="text"
                                placeholder={`Charge Period To`} id="chargePeriodToTime" className='p-2 h-9'
                            />
                        </div>
                        <div>
                            <label htmlFor="deductionPercentage" className='text-xs uppercase font-semibold'>Deduction Percentage</label>
                            <Field
                                {...register('deductionPercentage')} error={errors?.deductionPercentage?.message} type="text"
                                placeholder={`Deduction Percentage`} id="deductionPercentage" className='p-2 h-9'
                            />
                        </div>
                    </div>
                    <div className="flex flex-col lg:flex-row gap-4 mt-5 justify-center">
                        {!id &&
                            <Button
                                className="bg-amber-400 hover:bg-yellow-600 rounded px-2 py-1 text-sm font-semibold"
                                submit
                            >
                                {!id && "Create"}
                            </Button>}
                        {
                            cancellationPolicy?.id && id &&
                            <>
                                <UpdateButton formName='CancellationPolicyForm' title={cancellationPolicy.policyType} mode='Interest' value={'Update'} />

                                <Delete
                                    id={getValues('id')}
                                    title={getValues('policyType')}
                                    mode='Cancellation Policy'
                                    deleteFunction={deleteCancellationPolicy}
                                    success={deleteCancellationPolicySuccess}
                                    afterSuccess="/s/cancellationPolicy"
                                />
                            </>
                        }

                        {cancellationPolicy?.id && id && (
                            <Button
                                className="bg-amber-400 hover:bg-yellow-600 rounded px-2 py-1 text-sm font-semibold"
                                onClick={navigateToList}
                            >
                                CANCEL
                            </Button>
                        )}
                    </div>
                </form>
            </div>

        </div>
    )
}

export default EditCancellationPolicy