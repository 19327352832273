import { FeedbackCategory } from '../Models/feedbackCategory.model';
import { PagedData } from './../Models/pagedData.model';
import { allApis } from "./allApis";

export const contactFeedbackCategoryApi = allApis.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
        getAllFeedbackCategory: builder.query<PagedData<FeedbackCategory>, any>({
            query: page => {
                return {
                    url: "contactFeedbackCategory",
                    params: page,
                };
            },
            providesTags: ['FeedbackCategory'],
        }),
        getFeedbackCategoryById: builder.query<FeedbackCategory, any>({
            query: id => {
                return {
                    url: `contactFeedbackCategory/get/${id}`,
                };
            },
            providesTags: ['FeedbackCategory'],

        }),
        updateFeedbackCategory: builder.mutation<FeedbackCategory, any>({
            query: (feedbackCategory) => {
                return {
                    url: `contactFeedbackCategory/${feedbackCategory.id}`,
                    method: 'PUT',
                    body: feedbackCategory
                };
            },
            invalidatesTags: ['FeedbackCategory'],
        }),
        deleteFeedbackCategory: builder.mutation<FeedbackCategory, any>({
            query: (args) => {
                const {id} =args;
                return {
                    url: `contactFeedbackCategory/${id}`,
                    method: 'DELETE',
                };
            },
            invalidatesTags: ['FeedbackCategory'],
        }),
        createFeedbackCategory: builder.mutation<FeedbackCategory, any>({
            query: (feedbackCategory) => {
                return {
                    url: `contactFeedbackCategory`,
                    method: 'POST',
                    body: feedbackCategory
                };
            },
            invalidatesTags: ['FeedbackCategory'],
        }),
        getFeedbackAttachmentId: builder.query<string[], any>({
            query: id => {
              return `contactFeedBackAttachments/feedbackAttachment/${id}`;
            },
          }),
    })
});

export const { useGetAllFeedbackCategoryQuery, useGetFeedbackCategoryByIdQuery, useUpdateFeedbackCategoryMutation, useDeleteFeedbackCategoryMutation, useCreateFeedbackCategoryMutation, useGetFeedbackAttachmentIdQuery  } = contactFeedbackCategoryApi;
