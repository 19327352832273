import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import Icon from "../../Components/base/icon/icon";
import useFetchDataHooks from "../../Hooks/FetchDataHooks";
import { useGetAllTransactionsByUserQuery } from "../../Services/transactionApi";
import { getUTFormattedDate } from "../../Utils/datetime";
import NavRight from "../Navbar/NavRight";
import Title from "../Navbar/Title";
import TableFormat from "../TableFormat";

function ViewTransactions() {

  const { id } = useParams();
  const { pageData, isLoading, fetchData, pagination, error } = useFetchDataHooks<any>(useGetAllTransactionsByUserQuery, { id: id ? id : '' } as any);

  const defaultColumn = React.useMemo(() => ({
    width: 150,
  }), []);

  const data = useMemo(() => {

    const displayData: any[] = [];
    pageData?.data?.forEach((history: any) => {
      const tempData = { ...history };
      displayData.push({
        col1: ' ',
        col2: getUTFormattedDate(tempData.transactionDateTime),
        col3: tempData.transactionType,
        col4: tempData?.transactionAmount,
        col5: tempData?.currency,
        col6: tempData?.transactionDescription,
        col7: tempData?.guestCount,
        col8: tempData?.transactionStatus,

      });
    });
    return displayData;
  }, [pageData]);

  const columns = React.useMemo(() => [{
    Header: <Icon icon="FILTER" />,
    accessor: 'col1',
    width: 30,
  }, {
    Header: 'Transaction Date',
    accessor: 'col2',
    width: 100,
  }, {
    Header: 'Type',
    accessor: 'col3',
    width: 100,
  }, {
    Header: 'Amount',
    accessor: 'col4',
    width: 100,
  }, {
    Header: 'Currency',
    accessor: 'col5',
    width: 150,
  }, {
    Header: 'Description',
    accessor: 'col6',
    width: 150,
  }, {
    Header: 'Guest Count',
    accessor: 'col7',
    width: 100,
  }, {
    Header: 'Status',
    accessor: 'col8',
    width: 150,
  }
  ], []);

  if (isLoading) {
    return <> "Loading..."</>
  }

  return (
    <div className='flex flex-col h-screen'>

      <div className='h-16 bg-gray-200 flex  w-full justify-between p-2'>
        <Title title="Transaction History" prePage={`/s/users/userView/${id}`} />
        <NavRight />
      </div>

      <div className="items-center justify-center h-screen w-full font-semibold mb-4 rounded-lg bg-white">
        {((pageData?.data?.length === 0) || pageData === undefined) && <div className="h-full flex justify-center items-center text-lg">No Data to Display</div>}
        {pageData && <TableFormat columns={columns} data={data} defaultColumn={defaultColumn} fetchData={fetchData} loading={isLoading} page={pagination} error={error} />}
      </div>
    </div>
  )
}
export default ViewTransactions;