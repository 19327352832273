import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { Button } from '../../Components';
import { Action, ActionType } from '../../Components/Action';
import Icon from '../../Components/base/icon/icon'
import { ComponentType } from '../../Components/Buttons/Delete';
import useFetchDataHooks from '../../Hooks/FetchDataHooks';
import { User } from '../../Models/user.model';
import { useGetAllUsersQuery } from '../../Services/userApi';
import NavRight from '../Navbar/NavRight';
import Title from '../Navbar/Title';
import TableFormat, { SelectColumnFilter, TextColumnFilter } from '../TableFormat';

interface FormData {
  search: string;
}

function Verification({ values }: { values: any }) {
  return (
    <>
      {values === 'Not complete' &&
        <span className="bg-red-600 text-white text-xs font-semibold mr-2 px-2.5 py-0.5 rounded-lg">{values}</span>
      }
      {values === 'Complete' &&
        <span className="bg-green-600 text-white text-xs font-semibold mr-2 px-2.5 py-0.5 rounded-lg">{values}</span>
      }
    </>
  );
};

function HostBank({ values }: { values: any }) {
  return (
    <>
      {values === 'No' &&
        <span className="bg-red-600 text-white text-xs font-semibold mr-2 px-2.5 py-0.5 rounded-lg">Inactive</span>
      }
      {values === 'Yes' &&
        <span className="bg-green-600 text-white text-xs font-semibold mr-2 px-2.5 py-0.5 rounded-lg">Active</span>
      }
    </>
  );
};

function UserList() {

  const { register, handleSubmit } = useForm<FormData>();
  const { pageData, isLoading, fetchData, pagination, error } = useFetchDataHooks(useGetAllUsersQuery);

  const onSubmit = (values: any) => {

  }

  const defaultColumn = useMemo(() => ({
    width: 150,
  }), []);

  const columns = useMemo(() => [
    {
      Header: 'ID',
      accessor: 'userId',
      width: 300,
      Filter: TextColumnFilter,
      filter: 'fuzzyText',


    }, {
      Header: 'First Name',
      accessor: 'firstName',
      width: 200,
      Filter: TextColumnFilter,
      filter: 'fuzzyText',
    }, {
      Header: 'Interests',
      accessor: 'interests',
      width: 300,
      Filter: TextColumnFilter,
      filter: 'fuzzyText',

    }, {
      Header: 'Verification',
      accessor: 'verification',
      Cell: ({ cell: { value } }: any) => <Verification values={value} />,
      width: 200,
      Filter: SelectColumnFilter,
    }, {
      Header: 'Group',
      accessor: 'group',
      width: 150,
      Filter: SelectColumnFilter,

    }, {
      Header: 'Host Bank Active',
      accessor: 'hostBankActive',
      width: 150,
      Filter: SelectColumnFilter,
      Cell: ({ cell: { value } }: any) => <HostBank values={value} />
    },
    {
      Header: <div className='pb-8'>Action</div>,
      accessor: 'action',
      Cell: ({ row: { original } }: any) => (<Action id={original.action} types={[ActionType.View]} navigation="s/users/userView" title="view" componenetType={ComponentType.Button} />),
      width: 100,
    },
    {
      Header: <div className='pb-8'> User History</div>,
      accessor: 'userHistory',
      Cell: ({ row: { original } }: any) => (<Action id={original.action} types={[ActionType.View]} navigation='s/users/userHistory' title="view History" componenetType={ComponentType.Button} />),
      width: 150,
    }], []);

  const data = useMemo(() => {
    const displayData: any[] = [];

    pageData?.data?.forEach((user: User) => {
      const tempData = { ...user };

      if (user.userCategory) {
        let category = '';

        user.userCategory.forEach((cat: any, index: any) => {
          category += cat.category?.name;

          if (index !== user.userCategory.length - 1) {
            category += ', ';
          }
        });

        tempData.userCategoryDisplay = category;
      } else {
        tempData.userCategoryDisplay = '';
      }

      if (user.group) {
        const commission = user.group.name + '- ' + user.group.commissionPercentage;
        tempData.groupDisplay = commission;
      }

      displayData.push({
        userId: tempData.id,
        firstName: tempData.firstName,
        interests: tempData.userCategoryDisplay,
        verification: tempData.verified,
        group: tempData.groupDisplay,
        hostBankActive: tempData.paymentAccountActive,
        action: tempData.id,
        userHistory: tempData.id
      });
    });

    return displayData;
  }, [pageData?.data]);

  let tempData = useMemo(() => {
    if (data) {
      return data;
    }
  }, [pageData?.data]);

  return (
      <div className='flex flex-col h-screen'>
      <div className='h-16 bg-gray-200 flex items-center justify-between p-2 gap-2'>
        <Title icon="USERS" title="Users" />
        <div className="w-full"></div>
        {/* <div className="w-5/12">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="relative w-auto">
              <input
                type="text"  {...register('search')} placeholder='Search by UserID, Interests....' autoComplete='off'
                className="w-full h-10 px-2 rounded-lg z-0 focus:outline-none border-BeeMG-yellow focus:border-2 text-sm"
              />
              <div className="absolute top-3 right-2  cursor-pointer bg-white">
                <button type="submit"><Icon icon='SEARCH' className='fill-black' /></button>
              </div>
            </div>
          </form>
        </div> */}
        <div className="flex gap-1 justify-center ">
          <Link to={'/s/users/nickName'}>
            <Button className="text-xs py-1 bg-BeeMG-yellow ml-4" size='sm' id="btn_signIn">
              <div className='flex gap-x-1'>
                <Icon icon='EDIT' className='fill-black' />
                <span className="font-bold flex items-center whitespace-nowrap"> Nick Names</span>
              </div>
            </Button>
          </Link>
          <Link to={'avatars/create'}>
            <Button className="text-xs py-1 bg-BeeMG-yellow" size='sm' id="btn_signIn">
              <div className='flex gap-x-1'>
                <Icon icon='EDIT' className='fill-black' /> <span className="font-bold flex items-center"> avatars</span>
              </div>
            </Button>
          </Link>
          <Link to={'/s/users/interests'}>
            <Button className="text-xs py-1 bg-BeeMG-yellow" size='sm' id="btn_signIn">
              <div className='flex gap-x-1'>
                <Icon icon='EDIT' className='fill-black' /> <span className="font-bold flex items-center"> Interests</span>
              </div>
            </Button>
          </Link>
        </div>
        <NavRight />
      </div>
       <div className="items-center justify-center h-screen font-semibold mb-4 rounded-lg bg-white overflow-x-auto">
     {pageData && (
          <TableFormat
            columns={columns} data={data} defaultColumn={defaultColumn} loading={isLoading} fetchData={fetchData}
            page={pagination} error={error}/>
        )}
      </div>
    </div>
  )
}

export default UserList