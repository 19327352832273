import { Face } from "../Models/face.model";
import { allApis } from "./allApis";

export const FaceApi = allApis.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({

        getFaceById: builder.query<Face, any>({
            query: id => {
                return {
                    url: `verification/face/${id}`,
                };
            }
        }),
    })
});

export const { useGetFaceByIdQuery } = FaceApi;