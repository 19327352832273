import { AdminUser } from './../Models/adminUser.model';
import { User } from "../Models/user.model";
import { allApis } from "./allApis";
import { Login } from '../Models/login.model';

export const loginApi = allApis.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
        login: builder.mutation<Login, Partial<AdminUser>>({
            query: (adminUser) => ({
                
                url: `authAdmin/login`,
                method: 'POST',
                body: adminUser
            })
        }),
    })
});

export const { useLoginMutation } = loginApi;
