import { Nickname  } from "../Models/nickname.model";
import { PagedData } from "../Models/pagedData.model";
import { allApis } from "./allApis";
export const nicknameApi = allApis.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
        getAllNickname: builder.query<PagedData<Nickname>, any>({
            query: page => {
                return {
                      url: `nickName/nickNameListPagination/`,
                      params: page
                };
            },
            providesTags:['nicknameList']
        }), 
        getNicknameById: builder.query<Nickname, any>({
            query: id => {
                return {
                    url: `nickName/${id}`,
                };
            },
            providesTags:['nicknameByID']
        }),
         createNickname: builder.mutation<Nickname, any>({
            query: (nickname) => {
                return {
                    url: `nickname`,
                    method: 'POST',
                    body: nickname
                };  
            },
            invalidatesTags:['nicknameList'] ,
            
        }),
       updateNickname: builder.mutation<Nickname, any>({
            query: (nickname) => {
                return {
                    url: `nickName/${nickname.id}`,
                    method: 'PUT',
                    body: nickname
                };
                
            },
            invalidatesTags:['nicknameList', 'nicknameByID'] ,
            
        }),
       
         cancelNickname: builder.mutation<Nickname, any>({
            query: (nickname) => {
                return {
                    url: `nickname`,
                    method: 'POST',
                    body: nickname
                };  
            },
             invalidatesTags: ['nicknameCancel'],
            
        }),
   
    })  
     
})
export const {useGetAllNicknameQuery, useCreateNicknameMutation, useGetNicknameByIdQuery, useUpdateNicknameMutation, useCancelNicknameMutation} = nicknameApi;