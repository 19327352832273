import Button from "../../Components/base/button/button";
import Icon from "../../Components/base/icon/icon";

export enum CancellationStatus {
    Success = "success",
    Failed = "failed",
    Pending = "pending"
}

interface prop {
    title: string
    date?: string
    content?: string,
    status: CancellationStatus
}

const CancellationStatusCard = (props: prop) => {
    const { title, date, content, status } = props;

    let component;

    if (status === CancellationStatus.Success) {
        component = (<>

            <div className='flex flex-col items-center'>
                <Icon className='bg-BeeMG-yellow rounded-full grid justify-items-center p-1' icon="DONE" size='medium' color='white' />
                <div className='h-full w-0 border bg-BeeMG-yellow border-BeeMG-yellow'></div>
            </div>
            <div className="h-full col-start-2 col-span-5 w-full bg-white p-4 flex flex-col gap-y-3 rounded-xl shadow-md border-t">
                <div className='font-medium text-lg'>{title}</div>
                <div className="text-sm flex flex-col gap-y-3">
                    <div className=''>{date}</div>
                    <div className=''>{content}</div>
                </div>
            </div>
        </>)
    }


    if (status === CancellationStatus.Pending) {
        component = (<>
            <div className='flex flex-col items-center'>
                <Icon className='bg-slate-400 rounded-full grid justify-items-center p-1' icon="DONE" size='medium' color='white' />
                <div className='h-full w-0 bg-slate-400 border border-slate-400'></div>
            </div>
            <div className="h-full col-start-2 col-span-5 w-full bg-white p-4 flex flex-col gap-y-3 rounded-xl shadow-md border-t">
                <div className='font-medium text-lg text-slate-400'>{title}</div>
                <div className="text-sm flex flex-col gap-y-3 text-slate-400">
                    <div className=''>{date}</div>
                </div>
            </div>

        </>)
    }


    if (status === CancellationStatus.Failed) {
        component = (<>
            <div className='flex flex-col items-center'>
                {/* <Icon className='bg-slate-400 rounded-full grid justify-items-center p-1' icon="DONE" size='medium' color='white' /> */}
                <Icon className='bg-red-600 rounded-full grid justify-items-center p-1' icon="CLOSE" size='medium' color='white' />
            </div>
            <div className="h-full col-start-2 col-span-5 w-full bg-white p-4 flex flex-col gap-y-3 rounded-xl shadow-md border-t">
                <div className='font-medium text-lg text-red-600'>{title}</div>
                <div className="">
                    <div className="text-base text-red-500">{content}</div>
                </div>
                <div className="">
                    <Button
                        className="bg-amber-400 hover:bg-yellow-600 rounded px-2 py-1 text-sm font-semibold"> Retry
                    </Button>

                </div>
            </div>
        </>)
    }

    return (
        <>
            {component}
        </>
    )

}

export default CancellationStatusCard;