import * as yup from "yup";
import YupPassword from "yup-password";

YupPassword(yup);

yup.addMethod(yup.array, "unique", function (field, message) {
  return this.test("unique", message, function (array) {
    const uniqueData = Array.from(
      new Set(array.map((row) => row[field]?.toLowerCase()))
    );
    const isUnique = array.length === uniqueData.length;

    if (isUnique) {
      return true;
    }

    const index = array.findIndex(
      (row, i) => row[field]?.toLowerCase() !== uniqueData[i]
    );

    if (array[index][field] === "") {
      return true;
    }

    return this.createError({
      path: `${this.path}.${index}.${field}`,
      message,
    });
  });
});

const phoneRegExp =
  /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

const emailSchema = yup
  .string()
  .email("Enter valid email ID")
  .transform((curr, orig) => (orig === null ? "" : curr))
  .required("Required")
  .max(50, "Email can be max 50 characters long");

export const coHostValidationSchema = yup.object().shape({
  email: emailSchema,
});

export const loginValidationSchema = yup.object().shape({
  email: emailSchema,
  password: yup.string().required("Required"),
});

export const avatarValidationSchema = yup.object().shape({
  id: yup.string().required("Required"),
  name: yup.string().required("Required"),
});
export const feedbackCategoryValidationSchema = yup.object().shape({
  id: yup.string().required("Required"),
  categoryName: yup.string().required("Required"),
});

export const adminUserValidationSchema = yup.object().shape({
  id: yup.string(),
  name: yup.string().required("Required"),
  email: emailSchema,
  password: yup.string().test("password", "Required", function (password) {
    if (!this.parent.id && !password) {
      return false;
    } else {
      return true;
    }
  }),
  adminProfile: yup
    .object()
    .test("atleastOne", "You must select admin profile", function (profile) {
      if (!profile) {
        return false;
      } else {
        return true;
      }
    }),
  countryCode: yup.string().required("Required"),
  phoneNo: yup.string().matches(phoneRegExp, "Phone number is not valid").matches(/^[0-9]{10}$/, "Phone number is not valid"),
});
export const policyValidationSchema = yup.object().shape({
  policyId: yup.string().required("Required"),
  policyVersion: yup.string().required("Required"),
  policyName: yup.string().required("Required"),
});
export const cancellationPolicyValidationSchema = yup.object().shape({
  id: yup.string().required("Required"),
  policyType: yup.string().required("Required"),
  chargePeriodFromTime: yup.string().required("Required"),
  chargePeriodToTime: yup.string().required("Required"),
  deductionPercentage: yup.string().required("Required"),
});
export const settingValidationSchema = yup.object().shape({
  key: yup.string().required("Required"),
  value: yup.string().required("Required"),
});

export const CreateNickNameValidationSchema = yup.object().shape({
  id: yup.string().required("Required"),
  name: yup.string().required("Required"),
});

export const EditNickNameValidationSchema = yup.object().shape({
  id: yup.string().required("Required"),
  name: yup.string().required("Required"),
});
export const EditInterestsValidationSchema = yup.object().shape({
  id: yup.string().required("Required"),
  name: yup.string().required("Required"),
});
export const SpecificPercentageValidationSchema = yup.object().shape({
  commissionPercentage: yup
    .string()
    .required("Required")
    .matches(/^(?=.*[0-9])[0-9]{0,2}(?:\.[0-9]{1,2})?$/, "Percentage should range between 0 to 100"),
}
);

