import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from '../../Components'
import { Action, ActionType } from '../../Components/Action'
import Icon from '../../Components/base/icon/icon'
import { ComponentType } from '../../Components/Buttons/Delete'
import useFetchDataHooks from '../../Hooks/FetchDataHooks'
import { useGetAllContactQuery } from '../../Services/contactApi'
import { getUTFormattedDate, getUTFormattedTime } from '../../Utils/datetime'
import NavRight from '../Navbar/NavRight'
import Title from '../Navbar/Title'
import TableFormat, { TextColumnFilter } from '../TableFormat'


function Feedbacks() {

  const { pageData, isLoading, fetchData, pagination, error } = useFetchDataHooks(useGetAllContactQuery);

  const defaultColumn = React.useMemo(() => ({
    width: 100,
  }), []);

  const columns = React.useMemo(() => [
    {
      Header: <div className='pb-8'> Username/Email </div>,
      accessor: 'username',
      width: 150,
      Cell: ({ row }: any) => row.original.user ? (row.original.user.firstName + ' ' + row.original.user.firstName) : row.original.email,
    },
    {
      Header: <div className='pb-8'> UTC Date and Time </div>,
      accessor: 'dateAndTime',
      width: 100,
      Cell: ({ row }: any) => getUTFormattedDate(row.original.createdAt) + ' ' + getUTFormattedTime(row.original.createdAt),
    },
    {
      Header: 'Support Category',
      accessor: 'feedbackCategory',
      width: 250,
      Filter: TextColumnFilter,
      filter: 'fuzzyText',
      Cell: ({ row }: any) => <div className='flex gap-x-2'>
        <span>{row.original.category}</span>
        <span>{row.original.totalAttachments > 0 &&
          <div>
            <p className='flex'> <Icon icon='ATTACH' /><sub className="font-features subs">{row.original.totalAttachments}</sub></p>
          </div>
        }</span>
      </div>,
    },
    {
      Header: <div className='pb-8'> Action </div>,
      accessor: 'action',
      width: 50,
      Cell: ({ row: { original } }: any) => (<Action id={original.id} types={[ActionType.View]} navigation='s/feedbacks/view' title="view" componenetType={ComponentType.Button} />),
    }], []);

  return (
    <div className='flex flex-col h-screen'>
      <div className='h-16 bg-gray-200 flex items-center w-full justify-between p-2'>
        <Title icon="COMMENT" title="Support" />
        <div className="w-full flex justify-end mr-5">
          <Link to={'feedbackCategory'} ><Button className="text-xs py-1 bg-BeeMG-yellow" size='sm' id="btn_signIn"><div className='flex gap-x-1'><Icon icon='EDIT' className='fill-black' /><span className="font-bold flex items-center"> Support Category</span></div></Button></Link>
        </div>
        <NavRight />
      </div>
      <div className="items-center justify-center h-screen w-full font-semibold mb-4 rounded-lg bg-white overflow-auto overflow-x-hidden">
        {pageData && (
          <TableFormat
            columns={columns} data={pageData?.data} defaultColumn={defaultColumn} loading={isLoading} fetchData={fetchData}
            page={pagination} error={error}
          />
        )}
      </div>
    </div>
  )
}

export default Feedbacks