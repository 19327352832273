import { PagedData } from '../Models/pagedData.model';
import { Policies } from '../Models/policies.model';
import { allApis } from "./allApis";

export const policiesApi = allApis.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
        getAllTermAndPolicies: builder.query<PagedData<Policies>, any>({
            query: page => {
                return {
                    url: "policies",
                    params: page,
                };
            },       
            providesTags: ['TermsAndPolicies'],
        }),
        getTermAndPolicyById: builder.query<Policies, any>({
            query: id => {
                return {
                    url: `policies/${id}`,
                };
            },
            providesTags: ['TermsAndPolicies'],

        }),
        updateTermAndPolicy: builder.mutation<Policies, any>({
            query: (policies) => {
                return {
                    url: `policies/${policies.id}`,
                    method: 'PUT',
                    body: policies
                };
            },
            invalidatesTags: ['TermsAndPolicies'],
        }),
        deleteTermAndPolicy: builder.mutation<Policies, any>({
            query: (args) => {
                const {id} =args;
                return {
                    url: `policies/${id}`,
                    method: 'DELETE',
                };
            },
            invalidatesTags: ['TermsAndPolicies'],
        }),
        createTermAndPolicy: builder.mutation<Policies, any>({
            query: (policies) => {
                return {
                    url: `policies`,
                    method: 'POST',
                    body: policies
                };
            },
            invalidatesTags: ['TermsAndPolicies'],
        }),
        
    })
});

export const { 
    useCreateTermAndPolicyMutation, useDeleteTermAndPolicyMutation, useGetAllTermAndPoliciesQuery, useGetTermAndPolicyByIdQuery, useUpdateTermAndPolicyMutation,
  } = policiesApi;
