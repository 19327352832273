import { yupResolver } from '@hookform/resolvers/yup';
import { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Button } from '../../../Components';
import Field from '../../../Components/base/field/field';
import Icon from '../../../Components/base/icon/icon';
import Delete from '../../../Components/Buttons/Delete';
import UpdateButton from '../../../Components/Buttons/UpdateButton';
import { useUpdateTermAndPolicyMutation, useDeleteTermAndPolicyMutation, useGetTermAndPolicyByIdQuery, useCreateTermAndPolicyMutation } from '../../../Services/policiesApi';
import { toastError, toastSuccess } from '../../../Utils/toast';
import { policyValidationSchema } from '../../../Utils/validation';
import NavRight from '../../Navbar/NavRight';
import Title from '../../Navbar/Title';

interface FormData {
  policyId: string;
  policyVersion: string;
  policyName: string;
  description: string;
  id: string;

}



function ViewTermsPolicies() {

  const editorModules = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ 'font': [] }],
        [{ 'size': ['small', false, 'large', 'huge'] }],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'align': [] }],
        ['link', 'image', 'video'],
        ['clean'],
      ],
    },
  };


  const { id } = useParams();
  const navigate = useNavigate();


  const [serverError, setServerError] = useState<string>('');
  const [openUpdateModal, setOpenUpdateModal] = useState(false)

  const { register, handleSubmit, setValue, reset, getValues, watch, formState: { errors } } = useForm<FormData>({ resolver: yupResolver(policyValidationSchema) });
  const [createTermsAndPolicy] = useCreateTermAndPolicyMutation();
  const [updatePolicy] = useUpdateTermAndPolicyMutation();
  const [deletePolicy, { isSuccess: deletePolicySuccess }] = useDeleteTermAndPolicyMutation();
  const { data: policy } = useGetTermAndPolicyByIdQuery(id ? id : '', { skip: !id });
  const quillStoredContent = watch("description");


  const onSubmit = (values: any) => {

    if (policy?.id && id) {
      values.id = id;
      updatePolicy(values).then((res: any) => {
        if (res?.error) {
          toastError(res.error.data.message)
          setServerError(res.error.data.message);
        } else {
          setOpenUpdateModal(false)
          toastSuccess('Policy updated successfully')
          reset()
          navigate('/s/TermsAndPolicies')
        }
      })
    } else if (!id) {
      createTermsAndPolicy(values).then((res: any) => {
        if (res?.error) {
          toastError(res.error.data.message)
          setServerError(res.error.data.message);
        } else {
          setOpenUpdateModal(false)
          toastSuccess('Policy created successfully')
          reset()
          navigate('/s/TermsAndPolicies')
        }
      })
    }

  }

  useEffect(() => {
    if (id) {
      if (policy) {
        setValue('id', policy.id);
        setValue('policyId', policy.policyId);
        setValue('policyVersion', policy.policyVersion);
        setValue('policyName', policy.policyName);
        setValue('description', policy.description);
      } else {
        reset();
        setValue('id', '');
        setValue('policyId', '');
        setValue('policyVersion', '');
        setValue('policyName', '');
        setValue('description', '');
      }
    }
  }, [id, policy, reset, setValue]);

  useEffect(() => {
    if (deletePolicySuccess) {
      toastSuccess('Policy deleted successfully')
      navigate('/s/TermsAndPolicies');
    }
  }, [deletePolicySuccess]);

  return (
    <div className='flex flex-col h-screen'>
      <div className='h-16 bg-gray-200 flex items-center w-full justify-between p-2'>
        <Title title={id ? "Update Terms And Policies" : "Create Terms And Policies"} prePage='/s/TermsAndPolicies' />
        {id && <div className="w-full flex justify-end mr-5">
          <Link to={'/s/TermsAndPolicies/createTermsAndPolicy'}><Button className="text-xs py-1 bg-BeeMG-yellow" size='sm' id="btn_signIn" ><div className='flex gap-x-1'><Icon icon='ADD' className='fill-black' /><span className="font-bold flex items-center"> Create Terms &amp; Policy</span></div></Button></Link>
        </div>}
        <NavRight />
      </div>
      <div className="flex flex-col  h-screen w-full px-5  bg-gray-200 overflow-hidden">
        <div className="bg-white h-full w-full p-10 mb-4 rounded-lg overflow-y-auto">
          <form onSubmit={handleSubmit(onSubmit)} id="TermsAndPolicyForm">
            <div className="grid grid-cols-12 gap-y-6">
              <div className="flex flex-col col-span-2 font-semibold">
                <span>PolicyId</span>
              </div>
              <div className="flex flex-col col-span-10 w-1/3">
                <Field
                  {...register('policyId')} error={errors?.policyId?.message} type="text"
                  placeholder={'ID'} className='p-2 h-9' readOnly={policy?.id ? true : false}
                />
              </div>
              <div className="flex flex-col col-span-2 font-semibold">
                <span>Policy Version</span>
              </div>
              <div className="flex flex-col col-span-10 w-1/3">
                <Field
                  {...register('policyVersion')} error={errors?.policyVersion?.message} type="text"
                  placeholder={'Policy Version'} className='p-2 h-9' defaultValue={policy?.policyVersion}
                /></div>
              <div className="flex flex-col col-span-2 font-semibold">
                <span>Policy Name</span>
              </div>
              <div className="flex flex-col col-span-10 w-1/3"><Field
                {...register('policyName')} error={errors?.policyName?.message} type="text"
                placeholder={'Policy Name'} className='p-2 h-9' defaultValue={policy?.policyName}
              /></div>
              <div className="flex flex-col col-span-2 font-semibold">
                <span>Policy Description</span>
              </div>
              <div className="flex flex-col col-span-10 w-1/2">
                <div data-testid="input_selfIntro" className="mt-3">

                  <ReactQuill
                    theme="snow"
                    value={quillStoredContent as string || ''}
                    className="rounded-lg"
                    modules={editorModules}
                    onChange={(content) => {
                      setValue('description', content)
                    }}
                    id="description"

                  />



                </div>
              </div>
            </div>
            <div className='flex gap-3 mt-10'>
              {policy?.id && id && <>
                <Delete
                  mode="Policy"
                  deleteFunction={deletePolicy}
                  id={getValues('id')}
                  title={getValues('policyName')}
                  success={deletePolicySuccess}
                  afterSuccess="/s/TermsAndPolicies"
                />
                <UpdateButton formName='TermsAndPolicyForm' title={policy.policyName} mode='cancellation policy' value={'Update'} />
              </>}
              {!id && <Button className="px-4 py-1.5 uppercase bg-BeeMG-yellow" size='sm' submit><span className="font-bold" >Create</span></Button>}
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ViewTermsPolicies