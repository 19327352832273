import Icon from "../../Components/base/icon/icon";
import LanguageDropdown from "../dropdown/LanguageDropdown";
import ModeDropdown from "../dropdown/ModeDropdown";
import ProfileDropdown from "../dropdown/ProfileDropdown";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

function NavRight() {
  return (
    <>
      <div className="flex justify-end gap-x-2 pr-1 items-center z-50">
      <Tippy content="Help">
        <a
          href="https://help.beemg.com/portal/en/home"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="p-1 hover:bg-slate-100 rounded-full">
            <Icon icon="HELP" size="medium" className="fill-black" />
          </div>
        </a>
      </Tippy>
        <ModeDropdown />
        <LanguageDropdown />
        <ProfileDropdown />
      </div>
    </>
  );
}

export default NavRight;
