import { MeetingParticipant } from "../Models/meetingParticipant.model";
import { allApis } from "./allApis";

export const meetingPartcicipantApi = allApis.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
        
        getMeetingParticpantByMeetingId: builder.query<MeetingParticipant[], any>({
            query: id => {
                return {
                    url: `meetingParticipant/allParticipantsList/${id}`,
                };
            }
        }),
        getMeetingParticpantIdByMeetingId: builder.query<MeetingParticipant[], any>({
            query: id => {
                return {
                    url: `meetingParticipant/participantofMeeting/${id}`,
                };
            }
        }),
    }),
   
});


export const { useGetMeetingParticpantByMeetingIdQuery, useGetMeetingParticpantIdByMeetingIdQuery } = meetingPartcicipantApi;
