import React, { useMemo } from 'react'
import { Outlet } from 'react-router-dom';
import { Action, ActionType } from '../../Components/Action';
import Icon from '../../Components/base/icon/icon';
import useFetchDataHooks from '../../Hooks/FetchDataHooks';
import { useGetAllFeedbackCategoryQuery } from '../../Services/contactFeedbackCategoryApi';
import NavRight from '../Navbar/NavRight'
import Title from '../Navbar/Title'
import TableFormat from '../TableFormat';



function FeedbackCategory() {
    const { pageData, isLoading, fetchData, pagination, error } = useFetchDataHooks(useGetAllFeedbackCategoryQuery);

    const data = useMemo(() => {
        const displayData: any[] = [];

        pageData?.data?.forEach((feedbackCategory: any) => {
            const feedbackCategoryData = { ...feedbackCategory };

            displayData.push({
                filter: '',
                id: feedbackCategoryData.id,
                categoryName: feedbackCategoryData.categoryName,
                action: feedbackCategoryData.id,
            });
        });

        return displayData;
    }, [pageData]);

    const defaultColumn = React.useMemo(() => ({
    }), []);

    const columns = React.useMemo(() => [{
        Header: <div className="p-2 hover:bg-gray-200 rounded-full"><Icon icon="FILTER" className='flex flex-none' /></div>,
        accessor: 'filter',
        width: 25,
    },
    {
        Header: 'ID',
        accessor: 'id',
        width: 50,
    }, {
        Header: 'Category Name',
        accessor: 'categoryName',
    }, {
        Header: 'Action',
        accessor: 'action',
        Cell: ({ row: { original } }: any) => <Action id={original.id} types={[ActionType.Edit]} />,
        width: 70,
    }], []);

    return (
        <div className='flex flex-col h-screen'>
            <div className='h-16 bg-gray-200 flex items-center w-full justify-between p-2'>
                <Title title="Feedback Category" prePage='/s/feedbacks' />
                <NavRight />
            </div>

            <div className="grid grid-cols-12 flex-row lg:flex-col justify-center w-full mb-4 rounded-lg bg-white h-full lg:overflow-x-hidden">
                <div className="lg:col-span-7 col-span-12 border-r">
                    {pageData && (
                        <TableFormat
                            columns={columns} data={data} defaultColumn={defaultColumn} loading={isLoading} fetchData={fetchData}
                            page={pagination} tab="Cancellation Request" error={error}
                        />
                    )}
                </div>
                <div className="lg:col-span-5 col-span-12 p-3"><Outlet /></div>
            </div>
        </div>
    )
}

export default FeedbackCategory