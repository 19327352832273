import { Transition, Dialog } from '@headlessui/react';
import { Fragment, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import Icon from '../../Components/base/icon/icon';
import { Processed } from '../../Models/cancellationRequest.model';
import { Ledger } from '../../Models/ledger.model';
import { useGetCancellationRequestByIdQuery } from '../../Services/cancellationRequestApi';
import { useAppDispatch } from '../../Store/hooks';
import { formatDateTimeFullToTimezoneFormatted, formatDateToTimezone2, formatTimeToTimezone2, isBetween } from '../../Utils/datetime';
import NavRight from '../Navbar/NavRight'
import Title from '../Navbar/Title'
import { format, isAfter, isBefore, subMinutes } from 'date-fns'
import CancellationStatusCard, { CancellationStatus } from './CancellationStatusCard';
import { transactionApi } from '../../Services/transactionApi';


function ViewCancellationRequest() {
    const { id } = useParams();
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let [isOpen, setIsOpen] = useState(false)
    const { data: cancellationRequest, isLoading: cancellationRequestLoading, isSuccess: cancellationRequestSuccess, refetch: refetchCancellationRequest } = useGetCancellationRequestByIdQuery(id ? id : '', { skip: !id });
    const [cancellationLedger, setCancellationLedger] = useState<Ledger>();
    const [refundFull, setRefundFull] = useState<string>('')
    const [refundHalf, setRefundHalf] = useState<string>('')
    const [refundNull, setRefundNull] = useState<string>('')
    const dispatch = useAppDispatch();
    let ChargeTimeFrom: any;
    let ChargeTimeTo: any;
    let FullRefund: Boolean;
    let NullRefund: Boolean;
    let HalfRefund: Boolean;

    const processForRefund = (id: string) => {
        dispatch(transactionApi.endpoints.getCancellationLedgerById.initiate(id)).then((val: any) => {
            setCancellationLedger(val.data);
            refetchCancellationRequest();
        });
    }

    function closeModal() {
        setIsOpen(false)
    }
    function openModal() {
        setIsOpen(true)
    }


    useEffect(() => {
        if (cancellationRequestSuccess) {
            if (cancellationRequest?.requestDateTime && isBetween(new Date(), cancellationRequest?.requestDateTime, new Date(cancellationRequest?.meeting.fromDateTime))) {
                if (cancellationRequest?.meeting?.meetingDetails?.cancellationPolicy?.chargePeriodFromTimeInMinute) {
                    ChargeTimeFrom = format(subMinutes(new Date(cancellationRequest?.meeting?.fromDateTime), Number(cancellationRequest?.meeting.meetingDetails?.cancellationPolicy?.chargePeriodFromTimeInMinute)))
                    FullRefund = isBefore(new Date(), new Date(ChargeTimeFrom))
                }
                if (cancellationRequest?.meeting?.meetingDetails?.cancellationPolicy?.chargePeriodToTimeInMinute) {
                    ChargeTimeTo = format(subMinutes(new Date(cancellationRequest?.meeting?.fromDateTime), Number(cancellationRequest?.meeting.meetingDetails?.cancellationPolicy?.chargePeriodToTimeInMinute)))
                    NullRefund = isAfter(new Date(), new Date(ChargeTimeTo))
                    HalfRefund = isBetween(new Date(), new Date(ChargeTimeFrom), new Date(ChargeTimeTo))
                }
            }
            if (FullRefund) {
                setRefundFull(`${cancellationRequest?.meeting?.meetingDetails?.cancellationPolicy?.policyType} - No Cancellation Charges`)
            }
            if (NullRefund) {
                setRefundNull(`${cancellationRequest?.meeting?.meetingDetails?.cancellationPolicy?.policyType} - No Refund`)
            }
            if (HalfRefund) {
                setRefundHalf(`${cancellationRequest?.meeting?.meetingDetails?.cancellationPolicy?.policyType} - ${cancellationRequest?.meeting?.meetingDetails?.cancellationPolicy?.deductionPercentage}% (Before ${cancellationRequest?.meeting?.meetingDetails?.cancellationPolicy?.chargePeriodFromTime} hours for the meeting)`)
            }
        }
    }, [cancellationRequest]);

    if (!cancellationRequestSuccess || cancellationRequestLoading)
        return <div>Loading...</div>

    return (
        <div className='flex flex-col h-screen'>
            <div className='h-16 bg-gray-200 flex items-center w-full justify-between p-2'>
                <Title justBack={true} title="View Cancellation Request" />
                <NavRight />
            </div>
            <div className="flex flex-row h-screen w-full font-semibold mb-4 rounded-lg bg-white">
                <div className="grid gap-5 mx-10 my-5 pr-5 border-r-2">
                    <div className="col-span-4 w-full bg-white p-4 flex flex-col gap-y-3 rounded-xl shadow-md border-t">
                        <div className='font-bold text-lg'>Request Details</div>
                        <div className="text-sm flex flex-col gap-y-3">
                            <div className="grid grid-cols-2">
                                <div>Requested By</div>
                                <div className="flex gap-x-2 justify-end">
                                    <div className='truncate'>{cancellationRequest?.user?.firstName} {cancellationRequest?.user?.lastName}</div>
                                    <div>
                                        {cancellationRequest?.user?.verified === 'Complete' && <Icon icon="VERIFIED" color='green' />}
                                    </div>
                                </div>
                            </div>
                            <div className="grid grid-cols-2">
                                <div>Requested Date</div>
                                <div className='flex justify-end'>{formatDateToTimezone2(timezone, cancellationRequest?.requestDateTime.toString())}</div>
                            </div>
                            <div className="grid grid-cols-2">
                                <div>Requested Time</div>
                                <div className='flex justify-end'>{formatTimeToTimezone2(timezone, cancellationRequest?.requestDateTime.toString())}</div>
                            </div>
                            {cancellationRequest?.meetingParticipant && <div className="grid grid-cols-2">
                                <div>Requested For</div>     
                                <div className={'flex justify-end'}>
                                 {cancellationRequest?.meetingParticipant?.user?.firstName || 'User not available'}
                                </div> 
                            </div>}
                        </div>
                    </div>
                    <div className="col-span-4 w-full bg-white p-4 flex flex-col gap-y-3 rounded-xl shadow-md border-t">
                        <div className='font-bold text-lg'>Event Details</div>
                        <div className="text-sm flex flex-col gap-y-3">
                            <div className="grid grid-cols-2">
                                <div >Event title</div>
                                <div className="flex gap-x-2 justify-end">
                                    <div className='truncate'>{cancellationRequest?.meeting?.meetingDetails?.title}</div>
                                </div>
                            </div>
                            <div className="grid grid-cols-2">
                                <div>Hosted By</div>
                                <div className="flex gap-x-2 justify-end">
                                    <div className='truncate'>{cancellationRequest?.meeting?.meetingDetails?.host?.firstName} {cancellationRequest?.meeting?.meetingDetails?.host?.lastName}</div>
                                    <div>
                                        {cancellationRequest?.user?.verified === 'Complete' && <Icon icon="VERIFIED" color='green' />}
                                    </div>
                                </div>
                            </div>
                            <div className="grid grid-cols-2">
                                <div>Scheduled On</div>
                                <div className='flex justify-end'>{formatDateToTimezone2(timezone, cancellationRequest?.meeting?.fromDateTime.toString())}</div>
                            </div>
                            <div className="grid grid-cols-2">
                                <div>Event Time</div>
                                <div className='flex justify-end'>{formatTimeToTimezone2(timezone, cancellationRequest?.meeting?.fromDateTime.toString())} - {formatTimeToTimezone2(timezone, cancellationRequest?.meeting?.toDateTime.toString())}</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-4 w-full bg-white p-4 flex flex-col gap-y-3 rounded-xl shadow-md border-t">
                        <div className='font-bold text-lg'>Transaction Details</div>
                        <div className="text-sm flex flex-col gap-y-3">
                            <div className="grid grid-cols-2">
                                <div>Transaction Amount</div>
                                <div className="flex gap-x-2 justify-end">
                                    <div>{cancellationRequest?.ledger?.transactionAmount.toLocaleString('en-IN')}</div>
                                    <div>{cancellationRequest?.ledger?.currency}</div>
                                </div>
                            </div>
                            <div className="grid grid-cols-2">
                                <div>Transaction Date</div>
                                <div className='flex justify-end'>{formatDateToTimezone2(timezone, cancellationRequest?.ledger?.transactionDateTime.toString())}</div>
                            </div>
                            <div className="grid grid-cols-2">
                                <div>Transaction Time</div>
                                <div className='flex justify-end'>{formatTimeToTimezone2(timezone, cancellationRequest?.ledger?.transactionDateTime.toString())}</div>
                            </div>
                            <div className="grid grid-cols-2">
                                <div>Transaction Status</div>
                                <div className={cancellationRequest?.ledger?.transactionStatus === 'Completed' ? `text-green-600 flex justify-end` : `text-red-500 flex justify-end`}>{cancellationRequest?.ledger?.transactionStatus}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="grid grid-rows-12 grid-flow-col gap-1 my-5 py-5">

                    <CancellationStatusCard
                        title={"Cancellation Requested"}
                        date={formatDateTimeFullToTimezoneFormatted(timezone, cancellationRequest?.requestDateTime.toString())}
                        status={cancellationRequest ? CancellationStatus.Success : CancellationStatus.Pending}
                        content={refundFull ? refundFull : refundNull ? refundNull : refundHalf ? refundHalf : ""}
                    />

                    <CancellationStatusCard
                        title={"Request Processing"}
                        date={formatDateTimeFullToTimezoneFormatted(timezone, cancellationRequest?.createdAt.toString())}
                        status={cancellationRequest.processed === Processed.Yes ? CancellationStatus.Success : cancellationRequest.processed === Processed.No ? CancellationStatus.Failed : CancellationStatus.Pending}
                    />

                    <CancellationStatusCard
                        title={"Cancellation approval"}
                        date={cancellationRequest?.refundLedger ? formatDateTimeFullToTimezoneFormatted(timezone, cancellationRequest?.refundLedger?.createdAt.toString()) : 'Denied'}
                        status={cancellationRequest?.refundLedger ? CancellationStatus.Success : CancellationStatus.Pending}
                    />

                    <CancellationStatusCard
                        title={cancellationRequest?.refundLedger ? "Refund Success" : "Amount refund failed"}
                        content={cancellationRequest?.refundLedger ? `Amount Refunded ${cancellationRequest?.refundLedger?.transactionAmount.toLocaleString('en-IN')} ${cancellationRequest?.ledger?.currency}` : `Couldn't refund amount, Refunding amount failed`}
                        status={cancellationRequest?.refundLedger ? CancellationStatus.Success : CancellationStatus.Failed}
                    />
                   

                </div>

                {cancellationLedger?.id &&
                    <div className='mx-20 mt-10 w-1/3 flex flex-col gap-3'>
                        <div className='font-bold'>Refund Details</div>
                        <div className='gap-x-5 grid grid-cols-5'>
                            <div className='col-span-2'>Refunded On</div>
                            <div>:</div>
                            <div className='col-span-2'>{formatDateToTimezone2(timezone, cancellationLedger?.createdAt.toString())}</div>
                        </div>
                        <div className='gap-x-5 grid grid-cols-5'>
                            <div className='col-span-2'>Time</div>
                            <div>:</div>
                            <div className='col-span-2'>{formatTimeToTimezone2(timezone, cancellationLedger?.createdAt.toString())}</div>
                        </div>
                        <div className='gap-x-5 grid grid-cols-5'>
                            <div className='col-span-2'>Transaction Status</div>
                            <div className='col-span-1'>:</div>
                            <div className={`text-white text-xs font-semibold mr-2 px-2.5 w-fit py-0.5 rounded-lg col-span-2 ${cancellationLedger?.transactionStatus === 'Submitted' ? 'bg-blue-600' : cancellationLedger?.transactionStatus === 'Completed' ? `bg-green-600` : `bg-red-600`}`}>{cancellationLedger?.transactionStatus}</div>
                        </div>
                    </div>
                }
            </div>
            {/* <div className="flex justify-center w-full mt-5">
                <Button className="text-xs  bg-BeeMG-yellow py-1" disabled={cancellationRequest?.processed === 'Yes'} size='sm' onClick={() => { openModal() }}>
                    <span className="font-bold">Process for refund</span>
                </Button>
            </div> */}
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-medium leading-6 text-gray-900"
                                    >
                                        Refund Confirmation
                                    </Dialog.Title>
                                    <div className="mt-2">
                                        <p className="text-gray-800">
                                            Are You Sure Refund To " <span className="font-semibold">{cancellationRequest?.user?.firstName} {cancellationRequest?.user?.lastName}</span>" ?
                                        </p>
                                    </div>

                                    <div className="mt-4 flex justify-end gap-3">
                                        <button
                                            type="button"
                                            className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                            onClick={closeModal}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="button"
                                            className="inline-flex justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 text-sm font-medium text-red-900 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                                            onClick={() => { closeModal(); processForRefund(cancellationRequest?.id) }}
                                        >
                                            Yes, I'm
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div>
    )
}

export default ViewCancellationRequest