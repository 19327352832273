import { Link, useParams } from 'react-router-dom'
import { Button } from '../../Components'
import { useGetByIdQuery } from '../../Services/avatarApi'
import { useGetSettingValue } from '../../Services/settingReducer'

const style = {
  avatarImg: `h-20 w-20 md:h-36 md:w-36 bg-blend-multiply rounded-full`,
  avatarHolder: `bg-gray-200 rounded-full`
}

function ViewAvatar() {
  const { id } = useParams();
  const {data: avatar, isSuccess: avatarSuccess} = useGetByIdQuery(id ? id : '', {skip: !id});
  const baseImageURL = useGetSettingValue('IMAGE_URL');
  let imageName;

  if(avatarSuccess) {
    imageName = baseImageURL + '/' + avatar.avatarName;
  }
  
  return (
    <div className='flex flex-col h-full'>
      <div className="flex justify-between py-3 px-2">
        <div className='font-semibold'>Avatars</div>
        <div>
          <Link to={'/s/users/avatars/create'}>
            <Button className="text-xs py-1 bg-BeeMG-yellow" size='sm' id="btn_signIn">
              <div className='flex gap-x-1'><span className="font-bold flex items-center whitespace-nowrap"> Add new</span></div>
            </Button>
          </Link>
        </div>
      </div>
      <div className="flex flex-grow h-80vh overflow-y-auto overflow-x-hidden liteScroll">
        <div className="grid md:grid-cols-3 grid-cols-2 gap-5 mt-5 w-full px-2 h-fit">
          {imageName && (
            <div className='flex flex-col items-center'>
              <img src={imageName} className={style.avatarImg} alt={`Avatar1`} />
              <div className='truncate'>{avatar?.avatarName}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ViewAvatar