import NavRight from '../Navbar/NavRight'
import Title from '../Navbar/Title'
import { Fragment } from "react";
import { useForm } from "react-hook-form";
import { Button } from "../../Components";
import Field from "../../Components/base/field/field";
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { useDeleteUserbyIdMutation, useGetUsersByIdQuery, useGetUsersBySubQuery } from '../../Services/userApi';
import defaultImage from '../../Assets/img/userImage.png';
import { useGetSettingValue } from '../../Services/settingReducer';
import Delete from '../../Components/Buttons/Delete';
import DropDown from './DropDown';
import { useGetAllReportedUserByIdQuery } from '../../Services/reportApi';
import { Name } from '../../Models/group.model';
import { useUpdateCommissionPercentageMutation } from '../../Services/group.Api';

function ViewUser() {

  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const backScreen = searchParams.get('back')
  const backScreenId = searchParams.get('id')
  const baseImageURL = useGetSettingValue('IMAGE_URL');
  const regex = /(<([^>]+)>)/gi;
  const { handleSubmit, formState: { errors } } = useForm<any>();
  const { data: reportedUser, isLoading: reportedUserLoading, isSuccess: reportedUserSuccess } = useGetAllReportedUserByIdQuery(id ? id : '', { skip: !id });
  const { data: userData, isLoading: userLoading, isSuccess: userSuccess, isError: userError } = useGetUsersByIdQuery(id ? id : '', { skip: !id });
  const { data: userDetails, isLoading: userDetailsLoading, isSuccess: userDetailsSuccess } = useGetUsersBySubQuery(userData?.sub ? userData?.sub : '', { skip: !userData?.sub });
  const [updateCommissionPercentage] = useUpdateCommissionPercentageMutation();
  let imageName = baseImageURL + '/' + userData?.image[0]?.imageName;
  const [deleteUser, { isSuccess: deleteUserSuccess }] = useDeleteUserbyIdMutation();

  const onSubmit = () => {
    console.log("InProgress");
  }

  if (userLoading || userDetailsLoading || !userData) {
    return <>Loading...</>
  }
  const Id = userData?.id

  let returnURL = '/s/users/';
  let returnTitle = 'View User';
  if (backScreen) {
    returnURL = "/s/reportedUsers/view/" + backScreenId;
    returnTitle = 'View Reporter'
  }

  return (
    <div className='flex flex-col h-screen'>
      <div className='h-16 bg-gray-200 flex items-center w-full justify-between p-2'>
        <Title title={returnTitle} prePage={returnURL} />
        <div className='ml-auto'>
          <Link to={'/s/users/eventList/' + Id}><Button className="px-4 py-1.5 uppercase bg-BeeMG-yellow" color='category' size='sm' submit><span className="font-bold" >view Events</span></Button></Link>
          <Link to={'/s/users/transactions/' + Id}><Button className="px-4 py-1.5 uppercase bg-BeeMG-yellow mx-6" color='category' size='sm' submit><span className="font-bold" >view Transactions</span></Button></Link> </div>
        <NavRight />
      </div>
      <div className="flex flex-col items-center justify-center h-screen w-full  font-semibold mb-4 rounded-lg bg-white overflow-hidden">
        <div className="flex flex-col  h-screen w-full px-5  bg-gray-200 overflow-hidden">
          <div className="flex bg-white h-full w-full p-10 mb-4 rounded-lg overflow-y-auto overflow-x-hidden">
            <div className='flex flex-auto w-1/2 sticky'>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="grid grid-cols-11 gap-y-6">
                  <div className="flex flex-col col-span-2 font-semibold">
                    <span>ID</span>
                  </div>

                  <div className="flex flex-col col-span-7 ">
                    <Field
                      name='userId'
                      type="userId"
                      placeholder={'userId'} className='p-2 h-9'
                      defaultValue={userData?.id}
                      disabled={true}
                    />
                  </div><div className="col-span-2"></div>

                  <div className="flex flex-col col-span-2 font-semibold">
                    <span>Created At</span>
                  </div>

                  <div className="flex flex-col col-span-7">
                    <Field
                      name='createdAt'
                      type="createdAt"
                      placeholder={'createdAt'} className='p-2 h-9'
                      disabled={true}
                      defaultValue={userData?.createdAt.toString()}
                    />
                  </div><div className="col-span-2"></div>

                  <div className="flex flex-col col-span-2 font-semibold">
                    <span>IP Address</span>
                  </div>

                  <div className="flex flex-col col-span-7 ">
                    <Field
                      name='ipAddress' type="ipAddress"
                      placeholder={'ipAddress'} className='p-2 h-9'
                      defaultValue={userData?.ipAddress}
                      disabled={true}
                    /></div><div className="col-span-2"></div>

                  <div className="flex flex-col col-span-2 font-semibold">
                    <span>First Name</span>
                  </div>

                  <div className="flex flex-col col-span-7 ">
                    <Field
                      name='firstName'
                      type="firstName"
                      placeholder={'firstName'} className='p-2 h-9'
                      defaultValue={userData?.firstName}
                      disabled={true}
                    />
                  </div><div className="col-span-2"></div>

                  <div className="flex flex-col col-span-2 font-semibold">
                    <span>Last Name</span>
                  </div>

                  <div className="flex flex-col col-span-7 ">
                    <Field
                      name='lastName' type="lastName"
                      placeholder={'lastName'} className='p-2 h-9'
                      defaultValue={userData?.lastName}
                      disabled={true}
                    />
                  </div>
                  {userData?.catchPhrase &&
                    <>
                      <div className="col-span-2"></div>
                      <div className="flex flex-col col-span-2 font-semibold">
                        <span>Catch Phrase</span>
                      </div>
                      <div className="flex flex-col col-span-7">
                        <Field
                          name='catchPhrase' type="catchPhrase"
                          placeholder={'catchPhrase'} className='p-2 h-9'
                          defaultValue={userData?.catchPhrase}
                          disabled={true}
                        />
                      </div>
                    </>}
                  <div className="col-span-2"></div>

                  {userData?.selfIntro &&
                    <><div className="flex flex-col col-span-2 font-semibold">
                      <span>Introduction</span>
                    </div>
                      <div className="flex flex-col col-span-7 ">
                        <Field
                          name='introduction' type="introduction"
                          placeholder={'introduction'} className='p-2 h-9'
                          disabled={true}
                          defaultValue={userData?.selfIntro.replace(regex, "")} /></div>
                      <div className="col-span-2"></div></>
                  }

                  <div className="flex flex-col col-span-2 font-semibold">
                    <span>Email</span>
                  </div>

                  <div className="flex flex-col col-span-7">
                    <Field
                      name='email' type="email"
                      placeholder={'email'} className='p-2 h-9'
                      defaultValue={userDetails?.email}
                      disabled={true}
                    /></div>
                  <div className="col-span-2"></div>

                  <div className="flex flex-col col-span-2 font-semibold">
                    <span>Date of Birth</span>
                  </div>

                  <div className="flex flex-col col-span-7">
                    <Field
                      name='dob' type="dob"
                      placeholder={'dob'} className='p-2 h-9'
                      defaultValue={userDetails?.birthdate}
                      disabled={true}
                    />
                  </div><div className="col-span-2"></div>

                  <div className="flex flex-col col-span-2 font-semibold">
                    <span>Gender</span>
                  </div>

                  <div className="flex flex-col col-span-7">
                    <Field
                      name='gender' type="gender"
                      placeholder={'gender'} className='p-2 h-9'
                      defaultValue={userData?.gender}
                      disabled={true}
                    />
                  </div> <div className="col-span-2"></div>

                  <div className="flex flex-col col-span-2 font-semibold">
                    <span>Phone Number</span>
                  </div>

                  <div className="flex flex-col col-span-7">
                    <Field
                      name='phoneNo'
                      type="phoneNo"
                      placeholder={'phoneNo'} className='p-2 h-9'
                      defaultValue={userDetails?.phone_number}
                      disabled={true}
                    />
                  </div> <div className="col-span-2"></div>

                  <div className="flex flex-col col-span-2 font-semibold">
                    <span>Platform Host Group Name</span>
                  </div>

                  <div className="flex flex-col col-span-7 ">
                    <DropDown items={Object.values(Name)} group={userData?.group} updateCommissionPercentage={updateCommissionPercentage} />
                  </div><div className="col-span-2"></div>

                  <div className="flex flex-col col-span-2 font-semibold">
                    <span>Platform Commission</span>
                  </div>

                  <div className="flex flex-col col-span-7 ">
                    <div className='relative'>
                      <input
                        name='commission' type="text"
                        disabled
                        placeholder={'commission'} className='leading-5 relative flex flex-1 w-full rounded-lg p-input text-gray-400 md:text-base placeholder:text-sm placeholder-gray-400 text-sm focus:outline-none focus:ring-1 focus:ring-BeeMG-yellow p-2 h-8 pl-3 cursor-text bg-gray-200 '
                        value={userData?.group?.commissionPercentage}
                      />
                      {<span className='absolute right-3 top-1 text-gray-400' > % </span>}
                    </div>
                  </div>
                  <div className="col-span-2"></div>
                  <div className="flex flex-col col-span-2 font-semibold">
                    <span>City</span>
                  </div>

                  <div className="flex flex-col col-span-7">
                    <Field
                      name='city' type="city"
                      placeholder={'city'} className='p-2 h-9'
                      defaultValue={userData?.city}
                      disabled={true}
                    />
                  </div><div className="col-span-2"></div>

                  <div className="flex flex-col col-span-2 font-semibold">
                    <span>Country</span>
                  </div>

                  <div className="flex flex-col col-span-7">
                    <Field
                      name='country' type="country"
                      placeholder={'country'} className='p-2 h-9'
                      defaultValue={userData?.country}
                      disabled={true}
                    />
                  </div><div className="col-span-2"></div>
                  <div className='flex gap-3 mb-8'>
                    <Delete
                      mode="User"
                      deleteFunction={deleteUser}
                      id={userData ? userData?.id : ""}
                      title={userData ? userData?.firstName : ""}
                      success={deleteUserSuccess}
                      afterSuccess="/s/users"
                    />
                    <Button className="px-5 py-1.5 uppercase" color='warning' size='sm'><span className="font-bold" >Save</span></Button>
                  </div>
                </div>
              </form>
            </div>
            <div className='flex flex-row w-1/2 overflow-y-scroll -mb-96'>
              <div className='flex flex-col gap-4'>
                <div className='text-center truncate'>User Image </div>
                {userData?.image[0]?.imageName ? <img src={imageName} className='w-40 h-40 rounded-full center' alt={`Avatar1`} />
                  : <img src={defaultImage} className='w-40 h-40 rounded-full center' alt={`Avatar1`} />}
                {userData?.userCategory &&
                  <div className='text-center truncate'>User Interests </div>}
                <div className='mt-4 gap-1 flex flex-wrap'>
                  {userData?.userCategory.map((item, index) => (
                  item.category?.name && <Button className="px-4 pr-10 py-1 text-sm rounded-sm flex flex-row items-center justify-between relative uppercase bg-BeeMG-yellow" color='userCategory' size='sm' submit><span className="font-bold">{item.category?.name}</span></Button>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ViewUser