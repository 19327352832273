import { avatarApi } from './../Services/avatarApi';
import { loginApi } from './../Services/loginApi';
import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import { persistReducer, persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import { userApi } from '../Services/userApi';
import tokenReducer from '../Services/tokenReducer';
import adminUserReducer from '../Services/adminUserReducer';

const reducers = combineReducers({
    [userApi.reducerPath]: userApi.reducer,
    [loginApi.reducerPath]: loginApi.reducer,
    [avatarApi.reducerPath]: avatarApi.reducer,
    token: tokenReducer,
    adminUser: adminUserReducer,
});

const persistConfig = {
    key: 'root',
    storage: AsyncStorage,
    whitelist: ['token', 'adminUser'],
    transforms: [
        encryptTransform({
            secretKey: 'my-super-secret-key', // To Do
            onError: function (error: any) {
                // Handle the error.
            },
        }),
    ],
}

const persistedReducer = persistReducer(persistConfig, reducers)

const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware => {
        const middlewares = getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        })
        .concat(userApi.middleware)
        .concat(loginApi.middleware)
        .concat(avatarApi.middleware);

        return middlewares
    },
})

const persistor = persistStore(store)
setupListeners(store.dispatch)
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export {store, persistor}
