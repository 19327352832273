import { Transition, Dialog } from '@headlessui/react';
import { Fragment, useState } from 'react'
import { useParams } from 'react-router-dom';
import { Button } from '../../Components';
import Icon from '../../Components/base/icon/icon';
import { useGetAllContactByIdQuery } from '../../Services/contactApi';
import { useGetFeedbackAttachmentIdQuery } from '../../Services/contactFeedbackCategoryApi';
import { useGetSettingValue } from '../../Services/settingReducer';
import { getUTFormattedDate, getUTFormattedTime } from '../../Utils/datetime';
import NavRight from '../Navbar/NavRight'
import Title from '../Navbar/Title'

function ViewFeedback() {
    const { id } = useParams();
    const baseImageURL = useGetSettingValue('IMAGE_URL');
    let [imageIndex, setImageIndex] = useState<number>(0);
    const { data: feedback, isLoading: feedbackLoading, isSuccess: feedbackSuccess } = useGetAllContactByIdQuery(id ? id : '', { skip: !id });
    const { data: attachment, isLoading: attachmentLoading } = useGetFeedbackAttachmentIdQuery(id ? id : '', { skip: !id });

    let [isOpen, setIsOpen] = useState(false)
    function closeModal() {
        setIsOpen(false)
    }

    function openModal() {
        setIsOpen(true)
    }

    if (attachmentLoading || feedbackLoading)
        return <div>Loading...</div>
    if (!feedbackSuccess)
        return <div>No data Found...</div>

    return (
        <div className='flex flex-col h-screen'>
            <div className='h-16 bg-gray-200 flex items-center w-full justify-between p-2'>
                <Title prePage='/s/feedbacks' title="View Support" />
                <NavRight />
            </div>
            <div className="flex flex-col  h-screen w-full px-5  bg-gray-200 overflow-hidden">
                <div className="bg-white h-full w-full p-10 mb-4 rounded-lg overflow-y-auto">
                    <div className="grid grid-cols-12 gap-y-6">
                        <div className="col-span-1 font-semibold">
                            {feedback.user ? <span>User Name</span> : <span>Email</span>}
                        </div>
                        <div className="col-span-1 font-semibold text-center">:</div>
                        <div className="col-span-10 w-fit pl-2">
                            {feedback.user ? <span>{feedback.user.firstName} {feedback.user.lastName}</span> : <span>{feedback.email}</span>}
                        </div>
                        <div className="col-span-1 font-semibold">
                            <span>Date </span>
                        </div>
                        <div className="col-span-1 font-semibold text-center">:</div>
                        <div className="col-span-10 w-fit pl-2">
                            {getUTFormattedDate(feedback.createdAt.toString())}
                        </div>
                        <div className="col-span-1 font-semibold">
                            <span>Time </span>
                        </div>
                        <div className="col-span-1 font-semibold text-center">:</div>
                        <div className="flex-wrap col-span-10 pl-2">
                            {getUTFormattedTime(feedback.createdAt.toString())}
                        </div>
                        <div className="col-span-1 font-semibold">
                            <span>Subject </span>
                        </div>
                        <div className="col-span-1 font-semibold text-center">:</div>
                        <div className='col-span-10 w-fit pl-2'>
                            <div> 
                                {feedback.subject}
                            </div>
                        </div>
                        <div className="col-span-1 font-semibold">
                            <span>Category </span>
                        </div>
                        <div className="col-span-1 font-semibold text-center">:</div>
                        <div className="col-span-10 w-fit">
                            <Button className=" bg-amber-400 normal-case text-sm px-4 p-1 font-semibold cursor-default">
                                {feedback.category}
                            </Button>
                        </div>
                        <div className="col-span-1 font-semibold ">
                            <span>Support </span>
                        </div>
                        <div className="col-span-1 font-semibold text-center">:</div>
                        <div className='col-span-10 w-fit pl-2'>
                            <div>
                                {feedback.feedback}
                            </div>
                        </div>
                        <div className="col-span-1 font-semibold">
                            <span>Support Attachments </span>
                        </div>
                        <div className="col-span-1 font-semibold text-center">:</div>
                        <div className="col-span-10 overflow-auto pl-2.5">
                            <div className="flex gap-x-3">
                                {attachment?.map((file: string, index: number) => (
                                    <>
                                        <img src={`${baseImageURL}/${file}`} alt="Attachments" className='w-28 h-28 rounded-md cursor-pointer border' onClick={() => { openModal(); setImageIndex(index) }} />
                                    </>
                                ))}
                                {attachment === undefined && <div>No Attachments</div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-50" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-screen h-screen items-center transform overflow-hidden bg-gray-900/50 text-left align-middle shadow-xl transition-all overflow-y-auto">
                                    <div className="flex items-center px-5 justify-center h-full my-10">
                                        <button className='cursor-pointer hover:bg-white/80 rounded-full flex justify-center items-center h-16 w-16 p-5 disabled:opacity-0 disabled:cursor-default focus:outline-none absolute left-10 inset-y-1/2' disabled={imageIndex > 0 ? false : true} onClick={() => setImageIndex(--imageIndex)}><Icon icon="LEFT" color='black' size='large' /></button>
                                        <div className="relative">
                                            {attachment &&
                                                <>
                                                    <img src={`${baseImageURL}/${attachment[imageIndex]}`} alt="BeeMG" className='select-none rounded-md transition-all ease-in-out duration-700 max-w-9xl max-h-screen overflow-y-auto' />
                                                </>
                                            }
                                            <div onClick={closeModal} className="absolute -top-8 -right-8 w-6 h-6 rounded-full flex justify-center items-center"><Icon icon="CLOSE" color='white' size='medium' /></div>
                                        </div>
                                        <button className='cursor-pointer hover:bg-white/80 rounded-full flex justify-center items-center h-16 w-16 disabled:opacity-0 disabled:cursor-default focus:outline-none absolute right-10 inset-y-1/2' disabled={attachment && attachment?.length > (imageIndex + 1) ? false : true} onClick={() => setImageIndex(++imageIndex)}><Icon icon="RIGHT" color='black' size='large' /></button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div >
    )
}

export default ViewFeedback