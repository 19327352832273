import { Transition, Dialog } from '@headlessui/react'
import React, { Fragment, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toastSuccess } from '../../Utils/toast'
import Button from '../base/button/button'
import Icon from '../base/icon/icon'

export enum ComponentType { 'Icon', 'Button' }

interface IDelete {
    type?: ComponentType,
    mode: string,
    deleteFunction: any,
    id: string,
    title: string,
    success?: any;
    screen?: any;
    afterSuccess?: string;
}

function Delete({ type = ComponentType.Button, mode, deleteFunction, id, title, screen, success, afterSuccess }: IDelete) {

    const navigate = useNavigate();
    const [isSuccess, setIsSuccess] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [notifyEmail, setNotifyEmail] = useState<boolean>(false);

    function closeModal() {
        setIsOpen(false)
    }
    function openModal() {
        setIsOpen(true)
    }

    useEffect(() => {
        if (isSuccess) {
            toastSuccess(mode + " deleted successfully")
            if (afterSuccess)
                navigate(afterSuccess)
        }
    }, [success, isSuccess])

    return (
        <>
            {type === ComponentType.Button && <Button className="px-5 py-1.5 uppercase" color='danger' size='sm' onClick={openModal}>Delete</Button>}
            {type === ComponentType.Icon && <Icon icon="DELETE" onClick={openModal} className="cursor-pointer" />}

            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-medium leading-6 text-gray-900"
                                    >
                                        Delete Confirmation
                                    </Dialog.Title>
                                    <div className="mt-2">
                                        <p className="text-gray-800">
                                            Are you sure to delete this " <span className="font-semibold">{title}</span>" {mode}?
                                        </p>
                                    </div>
                                    {screen == 'interests' && (
                                    <span className="float-left mr-2">
                                       <input
                                            type="checkbox"
                                            id="args"
                                            onClick={((e: any) =>{
                                                console.log(e.target.checked)
                                                setNotifyEmail(e.target.checked)
                                            })}
                                        />
                                      <label htmlFor="args"> Notify Email</label>
                                    </span>
                                     )}
                                    <div className="mt-4 flex justify-end gap-3">
                                        <button
                                            type="button"
                                            className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                            onClick={closeModal}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="button"
                                            className="inline-flex justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 text-sm font-medium text-red-900 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                                            onClick={() => { 
                                                closeModal(); 
                                                deleteFunction({id, notifyEmail }).then((res: any)=>{
                                                setIsSuccess(res.data)
                                            }) }}
                                        >
                                            Yes, I'm
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}

export default Delete