import { useState, useEffect, Fragment } from 'react'
import { useForm } from 'react-hook-form';
import { useParams, useNavigate } from 'react-router-dom';
import Button from '../../../Components/base/button/button';
import Field from '../../../Components/base/field/field';
import { useCreateAdminUserMutation, useDeleteAdminUserMutation, useGetAdminUsersByIdQuery, useUpdateAdminUserMutation } from '../../../Services/adminUserApi';
import NavRight from '../../Navbar/NavRight'
import Title from '../../Navbar/Title'
import { Listbox, Transition } from '@headlessui/react'
import { yupResolver } from '@hookform/resolvers/yup';
import { adminUserValidationSchema } from '../../../Utils/validation';
import { toastError, toastSuccess } from '../../../Utils/toast';
import Icon from '../../../Components/base/icon/icon';
import UpdateButton from '../../../Components/Buttons/UpdateButton';
import Delete from '../../../Components/Buttons/Delete';
import useFetchDataHooks from '../../../Hooks/FetchDataHooks';
import { useGetAllAdminProfileQuery } from '../../../Services/adminProfileApi';
import { AdminProfile } from '../../../Models/adminProfile.model';
import { AdminUser } from '../../../Models/adminUser.model';

function AdminView() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [selectedAdminProfile, setSelectedAdminProfile] = useState<AdminProfile>();

    const { register, handleSubmit, setValue, getValues, reset, formState: { errors } } = useForm<AdminUser>({ resolver: yupResolver(adminUserValidationSchema) });
    const [createAdminUser] = useCreateAdminUserMutation();
    const [updateAdminUser] = useUpdateAdminUserMutation();
    const [deleteAdminUser, { isSuccess: deleteAdminUserSuccess }] = useDeleteAdminUserMutation();
    const { data: adminUser } = useGetAdminUsersByIdQuery(id ? id : '', { skip: !id });
    const { pageData } = useFetchDataHooks(useGetAllAdminProfileQuery)
    const profiles = pageData?.data as AdminProfile[];

    const onSubmit = (values: AdminUser) => {
        if (adminUser?.id && id) {
            values.id = adminUser.id;
            values.adminProfile = selectedAdminProfile;

            updateAdminUser(values).then((res: any) => {
                if (res?.error) {
                    toastError(res.error.data.message)
                } else {
                    toastSuccess('Admin User Updated Successfully')
                    reset();
                    navigate('/s/adminUser')
                }
            })
        } else if (!id) {
            values.adminProfile = selectedAdminProfile;
            createAdminUser(values).then((res: any) => {
                if (res?.error) {
                    toastError(res.error.data.message)
                } else {
                    toastSuccess('Admin User Created Successfully')
                    reset()
                    navigate('/s/adminUser')
                }
            })
        }
    }

    useEffect(() => {
        if (adminUser && id) {
            reset(adminUser);
            setSelectedAdminProfile(adminUser.adminProfile);
        } else {
            reset();
        }
    }, [adminUser, id]);

    useEffect(() => {
        if (deleteAdminUserSuccess) {
            toastSuccess('Admin User Deleted Successfully')
            navigate('/s/adminUser');
        }
    }, [deleteAdminUserSuccess]);

    return (
        <div className='flex flex-col h-screen'>
            <div className='h-16 bg-gray-200 flex items-center w-full justify-between p-2'>
                {id && <Title title="Update Admin User" prePage='/s/adminUser' />}
                {!id && <Title title="Create Admin User" prePage='/s/adminUser' />}
                <NavRight />
            </div>
            <div className="flex flex-col  h-screen w-full px-5  bg-gray-200 overflow-hidden">
                <div className="bg-white h-full w-full p-10 mb-4 rounded-lg overflow-y-auto">
                    <form onSubmit={handleSubmit(onSubmit)} id="AdminForm">
                        <div className="grid grid-cols-12 gap-y-6">
                            <div className="flex flex-col col-span-2 font-semibold">
                                <span>Name</span>
                            </div>
                            <div className="flex flex-col col-span-10 w-1/3">
                                <Field
                                    {...register('name')} error={errors?.name?.message} type="text"
                                    placeholder={'Name'} className='p-2 h-9' defaultValue={adminUser?.name}
                                />
                            </div>
                            <div className="flex flex-col col-span-2 font-semibold">
                                <span>Email</span>
                            </div>
                            <div className="flex flex-col col-span-10 w-1/3"><Field
                                {...register('email')} error={errors?.email?.message} type="email"
                                placeholder={'Email'} className='p-2 h-9' defaultValue={adminUser?.email}
                            /></div>
                            {!id &&
                                <>
                                    <div className="flex flex-col col-span-2 font-semibold">
                                        <span>Password</span>
                                    </div>
                                    <div className="flex flex-col col-span-10 w-1/3">
                                        <div className="relative">
                                            <Field
                                                {...register('password')} error={errors?.password?.message} type={showPassword ? 'text' : 'password'}
                                                placeholder={'Password'} className='p-2 h-9'
                                            />
                                            {!errors?.password && <span className='absolute right-2 -mt-7 text-red-500' onClick={() => { setShowPassword(!showPassword) }}>{showPassword ? <Icon icon="EYE_SLASH" /> : <Icon icon="EYE" />}</span>}                                </div>
                                    </div>
                                </>
                            }
                            <div className="flex flex-col col-span-2 font-semibold">
                                <span>Profiles</span>
                            </div>
                            <div className="flex flex-col flex-wrap col-span-10 w-1/3">
                                <DisplayProfilesList />
                                {errors?.adminProfile?.message && <span className="text-red-500">{(errors?.adminProfile as any)?.message}</span>}
                            </div>
                            <div className="flex flex-col col-span-2 font-semibold">
                                <span>Country Code</span>
                            </div>
                            <div className="flex flex-col col-span-10 w-1/3">
                                <Field
                                    {...register('countryCode')} error={errors?.countryCode?.message} type="text"
                                    placeholder={'Country Code'} className='p-2 h-9' defaultValue={adminUser?.countryCode}
                                />
                            </div>
                            <div className="flex flex-col col-span-2 font-semibold">
                                <span>Phone Number</span>
                            </div>
                            <div className="flex flex-col col-span-10 w-1/3">
                                <Field
                                    {...register('phoneNo')} error={errors?.phoneNo?.message} type="tel"
                                    placeholder={'Phone Number'} className='p-2 h-9' defaultValue={adminUser?.phoneNo}
                                />
                            </div>
                        </div>
                        <div className='flex gap-3 mt-10'>
                            {adminUser?.id && id &&
                                <>
                                    <Delete mode="User"
                                        deleteFunction={deleteAdminUser}
                                        id={adminUser.id}
                                        title={adminUser.name}
                                        success={deleteAdminUserSuccess}
                                        afterSuccess="/s/adminUser" />
                                    <UpdateButton formName='AdminForm' title={adminUser.name} mode='User' value={'Update'} />
                                </>
                            }
                            {!id && <Button className="px-4 py-1.5 uppercase bg-BeeMG-yellow" size='sm' submit><span className="font-bold" >Save</span></Button>}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )

    function DisplayProfilesList() {

        return (
            <Listbox value={selectedAdminProfile} onChange={(profile) => {
                setSelectedAdminProfile(profile)
                setValue('adminProfile', profile)
            }}>
                <div className="relative mt-1">
                    <Listbox value={selectedAdminProfile} onChange={setSelectedAdminProfile}>
                        <div className="relative">
                            <Listbox.Button className="text-base relative flex flex-1 w-full rounded-lg py-1 px-4 bg-white text-gray-700 placeholder:text-sm placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-BeeMG-yellow focus:border-transparent border border-gray-300 h-auto">
                                <Listbox.Label className="block truncate  text-gray-700">
                                    {selectedAdminProfile ? selectedAdminProfile.profile_name : "--Select--"}
                                </Listbox.Label>
                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                    <Icon icon="RIGHT" size="x-small" className="p-0.5 rotate-90" />
                                </span>
                            </Listbox.Button>
                            <Transition
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <Listbox.Options className="absolute z-20 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                    {profiles?.map((adminProfile) => (
                                        <Listbox.Option
                                            key={adminProfile.id}
                                            className={({ active }) =>
                                                `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'}`
                                            }
                                            value={adminProfile}
                                        >
                                            {({ selected }) => (
                                                <>
                                                    <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                                                        {adminProfile?.profile_name}
                                                    </span>
                                                    {selected ? (
                                                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                                            <Icon icon="TICK" />
                                                        </span>
                                                    ) : null}
                                                </>
                                            )}
                                        </Listbox.Option>
                                    ))}
                                </Listbox.Options>
                            </Transition>
                        </div>
                    </Listbox>
                </div>
            </Listbox>
        )
    }
}

export default AdminView