import React from 'react'
import { Link } from 'react-router-dom';
import { Button } from '../../../Components';
import { Action, ActionType } from '../../../Components/Action';
import Icon from '../../../Components/base/icon/icon';
import { ComponentType } from '../../../Components/Buttons/Delete';
import useFetchDataHooks from '../../../Hooks/FetchDataHooks';
import { useGetAllAdminUsersQuery } from '../../../Services/adminUserApi';
import NavRight from '../../Navbar/NavRight'
import Title from '../../Navbar/Title'
import TableFormat, { SelectColumnFilter, TextColumnFilter } from '../../TableFormat';


function AdminList() {

    const { pageData, isLoading, fetchData, pagination, error } = useFetchDataHooks(useGetAllAdminUsersQuery);

    const defaultColumn = React.useMemo(() => ({
        width: 100,
    }), []);

    const columns = React.useMemo(() => [
        {
            Header: 'Name',
            accessor: 'name',
            width: 150,
            Filter: TextColumnFilter,
            filter: 'fuzzyText',
        },
        {
            Header: 'Email Address',
            accessor: 'email',
            width: 200,
            Filter: TextColumnFilter,
            filter: 'fuzzyText',
        },
        // {
        //     Header: 'Country Code',
        //     accessor: 'countryCode',
        //     width: 190,
        //     Filter: TextColumnFilter,
        //     filter: 'fuzzyText',
        // },
        {
            Header: 'Phone Number',
            accessor: 'phoneNo',
            width: 100,
            Filter: TextColumnFilter,
            filter: 'fuzzyText',
            Cell: ({ row: { original } }: any) => original?.countryCode + ' ' + original?.phoneNo,
        },
        {
            Header: 'Profile',
            accessor: 'adminProfile.access',
            width: 200,
            Filter: SelectColumnFilter,
            Cell: ({ row: { original } }: any) => original?.adminProfile?.access,
        },
        {
            Header: <div className='pb-8'>Action</div>,
            accessor: 'action',
            width: 80,
            Cell: ({ row: { original } }: any) => (<Action id={original.id} types={[ActionType.View]} navigation='s/adminUser/userView' title="view" componenetType={ComponentType.Button} />),
        }], []);


    return (
        <div className='flex flex-col h-screen'>
            <div className='h-16 bg-gray-200 flex items-center w-full justify-between p-2'>
                <Title title="Admin Users" prePage='/s/home' />
                <div className="w-8/12 flex justify-end mr-5">
                    <Link to={'/s/adminUser/userCreate'}>
                        <Button className="text-xs py-1 bg-BeeMG-yellow" size='sm' id="btn_signIn">
                            <div className='flex gap-x-1'>
                                <Icon icon='ADD' className='fill-black' /> <span className="font-bold flex items-center"> Create Admin</span>
                            </div>
                        </Button>
                    </Link>

                    <Link to={'/s/adminUser/adminProfile'}>
                        <Button className="text-xs py-1 bg-BeeMG-yellow" size='sm' id="btn_signIn">
                            <div className='flex gap-x-1'>
                                <Icon icon='ADD' className='fill-black' /> <span className="font-bold flex items-center"> Create Profile</span>
                            </div>
                        </Button>
                    </Link>
                </div>
                <NavRight />
            </div>
            <div className="items-center justify-center h-screen w-full font-semibold mb-4 rounded-lg bg-white overflow-auto overflow-x-hidden">
                {pageData && (
                    <TableFormat
                        columns={columns} data={pageData?.data} defaultColumn={defaultColumn} loading={isLoading} fetchData={fetchData}
                        page={pagination} error={error}
                    />
                )}
            </div>
        </div>
    )
}

export default AdminList