import { PagedData } from '../Models/pagedData.model';
import { Ledger } from "../Models/ledger.model";
import { allApis } from "./allApis";

export const transactionApi = allApis.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
        getAllTransactions: builder.query<PagedData<Ledger>, any>({
            query: page => {
                return {
                    url: "ledger/getAll",
                    params: page,
                };
            }
        }),
        getAllTransactionsByUser: builder.query<PagedData<Ledger>, any>({
            query: page => {
                return {
                    url: `ledger/admin/${page.id}`,
                    params: page,
                };
            }
        }),
        getAllTransactionsById: builder.query<Ledger, any>({
            query: id => {
                return {
                    url: `ledger/admin/transactionDetails/${id}`,
                };
            }
        }),
        getCancellationLedgerById: builder.query<Ledger, any>({
            query: id => {
                return {
                    url: `ledger/cancellation/${id}`,
                };
            }
        }),
        getRefundByMeetingId: builder.query<Ledger, any>({
            query: id => {
                return {
                    url: `ledger/getRefund/${id}`,
                };
            }
        }),
        getCheckPaymentById: builder.query<Ledger[], any>({
            query: (args) => {
                return {
                    url: `ledger/checkPayment/${args.meetingId}/${args.userId}`,
                };
            }
        }),
    })
});

export const { useGetAllTransactionsQuery, useGetAllTransactionsByUserQuery,useGetAllTransactionsByIdQuery } = transactionApi;
