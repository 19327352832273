import React, { useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { Action, ActionType } from '../../../Components/Action';
import Icon from '../../../Components/base/icon/icon';
import useFetchDataHooks from '../../../Hooks/FetchDataHooks';
import { useDeleteAdminProfileMutation, useGetAllAdminProfileQuery } from '../../../Services/adminProfileApi';
import NavRight from '../../Navbar/NavRight';
import Title from '../../Navbar/Title';
import TableFormat from '../../TableFormat';

function ViewAdminProfile() {
    const { pageData, isLoading, fetchData, pagination, error } = useFetchDataHooks(useGetAllAdminProfileQuery)
    const [deleteAdminProfile, { isSuccess: AdminProfileSuccess }] = useDeleteAdminProfileMutation();

    const defaultColumn = React.useMemo(() => ({
    }), []);

    const data = useMemo(() => {
        const displayData: any[] = [];

        pageData?.data?.forEach((event: any) => {
            const profileData = { ...event };
            if (event.access) {
                let accessibility = '';

                event.access.forEach((profile: any, index: any) => {
                    accessibility += profile;

                    if (index !== event.access.length - 1) {
                        accessibility += ', ';
                    }
                });

                profileData.accessDisplay = accessibility;
            } else {
                profileData.accessDisplay = '';
            }
            displayData.push({
                id: profileData.id,
                profile_name: profileData.profile_name,
                access: profileData.accessDisplay,
                // action: eventData.id,
            });
        });

        return displayData;
    }, [pageData]);

    const columns = useMemo(() => [{
        Header: <div className="p-2 hover:bg-gray-200 rounded-full"><Icon icon="FILTER" /></div>,
        accessor: 'filter',
        width: 35,
    }, {
        Header: 'ID',
        accessor: 'id',
        // width: 150,
    }, {
        Header: 'Name',
        accessor: 'profile_name',
        width: 100,
    }, {
        Header: 'Access',
        accessor: 'access',
        // width: 150,
    },
    {
        Header: 'Action',
        accessor: 'action',
        Cell: ({ row: { original } }: any) => <Action id={original.id} types={[ActionType.Edit, ActionType.Delete]} deleteFunc={deleteAdminProfile} success={AdminProfileSuccess} mode="Admin Profile" title={original.profile_name} navigation="s/adminUser/adminProfile" />,
        width: 50,
    }
    ], []);

    if (isLoading) return <div>Loading</div>
    if (!pageData) {
        return <div>No data Found...</div>
    }

    return (
        <div className='flex flex-col h-screen'>
            <div className='h-16 bg-gray-200 flex items-center w-full justify-between p-2'>
                <Title title="Admin Profile" prePage='/s/adminUser' />
                <NavRight />
            </div>
            <div className="grid grid-cols-12 flex-row lg:flex-col justify-center w-full mb-4 rounded-lg bg-white h-full lg:overflow-x-hidden">
                <div className="lg:col-span-7 col-span-12 border-r">
                    <TableFormat columns={columns} data={data} defaultColumn={defaultColumn} loading={isLoading} fetchData={fetchData}
                        page={pagination} error={error} />
                </div>
                <div className="lg:col-span-5 col-span-12 p-3"><Outlet /></div>
            </div>
        </div>
    )
}

export default ViewAdminProfile
