import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom'
import { Button } from '../../../Components';
import Field from '../../../Components/base/field/field';
import UpdateButton from '../../../Components/Buttons/UpdateButton';
import { useCreateAdminProfileMutation, useGetAdminProfileByIdQuery, useUpdateAdminProfileMutation } from '../../../Services/adminProfileApi';
import { toastError, toastSuccess } from '../../../Utils/toast';


function EditAdminProfile() {

    const { id } = useParams();
    const navigate = useNavigate();


    const [serverError, setServerError] = useState<string>('');
    const [accessList, setAccessList] = useState<string[]>([]);
    const [createAdminProfile] = useCreateAdminProfileMutation();
    const [updateAdminProfile] = useUpdateAdminProfileMutation();
    const roles = ['Super Admin', 'Administrator', 'Transaction Management', 'User Management', 'Customer Management', 'ZoomMeeting Management'];

    const { data: adminProfile, isSuccess: adminProfileSuccess } = useGetAdminProfileByIdQuery(id ? id : '', { skip: !id });
    const { register, handleSubmit, setValue, trigger, formState: { errors } } = useForm();

    useEffect(() => {
        if (adminProfile && id) {
            let accessList = adminProfile.access;
            // remove the white space in accessList string[]
            accessList = accessList.map((access: string) => access.trim());
            setAccessList(accessList);
            setValue("profile_name", adminProfile.profile_name);
            // setValue("access", accessList)
            trigger()
        } else {
            setValue("profile_name", "");
            setValue("access", [])
        }
    }, [adminProfile, id]);

    const handleAddAccessList = (access: any) => {
        const found = accessList?.find((role: any) => role === access);
        if (!found) {
            setAccessList([...accessList, access])
        } else {
            setAccessList(accessList.filter((role: any) => role !== access))
        }
    }

    const onSubmit = (values: any) => {
        values.id = id;
        values.access = accessList;
        if (id) {
            updateAdminProfile(values).then((res: any) => {
                if (res?.error) {
                    toastError(res.error.data.message);
                    setServerError(res?.error.message);
                } else {
                    toastSuccess('Admin Profile Updated Successfully');
                }
            })
        } else {
            createAdminProfile(values).then((res: any) => {
                if (res?.error) {
                    toastError(res.error.data.message)
                    setServerError(res.error.data.message);
                } else {
                    toastSuccess("Admin profile created!")
                }
            })

        }
    }

    const CheckBox = ({ checked, role }: any) => {
        return <>
            <div className="flex flex-row lg:flex-row gap-4 mt-5">
                <input type="checkbox" className='w-4 h-4' id='userView' defaultChecked={checked}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleAddAccessList(role.replace(/\s/g, ''))} />
                <label htmlFor="rememberMe" className='text-xs capitalize text-gray-500 font-semibold'>
                    {role}
                </label>
            </div>
        </>
    }

    return (
        <div className="flex flex-col items-center">
            <div className="font-bold">
                <span>{id ? 'Edit' : 'Create'} Admin Profile</span>
            </div>
            <div className='w-80'>
                <form onSubmit={handleSubmit(onSubmit)} id="NickNameForm">
                    <div className="flex flex-col gap-y-3">
                        <div className="pt-5 text-sm font-semibold">
                            <label htmlFor="input_ID" >Profile Name</label>
                            <Field
                                {...register('profile_name')}
                                dot={false}
                                error={errors?.id?.message}
                                type="profile_name"
                                name="profile_name"
                                placeholder={'Profile Name *'} id="input_ID" className='p-1.5'
                                contentEditable={adminProfile?.id && id ? true : false}
                            />
                        </div>
                        <div>

                            <div className="flex flex-col lg:flex-col mx-auto w-52">
                                {roles.map((role: any, index: any) => (
                                    <CheckBox checked={accessList?.includes(role.replace(/\s/g, ''))} role={role} key={index} />
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col lg:flex-row gap-4 mt-5 justify-center">
                        {!id &&
                            <Button className="bg-amber-400 hover:bg-yellow-600 rounded px-4 py-1 text-sm font-semibold" submit>
                                {!id && "Create"}
                            </Button>
                        }

                        {adminProfile?.id && id && (
                            <UpdateButton formName='NickNameForm' title={adminProfile.profile_name} mode='adminProfile' value={adminProfile?.id && id ? 'Update' : 'Create'} />
                        )}

                        {adminProfile?.id && id && (
                            <Button className="bg-amber-400 hover:bg-yellow-600 rounded px-4 py-1 text-sm font-semibold" onClick={() => navigate("/s/adminUser/adminProfile")}>
                                CANCEL
                            </Button>)}

                    </div>
                </form>
            </div>
        </div>
    )
}

export default EditAdminProfile
