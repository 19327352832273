import { PagedData } from './../Models/pagedData.model';
import { allApis } from "./allApis";
import { AdminUser } from '../Models/adminUser.model';

export const adminUserApi = allApis.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({

        getAllAdminUsers: builder.query<PagedData<AdminUser>, any>({
            query: page => {
                return {
                    url: "adminUsers",
                    params: page
                };
            },
            providesTags: ['deleteAdminUserTag','updateAdminUserTag','AdminUserTag']
        }),
        getAdminUsersById: builder.query<AdminUser, any>({
            query: id => {
                return {
                    url: `adminUsers/${id}`,
                };
            },
            providesTags: ['updateAdminUserTag','deleteAdminUserTag','AdminUserTag'],
        }),
        deleteAdminUser: builder.mutation<boolean, any>({
            query: (args) => {
                const {id} =args
                return {
                    url: `adminUsers/${id}`,
                    method: 'DELETE'
                };
            },
            invalidatesTags: ['deleteAdminUserTag'],
        }),
        createAdminUser: builder.mutation<AdminUser, any>({
            query: (adminUser) => {
                return {
                    url: `adminUsers`,
                    method: 'POST',
                    body: adminUser
                };
            },
            invalidatesTags: ['AdminUserTag'],
        }),
        updateAdminUser: builder.mutation<AdminUser, any>({
            query: (adminUser) => {
                return {
                    url: `adminUsers/${adminUser.id}`,
                    method: 'PUT',
                    body: adminUser
                };
            },
            invalidatesTags: ['updateAdminUserTag'],
        }),

    })
});

export const { useGetAllAdminUsersQuery, useGetAdminUsersByIdQuery, useDeleteAdminUserMutation, useCreateAdminUserMutation, useUpdateAdminUserMutation } = adminUserApi;
