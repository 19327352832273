import { allApis } from "./allApis";
import { PagedData } from "./../Models/pagedData.model";
import { Setting } from "./../Models/setting.model";


export const settingApi = allApis.injectEndpoints({
    overrideExisting: false,

    endpoints: (builder) => ({
        getAllSettings: builder.query<any, any>({
            query: (key) => `setting/all/key`
        }),
        getAllSettingsList: builder.query<PagedData<Setting>, any>({
            query: page => {
                return {
                    url: "setting/settingList",
                    params: page,

                };
            },
            providesTags: ['Settings'],
        }),
        getSettingById: builder.query<Setting, any>({
            query: id => {
                return {
                    url: `setting/${id}`,
                };
            },
            providesTags: ['Settings'],
        }),
        updateSettings: builder.mutation<Setting, any>({
            query: (setting) => {
                return {
                    url: `setting/${setting.id}`,
                    method: 'PUT',
                    body: setting
                };
            },
            invalidatesTags: ['Settings'],
        }),
    })
});

export const { useGetAllSettingsQuery, useGetAllSettingsListQuery, useGetSettingByIdQuery, useUpdateSettingsMutation } = settingApi;