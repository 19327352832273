import { useState, useCallback, useEffect } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Pagination } from "../Models/pagination.model";

function useFetchDataHooks<T>(useQuery: any, filter?: T) {
  const [searchParams, setSearchParams] = useSearchParams();
  let pageIndex = searchParams.get('pageIndex');
  let pageSize  = searchParams.get('pageSize');

  const navigate = useNavigate();
  const location = useLocation();

    const [pagination, setPagination] = useState<Pagination>({
        pageIndex: pageIndex ? parseInt(pageIndex): 0,
        pageSize: pageSize ? parseInt(pageSize): 15,
        totalElements: 0,
        totalPages: 0
    });
   
    const { data: pageData, isLoading, error } = useQuery({...filter, pageSize: pageSize ?? 15, pageIndex: pageIndex ?? 0 });

    useEffect(() => {
        if (pageData?.page) {
            const totalPages = Math.ceil(pageData?.page?.totalElements / pageData?.page?.pageSize);

            setTimeout(() => {
                setPagination({ ...pagination, totalPages, totalElements: pageData?.page?.totalElements });
            }, 50);
        }
    }, [pageData?.data]);
    
    const fetchData = useCallback(({ pageSize, pageIndex }: Pagination) => {  
        navigate({
            pathname: location.pathname,
            search: `?pageSize=${pageSize}&pageIndex=${pageIndex}`
        })
    }, [])



    return { pageData, isLoading, fetchData, pagination, error}
}

export default useFetchDataHooks;