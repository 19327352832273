import { AdminProfile } from './../Models/adminProfile.model';
import { PagedData } from "../Models/pagedData.model";
import { allApis } from "./allApis";

export const adminProfileApi = allApis.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
        getAllAdminProfile: builder.query<PagedData<AdminProfile>, any>({
            query: page => {
                return {
                    url: `adminProfile/`,
                    params: page
                };
            },
            providesTags: ['adminProfileList']
        }),
        getAdminProfileById: builder.query<AdminProfile, any>({
            query: id => {
                return {
                    url: `adminProfile/${id}`,
                };
            },
            providesTags: ['updateAdminUserTag', 'deleteAdminUserTag', 'AdminUserTag'],
        }),
        createAdminProfile: builder.mutation<AdminProfile, any>({
            query: (adminProfile) => {
                return {
                    url: `adminProfile`,
                    method: 'POST',
                    body: adminProfile
                };
            },
            invalidatesTags: ['adminProfileList'],

        }),
        updateAdminProfile: builder.mutation<AdminProfile, any>({
            query: (adminProfile) => {
                return {
                    url: `adminProfile/${adminProfile.id}`,
                    method: 'PUT',
                    body: adminProfile
                };
            },
            invalidatesTags: ['adminProfileList'],
        }),
        deleteAdminProfile: builder.mutation<boolean, any>({
            query: (args) => {
                const { id } = args;
                return {
                    url: `adminProfile/${id}`,
                    method: 'DELETE'
                };
            },
            invalidatesTags: ['adminProfileList'],
        }),
    })
});

export const { useGetAllAdminProfileQuery, useCreateAdminProfileMutation, useDeleteAdminProfileMutation, useGetAdminProfileByIdQuery, useUpdateAdminProfileMutation } = adminProfileApi;