import { MeetingDetailsHistory } from './../Models/meetingDetailsHistory.model';
import { PagedData } from './../Models/pagedData.model';
import { MeetingDetails } from "../Models/meetingDetails.model";
import { allApis } from "./allApis";

export const meetingDetailsApi = allApis.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
        getAllEvents: builder.query<PagedData<MeetingDetails>, any>({
            query: page => {
                return {
                    url: "meetingDetails/all/events",
                    params: page,
                };
            }
        }), 
        getMeetingDetailsById: builder.query<MeetingDetails, any>({
            query: id => {
                return {
                    url: `meetingDetails/getMeetingDetails/${id}  `,
                    // params: page,
                };
            }
        }),
        getMeetingsByEvents: builder.query<PagedData<MeetingDetails[]>, any>({
            query: (page) => {
                console.log("idmm",page)
                return {
                    url: `meeting/meetingsofEvent/${page.id}`,
                    params: page,
                };
            }
        }),
        getMeetingDetailsHistoryById: builder.query<PagedData<MeetingDetailsHistory>, any>({
            query: page=> {
                return {
                    url: `/meetingDetailsHistory/viewHistory/${page.id}`,
                    params: page,
                };
            }
        }),
        getMeetingDetailsByUserId: builder.query<PagedData<MeetingDetails>, any>({
            query: page=> {
                return {
                    url: `/meetingDetails/all/userEvents/${page.id}`,
                    params: page,
                };
            }
        }),
    })
});

export const { useGetAllEventsQuery, useGetMeetingDetailsByIdQuery, useGetMeetingsByEventsQuery, useGetMeetingDetailsHistoryByIdQuery, useGetMeetingDetailsByUserIdQuery } = meetingDetailsApi;
