import Icon from '../../Components/base/icon/icon'
import { useTitle } from '../useTitle';
import { useNavigate } from "react-router-dom";

function Title({ title, icon = 'LEFT', prePage, justBack }: { title: string, icon?: string, prePage?: string, justBack?: boolean }) {

    let navigate = useNavigate();
    useTitle(title);

    return (
        <>
            <div className='pl-1 flex items-center'>
                {justBack && 
                 <>
                    <div className={`${prePage && `cursor-pointer`}`} onClick={() => {  navigate(-1) } }><div className="p-2 hover:bg-slate-100 rounded-full"><Icon icon={icon} size={icon !== 'LEFT' ? 'medium' : 'x-small'} className='fill-black ' /></div></div>
                 </>
                }
                {!justBack && 
                  <>
                    <div className={`${prePage && `cursor-pointer`}`} onClick={() => { if (prePage && icon === "LEFT") { navigate(prePage) } }}><div className="p-2 hover:bg-slate-100 rounded-full"><Icon icon={icon} size={icon !== 'LEFT' ? 'medium' : 'x-small'} className='fill-black ' /></div></div>
                  </>
                }

                <div className="ml-2 text-sm md:text-md lg:text-xl font-bold text-black cursor-default w-fit whitespace-nowrap">{title}</div>
            </div>
        </>
    )
}

export default Title