import { CommonEntity } from "./commonEntity.model";
import { User } from "./user.model";

export enum Name {
    Default = 'Default',
    Normal = 'Normal',
    High = 'High',
    Elite = 'Elite',
    Specific = 'Specific',
}

export interface Group extends CommonEntity {
    user: User;
    name: Name;
    commissionPercentage: number;
}