import { CommonEntity } from './commonEntity.model';
import { Ledger } from './ledger.model';
import { User } from './user.model';
import { Meeting } from './meeting.model';
import { MeetingParticipant } from './meetingParticipant.model';

export enum Processed {
  Yes = 'Yes',
  No = 'No',
}

export interface CancellationRequest extends CommonEntity {
  ledger: Ledger;

  refundLedger: Ledger;

  user: User;

  meeting: Meeting;

  processed: Processed;

  requestDateTime: Date;

  meetingParticipant: MeetingParticipant;
}
