import { PagedData } from '../Models/pagedData.model';
import { Report } from "../Models/report.model";
import { allApis } from "./allApis";

export const reportApi = allApis.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
        getAllReports: builder.query<PagedData<Report>, any>({
            query: page => {
                return {
                    url: "report",
                    params: page,
                };
            }
        }),
        getAllReportedUserById: builder.query<Report, any>({
            query: id => {
                return {
                    url: `report/viewReport/${id}`,
                };
            },
            providesTags: ['ReportProfile', 'FakeProfile', 'VerifyProfile']
        }),
        updateFakeReport: builder.mutation<Report, any>({
            query: report => {
                return {
                    url: `report/report/${report.id}`,
                    body: report,
                    method: 'PUT',
                };
            },
            invalidatesTags: ['ReportProfile'],
        }),
        updateFakeProfile: builder.mutation<Report, any>({
            query: report => {
                return {
                    url: `report/profile/${report.id}`,
                    body: report,
                    method: 'PUT',
                };
            },
            invalidatesTags: ['FakeProfile'],
        }),
        updateVerifyProfile: builder.mutation<Report, any>({
            query: report => {
                return {
                    url: `report/verify/${report.id}`,
                    body: report,
                    method: 'PUT',
                };
            },
            invalidatesTags: ['VerifyProfile'],
        }),
    })
});

export const { useGetAllReportsQuery, useGetAllReportedUserByIdQuery, useUpdateFakeProfileMutation, useUpdateFakeReportMutation, useUpdateVerifyProfileMutation } = reportApi;
