import React from "react";
import { Action, ActionType } from "../../Components/Action";
import { ComponentType } from "../../Components/Buttons/Delete";
import useFetchDataHooks from "../../Hooks/FetchDataHooks";
import { useGetAllEmailTemplateQuery } from "../../Services/emailTemplateApi";
import NavRight from "../Navbar/NavRight";
import Title from "../Navbar/Title";
import TableFormat, { SelectColumnFilter, TextColumnFilter } from "../TableFormat";

function Email() {

  const { pageData, isLoading, fetchData, pagination, error } = useFetchDataHooks(useGetAllEmailTemplateQuery);

  const defaultColumn = React.useMemo(() => ({
    width: 100,
  }), []);

  const columns = React.useMemo(() => [
  {
    Header: 'Email Template Id',
    accessor: 'emailTemplateId',
    width: 100,
    Filter: TextColumnFilter,
    filter: 'fuzzyText',
  },
  {
    Header: 'Template Name',
    accessor: 'templateName',
    width: 80,
    Filter: TextColumnFilter,
    filter: 'fuzzyText',

  },
  {
    Header: 'Subject',
    accessor: 'subject',
    width: 80,
    Filter: TextColumnFilter,
    filter: 'fuzzyText',
  },
  {
    Header: 'Language',
    accessor: 'language',
    width: 100,
    Filter: SelectColumnFilter,
  },
  {
    Header: 'View Template',
    accessor: 'viewTemplate',
    width: 80,
    Cell: ({ row: { original } }: any) => (<Action id={original.id} types={[ActionType.View]} navigation="s/email/view" title="view" componenetType={ComponentType.Button} />),
  }], []);

  return (
    <div className='flex flex-col h-screen'>
      <div className='h-16 bg-gray-200 flex items-center w-full justify-between p-2'>
        <Title title="Email Templates" prePage='/s/home' />
        <NavRight />
      </div>
      <div className="items-center justify-center h-screen w-full font-semibold mb-4 rounded-lg bg-white overflow-auto overflow-x-hidden">
        {pageData && (
          <TableFormat
            columns={columns} data={pageData?.data} defaultColumn={defaultColumn} loading={isLoading} fetchData={fetchData}
            page={pagination} error={error}
          />
        )}
      </div>
    </div>
  )
}

export default Email