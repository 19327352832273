import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { Button } from "../../../Components";
import { Action, ActionType } from "../../../Components/Action";
import Icon from "../../../Components/base/icon/icon";
import useFetchDataHooks from "../../../Hooks/FetchDataHooks";
import { useGetAllTermAndPoliciesQuery } from "../../../Services/policiesApi";
import { formatDateToTimezone2 } from "../../../Utils/datetime";
import NavRight from "../../Navbar/NavRight";
import Title from "../../Navbar/Title";
import TableFormat, { TextColumnFilter } from "../../TableFormat";

function TermsAndPolicies() {
  const { pageData, isLoading, fetchData, pagination, error } = useFetchDataHooks(useGetAllTermAndPoliciesQuery);
  const policies = useMemo(() => {
    const arr: any = [];
    pageData?.data?.map((item: any) => {
      const check = arr.find((data: any) => data.policyId === item.policyId);
      if (!check) {
        arr.push(item);
      }
    });
    return arr;
  }, [pageData]);

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const data = useMemo(() => {
    const displayData: any[] = [];

    pageData?.data?.forEach((policy: any) => {
      const policyData = { ...policy };

      displayData.push({
        policyId: policyData.policyId,
        updatedAt: formatDateToTimezone2(timezone, policyData.updatedAt),
        policyName: policyData.policyName,
        description: <div className='truncate h-2.6 flex items-center mr-5' dangerouslySetInnerHTML={{ __html: policyData ? policyData?.description : '', }}></div>,
        policyVersion: policyData.policyVersion,
        editPolicy: JSON.stringify(policies).includes(JSON.stringify(policyData)) ? policyData.id : '',
      });
    });

    return displayData;
  }, [pageData]);

  const defaultColumn = React.useMemo(() => ({
    width: 100,
  }), []);

  const columns = React.useMemo(() => [
  {
    Header: 'PolicyId',
    accessor: 'policyId',
    width: 80,
    Filter: TextColumnFilter,
    filter: 'fuzzyText',
  },
  {
    Header: 'Updated At',
    accessor: 'updatedAt',
    width: 100,
    Filter: TextColumnFilter,
    filter: 'fuzzyText',
  },
  {
    Header: <div className='pb-8'> Policy Name </div>,
    accessor: 'policyName',
    width: 100,
  },
  {
    Header: <div className='pb-8'> Description </div>,
    accessor: 'description',
    width: 250,

  },
  {
    Header: <div className='pb-8'> Policy Version </div>,
    accessor: 'policyVersion',
    width: 100,

  },
  {
    Header: <div className='pb-8'> Edit Policy </div>,
    accessor: 'editPolicy',
    width: 80,
    Cell: ({ row: { original } }: any) => (<Action id={original.editPolicy} types={[ActionType.Edit]} />),
  }], []);

  return (
    <div className='flex flex-col h-screen'>
      <div className='h-16 bg-gray-200 flex items-center w-full justify-between p-2'>
        <Title title="Terms And Policies" prePage='/s/home' />
        <div className="w-full flex justify-end mr-5">
          <Link to={'createTermsAndPolicy'} ><Button className="text-xs py-1 bg-BeeMG-yellow" size='sm' id="btn_signIn"><div className='flex gap-x-1'><Icon icon='ADD' className='fill-black' /><span className="font-bold flex items-center"> Create Terms &amp; Policy</span></div></Button></Link>
        </div>
        <NavRight />
      </div>
      <div className="items-center justify-center h-screen w-full font-semibold mb-4 rounded-lg bg-white overflow-auto overflow-x-hidden">
        {pageData && (
          <TableFormat
            columns={columns} data={data} defaultColumn={defaultColumn} loading={isLoading} fetchData={fetchData}
            page={pagination} error={error}
          />
        )}
      </div>
    </div>
  )
}

export default TermsAndPolicies