import { allApis } from "./allApis";
import { Group } from '../Models/group.model';

export const groupApi = allApis.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
        updateCommissionPercentage: builder.mutation<Group, any>({
            query: (group:Group) => {
                return {
                    url: `/group/${group.id}`,
                    method: 'PUT',
                    body: group
                };
            },
            invalidatesTags: ['AdminUserByIDTag'],
        }),

    })
});

export const { useUpdateCommissionPercentageMutation } = groupApi;
