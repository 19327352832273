import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import Field from '../../Components/base/field/field'
import UpdateButton from '../../Components/Buttons/UpdateButton'
import { useGetSettingByIdQuery, useUpdateSettingsMutation } from '../../Services/settingApi'
import { toastSuccess } from '../../Utils/toast'
import { settingValidationSchema } from '../../Utils/validation'

interface ISetting {
    key: string;
    value: string;
}

function EditSetting() {

    const navigate = useNavigate();
    const { id } = useParams();

    const { data: settingData } = useGetSettingByIdQuery(id ? id : '', { skip: !id })
    const { register, handleSubmit, setValue, getValues, trigger, formState: { errors } } = useForm<ISetting>({ resolver: yupResolver(settingValidationSchema) });
    const [updateSetting, { isSuccess: updateSettingSuccess }] = useUpdateSettingsMutation();

    const onSubmit = (values: any) => {
        values.id = id;
        updateSetting(values).then((value: any) => {
            console.log("Values", value);
        });
    }

    useEffect(() => {
        if (settingData) {
            setValue('key', settingData?.key);
            setValue('value', settingData?.value);
            trigger()
        }
    }, [settingData]);

    useEffect(() => {
        if (updateSettingSuccess) {
            toastSuccess('Setting updated successfully');
            navigate('/s/settings');
        }
    }, [updateSettingSuccess]);

    return (
        <div className='w-full flex flex-col items-center'>
            <div className="font-bold">Update Setting</div>
            <div className='w-8/12'>
                <form onSubmit={handleSubmit(onSubmit)} id="settingForm">
                    <div className="flex flex-col gap-y-3">
                        <div>
                            <label htmlFor="settingKey" className='text-xs uppercase font-semibold'>Key</label>
                            <Field
                                {...register('key')} error={errors?.key?.message} type="text"
                                placeholder={'Key'} id="settingKey" className='p-2 h-9'
                            />
                        </div>
                        <div>
                            <label htmlFor="settingValue" className='text-xs uppercase font-semibold'>Value</label>
                            <Field
                                {...register('value')} error={errors?.value?.message} type="text"
                                placeholder={`Value`} id="settingValue" className='p-2 h-9'
                            />
                        </div>
                    </div>
                    <div className='flex flex-col lg:flex-row gap-4 mt-5 justify-center'>
                        <UpdateButton formName='settingForm' title={getValues('key')} mode='Settings' value={'Update'} />
                    </div>
                </form>
            </div>
        </div>
    )
}

export default EditSetting