import { useForm } from 'react-hook-form';
import Field from '../Components/base/field/field'
import { yupResolver } from '@hookform/resolvers/yup'
import { loginValidationSchema } from '../Utils/validation'
import { Button } from '../Components'
import { useState } from 'react'
import { AdminUser } from '../Models/adminUser.model'
import { useLoginMutation } from '../Services/loginApi'
import { useDispatch } from 'react-redux'
import { changeToken } from '../Services/tokenReducer';
import { useNavigate } from 'react-router-dom';
import logo from '../Assets/img/admin_logo.svg'
import { changeUser } from '../Services/adminUserReducer';
interface ISignIn {
    email: string;
    password: string;
}

function SignIn() {
    const { register, handleSubmit, formState: { errors } } = useForm<ISignIn>({ resolver: yupResolver(loginValidationSchema) });
    const [login] = useLoginMutation<any>();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onSubmit = (values: any) => {
        if (serverError) {
            setServerError('');
        }

        const model = {} as AdminUser;
        model.email = values.email;
        model.password = values.password;

        login(model).then((response: any) => {
            if (response.data) {
                dispatch(changeToken(response.data.token))
                dispatch(changeUser(response.data.adminUser))
                navigate('s/home');
            } else {
                setServerError('Incorrect email or password');
            }
        });
    }

    const [serverError, setServerError] = useState('');

    return (
        <div>
            <div className="grid grid-cols-12 h-screen bg-BeeMG-gray-primary">
                <div className="lg:col-span-1 hidden lg:block"></div>
                <div className='col-span-12  md:col-span-6 lg:col-span-3 px-3 lg:px-0 flex flex-col gap-y-5 items-center justify-center'>
                    <img src={logo} alt="BeeMg Admin" className='w-full h-24 px-5' />
                    <div className="w-full py-16 bg-white rounded-lg p-9">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            {serverError && (
                                <div
                                    id="error_loginMessage"
                                    className="bg-orange-100 text-yellow-900 rounded-lg w-full py-2 text-center -mt-8"
                                >
                                    {serverError}
                                </div>
                            )}
                            <div className="flex flex-col gap-y-3">
                                <div>
                                    <label htmlFor="input_email" className='text-xs uppercase text-gray-500 font-semibold'>
                                        Email Address
                                    </label>
                                    <Field
                                        {...register('email')} error={errors?.email?.message} type="email"
                                        placeholder={'Email *'} id="input_email" className='p-2 h-9'
                                    />
                                </div>
                                <div>
                                    <label htmlFor="input_password" className='text-xs uppercase text-gray-500 font-semibold'>
                                        Password
                                    </label>
                                    <Field
                                        {...register('password')} dot={false} error={errors?.password?.message} type="password"
                                        placeholder={'Password *'} id="input_password" className='p-2 h-9'
                                    />
                                </div>
                                <div className='flex gap-1 items-center'>
                                    <input type="checkbox" value="lsRememberMe" id="rememberMe" />
                                    <label htmlFor="rememberMe" className='text-xs capitalize text-gray-500 font-semibold'>
                                        Remember me
                                    </label>
                                </div>
                            </div>
                            <div>
                                <Button className="w-full mt-5 py-2 shadow-md bg-BeeMG-gray-primary" color="save" id="btn_signIn" submit>
                                    <div>Log In</div>
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="lg::col-span-1 hidden lg:block"></div>
                <div className='SignIn col-span-7 md:col-span-6 lg:col-span-7 my-5 hidden md:block'></div>
            </div>
        </div>
    )
}

export default SignIn
