import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import useFetchDataHooks from "../../Hooks/FetchDataHooks";
import { useGetMeetingHistoryByIdQuery } from "../../Services/meetingApi";
import { getUTFormattedDate, getUTFormattedTime } from "../../Utils/datetime";
import NavRight from "../Navbar/NavRight";
import Title from "../Navbar/Title";
import TableFormat from "../TableFormat";

function ViewMeetingHistory() {

  const { id } = useParams();
  const { pageData, isLoading, fetchData, pagination, error } = useFetchDataHooks<any>(useGetMeetingHistoryByIdQuery, { id: id ? id : '' } as any);
  const defaultColumn = React.useMemo(() => ({
    width: 150,
  }), []);

  const data = useMemo(() => {

    const displayData: any[] = [];
    pageData?.data?.forEach((history: any) => {
      const tempData = { ...history };
      displayData.push({
        col1: tempData?.meeting?.meetingDetails?.title,
        col2: tempData?.startAt,
        col3: tempData?.endAt,
        col4: tempData?.duration,
        col5: tempData?.seatsAvailable,
        col6: tempData?.cost,
        col7: tempData?.currency,
        col8: tempData?.paidToHost,
        col9: tempData?.updatedAt ? getUTFormattedDate(tempData?.updatedAt?.toString()) : '',
        col10: tempData?.updatedAt ? getUTFormattedTime(tempData?.updatedAt?.toString()) : '',
      });
    });
    return displayData;
  }, [pageData]);

  const columns = React.useMemo(() => [  {
    Header: 'Title',
    accessor: 'col1',
    width: 150,
  }, {
    Header: 'Start At',
    accessor: 'col2',
    width: 100,
  }, {
    Header: 'End At',
    accessor: 'col3',
    width: 100,
  }, {
    Header: 'Duration',
    accessor: 'col4',
    width: 100,
  }, {
    Header: 'Seats Available',
    accessor: 'col5',
    width: 150,
  }, {
    Header: 'Cost',
    accessor: 'col6',
    width: 100,
  },
  {
    Header: 'Currency',
    accessor: 'col7',
    width: 100,
  }, {
    Header: 'paidToHost',
    accessor: 'col8',
    width: 100,
  }, {
    Header: 'Updated Date',
    accessor: 'col9',
    width: 100,
  }, {
    Header: 'Updated Time',
    accessor: 'col10',
    width: 100,
  },
  
  ], []);

  if (isLoading) {
    return <> "Loading..."</>
  }

  return (
    <div className='flex flex-col h-screen'>
      <div className='h-16 bg-gray-200 flex  w-full justify-between p-2'>
        <Title title="Meeting History" prePage='/s/meetings' />
        <NavRight />
      </div>
      <div className="items-center justify-center h-screen w-full font-semibold mb-4 rounded-lg bg-white overflow-auto overflow-x-hidden">
        {((pageData?.data?.length === 0) || pageData === undefined) && <div className="h-full flex justify-center items-center text-lg">No Data to Display</div>}
        {pageData?.data?.length > 0 && <TableFormat columns={columns} data={data} defaultColumn={defaultColumn} fetchData={fetchData} loading={isLoading} page={pagination} error={error} />}
      </div>
    </div>
  )
}
export default ViewMeetingHistory;