import React from 'react'
import { Action, ActionType } from '../../Components/Action';
import { ComponentType } from '../../Components/Buttons/Delete';
import useFetchDataHooks from '../../Hooks/FetchDataHooks';
import { useGetAllCancellationRequestQuery } from '../../Services/cancellationRequestApi';
import { formatTimeFullToTimezone, getUTFormattedDate } from '../../Utils/datetime';
import NavRight from '../Navbar/NavRight'
import Title from '../Navbar/Title'
import TableFormat, { TextColumnFilter } from '../TableFormat';

function CancellationRequest() {

  const { pageData, isLoading, fetchData, pagination, error } = useFetchDataHooks(useGetAllCancellationRequestQuery);
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const defaultColumn = React.useMemo(() => ({
    width: 100,
  }), []);

  const columns = React.useMemo(() => [
  {
    Header: 'Requested By',
    accessor: 'requestedBy',
    width: 120,
    Filter: TextColumnFilter,
    filter: 'fuzzyText',
    Cell: ({ row: { original } }: any) => original.user.firstName + ' ' + original.user.lastName,
  },
  {
    Header: 'Event Title',
    accessor: 'title',
    width: 180,
    Filter: TextColumnFilter,
    filter: 'fuzzyText',
   Cell: ({ row: { original } }: any) => {
    return original?.meeting?.meetingDetails?.title ?? "Title not available";
  }  
  },
  {
    Header: <div className='pb-8'> Host Name</div> ,
    accessor: 'hostName',
    width: 120,
    Cell: ({ row: { original } }: any) => {
      const firstName = original?.meeting?.meetingDetails?.host?.firstName;
      const lastName = original?.meeting?.meetingDetails?.host?.lastName;
      
      return <span>{firstName && lastName ? `${firstName} ${lastName}` : 'Host not available'}</span>;
    }
  },
  {
    Header: <div className='pb-8'> Scheduled On </div>,
    accessor: 'scheduledOn',
    width: 70,
    Cell: ({ row: { original } }: any) => {
      const formattedDate = original?.meeting?.fromDateTime
        ? getUTFormattedDate(original.meeting.fromDateTime)
        : "Date not available";
      return formattedDate;
    }    
  },
  {
    Header:<div className='pb-8'> Requested Date </div>,
    accessor: 'requestedDate',
    width: 70,
    Cell: ({ row: { original } }: any) => getUTFormattedDate(original.requestDateTime),
  },
  {
    Header:<div className='pb-8'> Requested Time </div>,
    accessor: 'requestedTime',
    width: 100,
    Cell: ({ row: { original } }: any) => formatTimeFullToTimezone(timezone, original.requestDateTime),
  },
 
  {
    Header: <div className='pb-8 pl-3'> Action </div>,
    accessor: 'action',
    Cell: ({ row: { original } }: any) => (<Action id={original.id} types={[ActionType.View]} title="view" componenetType={ComponentType.Button} navigation="s/cancellationRequest/edit"/>),
    width: 70,
  }], []);

  return (
    <div className='flex flex-col h-screen'>
      <div className='h-16 bg-gray-200 flex items-center w-full justify-between p-2'>
        <Title icon="CANCEL" title="Cancellation Request" />
        <NavRight />
      </div>
      <div className="items-center justify-center h-screen w-full font-semibold mb-4 rounded-lg bg-white overflow-auto overflow-x-hidden">
          <TableFormat
            columns={columns} data={pageData?.data ?? []} defaultColumn={defaultColumn} loading={isLoading} fetchData={fetchData}
            error={error}
            page={pagination}
          />
      </div>
    </div>
  )
}

export default CancellationRequest