import { Interest } from "../Models/interests.model";
import { PagedData } from "../Models/pagedData.model";
import { allApis } from "./allApis";

export const interestsApi = allApis.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getAllInterests: builder.query<PagedData<Interest>, any>({
      query: (page) => {
        return {
          url: `category/categoryList/`,
          params: page,
        };
      },
      providesTags: ["interestsList", "deleteInterests"],
    }),
    getInterestsById: builder.query<Interest, any>({
      query: (id) => {
        return {
          url: `category/get/${id}`,
        };
      },
      providesTags: ["interestsByID", "deleteInterests"],
    }),

    createInterests: builder.mutation<Interest, any>({
      query: (interests) => {
        return {
          url: `category`,
          method: "POST",
          body: interests,
        };
      },
      invalidatesTags: ["interestsList", "deleteInterests"],
    }),
    updateInterests: builder.mutation<Interest, any>({
      query: (interests) => {
        return {
          url: `category/${interests.id}`,
          method: "PUT",
          body: interests,
        };
      },
      invalidatesTags: ["interestsList", "interestsByID"],
    }),
    cancelInterests: builder.mutation<Interest, any>({
      query: (interests) => {
        return {
          url: `category`,
          method: "DELETE",
          body: interests,
        };
      },
      invalidatesTags: ["interests"],
    }),
  deleteInterests: builder.mutation<boolean, any>({  
    query: (args) => {
      console.log("----Del Int ", args)
      const { id, notifyEmail } = args
        return {
            url: `category/${id}/${notifyEmail}`,
            method: 'DELETE'
        };
    },
    invalidatesTags: ["deleteInterests"], 
}),
  }),
});

export const {
  useGetAllInterestsQuery,
  useCreateInterestsMutation,
  useGetInterestsByIdQuery,
  useUpdateInterestsMutation,
  useCancelInterestsMutation,
  useDeleteInterestsMutation,
} = interestsApi;
