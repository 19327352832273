import { useMemo } from "react";
import { Outlet } from "react-router-dom";
import { Action, ActionType } from "../../../Components/Action";
import Icon from "../../../Components/base/icon/icon";
import useFetchDataHooks from "../../../Hooks/FetchDataHooks";
import { useGetAllInterestsQuery } from "../../../Services/interestsApi";
import NavRight from "../../Navbar/NavRight";
import Title from "../../Navbar/Title";
import TableFormat from "../../TableFormat";

function InterestList() {
  const { pageData, isLoading, fetchData, pagination, error } = useFetchDataHooks(
    useGetAllInterestsQuery
  );

  const defaultColumn = useMemo(
    () => ({
      width: 150,
    }),
    []
  );

  const columns = useMemo(
    () => [
      {
        Header: (
          <div className="p-2 hover:bg-gray-200 rounded-full">
            <Icon icon="FILTER" className="flex flex-none" />
          </div>
        ),
        accessor: "filter",
        width: 60,
      },
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Parent ID",
        accessor: "parentId",
      },
      {
        Header: "Actions",
        Cell: ({ row: { original } }: any) => (
          <Action id={original.id} types={[ActionType.Edit]} />
        ),
      },
    ],
    []
  );

  return (
    <div className="flex flex-col h-screen">
      <div className="h-16 bg-gray-200 flex items-center w-full justify-between p-2">
        <Title title="Interests" prePage="/s/users" />
        <NavRight />
      </div>

      <div className="grid grid-cols-12 flex-row lg:flex-col justify-center w-full mb-4 rounded-lg bg-white h-full lg:overflow-x-hidden">
        <div className="lg:col-span-7 col-span-12 border-r">
          {pageData && (
            <TableFormat
              columns={columns}
              data={pageData?.data}
              defaultColumn={defaultColumn}
              loading={isLoading}
              fetchData={fetchData}
              page={pagination}
              error={error}
            />
          )}
        </div>
        <div className="lg:col-span-5 col-span-12 p-3">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default InterestList;
