import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../../../Components";
import Field from "../../../Components/base/field/field";
import Delete from "../../../Components/Buttons/Delete";
import UpdateButton from "../../../Components/Buttons/UpdateButton";
import { Interest } from "../../../Models/interests.model";
import { useCreateInterestsMutation, useDeleteInterestsMutation, useGetAllInterestsQuery, useGetInterestsByIdQuery, useUpdateInterestsMutation } from "../../../Services/interestsApi";
import { toastError, toastSuccess } from "../../../Utils/toast";
import { EditInterestsValidationSchema } from "../../../Utils/validation";
function EditInterests() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [serverError, setServerError] = useState<string>("");
  const [interestsArray, setInterestsArray] = useState<string[]>([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false)

  const [updateInterests, { isSuccess: updateIntererestSuccess }] = useUpdateInterestsMutation();
  const [createInterests, { isSuccess: createIntererestSuccess }] = useCreateInterestsMutation();
  const { data: interestList } = useGetAllInterestsQuery({
    pageSize: 1000
  })
  
  const [deleteInterests, { isSuccess: deleteIntererestSuccess }] = useDeleteInterestsMutation();
  const { data: interests, isSuccess: interestSuccess } = useGetInterestsByIdQuery(id ? id : "", { skip: !id });

  const { register, handleSubmit, setValue, reset, trigger, getValues, formState: { errors } } = useForm<Interest>({
    resolver: yupResolver(EditInterestsValidationSchema)
  });

  useEffect(() => {
    if (interests && id) {
      setValue("id", interests.id);
      setValue("name", interests.name);
      setValue("parentId", interests.parentId);
      trigger()
    } else {
      setValue("id", "");
      setValue("name", "");
      setValue("parentId", "");
    }
  }, [interests, id]);

  useEffect(() => {
    if (interestList?.data) {
      const interestArr = interestList.data.map((int: Interest) => int.id + ', ' + int.name)
      setInterestsArray(interestArr);
    }
  }, [interestList?.data])

  const submitedValue = (values: any) => {
    values.parentId = values.parentId.split(',')[0];
    if (interests?.id && id) {
      updateInterests(values).then((res: any) => {
        if (res?.error) {
          toastError(res.error.data.message);
          setServerError(res.error.data.message);
        } else {
          toastSuccess("Interests updated!");
          navigate("/s/users/interests");
        }
      });
    } else if (!id) {
      createInterests(values).then((res: any) => {
        if (res?.error) {
          toastError(res.error.data.message);
          setServerError(res.error.data.message);
        } else {
          toastSuccess("Interests created!");
          reset();
        }
      });
    }
  };
  useEffect(() => {
    if (deleteIntererestSuccess) {
        toastSuccess('Interests Deleted Successfully')
        navigate("/s/users/interests");
    }
}, [deleteIntererestSuccess]);

  const navigateToList = () => navigate("/s/users/interests");

  return (
    <div className="flex flex-col items-center">
      <div className="font-bold">
        {interests?.id && id && <span>Edit Interests</span>}
        {!id && <span>Create Interests</span>}
      </div>
      <div className="w-80">
        <form onSubmit={handleSubmit(submitedValue)} id="InterestsForm">
          <div className="flex flex-col gap-y-3">
            <div className="pt-5 text-sm font-semibold">
              <label htmlFor="input_ID">Interest ID</label>
              <Field
                {...register("id")}
                defaultValue={interests?.id}
                dot={false}
                error={errors?.id?.message}
                type="id"
                placeholder={"ID *"}
                id="input_ID"
                className="p-1.5"
                disabled={interests?.id && id ? true : false}
              />
            </div>
            <div className="pt-3 text-sm font-semibold">
              <label htmlFor="input_name" className="">
                Interest Name
              </label>
              <Field
                {...register("name")}
                defaultValue={interests?.name}
                dot={false}
                error={errors?.name?.message}
                type="name"
                placeholder={"Interest ID *"}
                id="input_Interest"
                className="p-1.5"
              />
            </div>

            <div className="pt-3 text-sm font-semibold">
              {interests?.parentId ? (
                <label htmlFor="input_name" className="">
                  Parent ID
                </label>
              ) : (
                <></>
              )}
              {interests?.parentId ? (
                <Field
                  type="select"
                  {...register("parentId")}
                  error={errors?.parentId?.message}
                >
                  <option value={interests?.parentId}>
                    {interests?.parentId}
                  </option>
                  {interestsArray.map((interest) => (
                    <option key={"int"+interest} value={interest}>
                      {interest}
                    </option>
                  ))}
                </Field>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="flex flex-col lg:flex-row gap-4 mt-5 justify-center">
            {!id && (
              <Button
                className="bg-amber-400 hover:bg-yellow-600 rounded px-2 py-1 text-sm font-semibold"
                submit
              >
                {!id && "Create"}
              </Button>
            )}
            {interests?.id && id && (
              <>
                <Delete
                  mode="Interest"
                  deleteFunction={deleteInterests}
                  id={getValues("id")}
                  title={getValues("name")}
                  success={deleteIntererestSuccess}
                  afterSuccess="/s/users/interests"
                  screen="interests"
                />
                <UpdateButton
                  formName="InterestsForm"
                  title={interests.name}
                  mode="Interest"
                  value={"Update"}
                />
              </>
            )}

            {interests?.id && id && (
              <Button
                className="bg-amber-400 hover:bg-yellow-600 rounded px-2 py-1 text-sm font-semibold"
                onClick={navigateToList}
              >
                CANCEL
              </Button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

export default EditInterests;
