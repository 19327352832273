import { PagedData } from '../Models/pagedData.model';
import { CancellationPolicy } from '../Models/cancellationPolicy.model';
import { allApis } from "./allApis";

export const cancellationPolicyApi = allApis.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
        getAllCancellationPolicies: builder.query<PagedData<CancellationPolicy>, any>({
            query: page => {
                return {
                    url: "cancellationPolicy/all",
                    params: page,
                };
            },       
            providesTags: ['CancellationPolicy'],
        }),
        getCancellationPolicyById: builder.query<CancellationPolicy, any>({
            query: id => {
                return {
                    url: `cancellationPolicy/${id}`,
                };
            },
            providesTags: ['CancellationPolicy'],

        }),
        updateCancellationPolicy: builder.mutation<CancellationPolicy, any>({
            query: (policies) => {
                return {
                    url: `cancellationPolicy/${policies.id}`,
                    method: 'PUT',
                    body: policies
                };
            },
            invalidatesTags: ['CancellationPolicy'],
        }),
        deleteCancellationPolicy: builder.mutation<CancellationPolicy, any>({
            query: (args) => {
                const {id} =args;
                return {
                    url: `cancellationPolicy/${id}`,
                    method: 'DELETE',
                };
            },
            invalidatesTags: ['CancellationPolicy'],
        }),
        createCancellationPolicy: builder.mutation<CancellationPolicy, any>({
            query: (policies) => {
                return {
                    url: `cancellationPolicy`,
                    method: 'POST',
                    body: policies
                };
            },
            invalidatesTags: ['CancellationPolicy'],
        }),
    })
});

export const { 
    useCreateCancellationPolicyMutation, useDeleteCancellationPolicyMutation, useGetAllCancellationPoliciesQuery, useGetCancellationPolicyByIdQuery, useUpdateCancellationPolicyMutation
  } = cancellationPolicyApi;
