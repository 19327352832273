import { PagedData } from './../Models/pagedData.model';
import { CancellationRequest } from "./../Models/cancellationRequest.model";
import { allApis } from "./allApis";

export const cancellationRequestApi = allApis.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
        getAllCancellationRequest: builder.query<PagedData<CancellationRequest>, any>({
            query: page => {
                return {
                    url: "/cancellationRequest",
                    params: page,
                };
            }
        }),
        getCancellationRequestById: builder.query<CancellationRequest, any>({
            query: id => {
                return {
                    url: `cancellationRequest/${id}`,
                };
            }
        }),
    })
});

export const { useGetAllCancellationRequestQuery, useGetCancellationRequestByIdQuery } = cancellationRequestApi;
