import { useNavigate, useParams } from "react-router-dom";
import { useGetMeetingParticpantIdByMeetingIdQuery } from "../../Services/meetingParticipantApi";
import { useGetSettingValue } from "../../Services/settingReducer";
import defaultImage from '../../Assets/img/userImage.png';
import { getUTFormattedDate } from "../../Utils/datetime";
import Icon from "../../Components/base/icon/icon";

function ViewMeetingPartcipant() {
    const { id } = useParams();
    const baseImageURL = useGetSettingValue('IMAGE_URL');
    const { data: allParticpantData } = useGetMeetingParticpantIdByMeetingIdQuery(id ? id : '')
    const navigate = useNavigate();

    if (!allParticpantData) {
        return <>Loading...</>;
    }

    return (
        
        <>
      <div>
        <div className='flex font-bold text-lg pb-4 justify-between ml-4 '>Participants <Icon icon="CLOSE" onClick={() => navigate("/s/viewMeeting/"+id)}/></div>
            <div className="flex flex-col font-semibold mb-4 rounded-lg overflow-scroll h-[100%] border-2 p-4 pb-2 ml-4">
                <>
                    {allParticpantData?.map((item: any, index:number) => (
                       
                    <>
                        <div className="flex">          
                            {item?.user?.image[0]?.imageName ? <img src={baseImageURL + '/' + item?.user?.image[0]?.imageName} className='w-20 h-20 rounded-full' alt={`Avatar1`} />
                                : <img src={defaultImage} className='w-20 h-20 rounded-full' alt={`Avatar1`} />}
                            <div className="text-sm flex flex-col gap-y-3 px-3 flex-1 ">
                                <div className="grid grid-cols-2">
                                    {item?.user?.firstName === undefined ? 
                                        <>
                                            <FieldTitle title="Email"/>
                                            <div className="flex gap-x-2 justify-end">
                                                {item?.invitedUser?.inviteEmail}
                                            </div>
                                        </>
                                        : 
                                        <>
                                            <FieldTitle title="Name"/>
                                            <div className="flex gap-x-2 justify-end">
                                                {item?.user?.firstName} {item?.user?.lastName}
                                            </div>
                                        </>
                                    }
                                </div>  
                                {item.transaction && !item.meeting.meetingDetails.isFreeAudioMeeting &&
                                    <>
                                        <div className="grid grid-cols-2">
                                            <FieldTitle title="Amount"/>
                                            <div className="flex gap-x-2 justify-end">
                                                {item.transaction.transactionAmount}
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-2">
                                            <FieldTitle title="Paid On"/>
                                            <div className="flex gap-x-2 justify-end">
                                                {getUTFormattedDate(item.transaction.transactionDateTime)}
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-2">
                                            <FieldTitle title="Transaction Status"/>
                                            <div className="flex gap-x-2 justify-end">
                                                {"Yes"}
                                            </div>
                                        </div>
                                    </>}
                                {item.meeting.meetingDetails.isFreeAudioMeeting &&
                                    <div className="grid grid-cols-2">
                                        <FieldTitle title="Free Event"/>
                                        <div className="flex gap-x-2 justify-end">
                                            {item.meeting.meetingDetails.isFreeAudioMeeting ? 'Yes' : 'No'}
                                        </div>

                                    </div>
                                }
                                <div className="grid grid-cols-2">
                                    <FieldTitle title="Joined"/>
                                    <div className="flex gap-x-2 justify-end">
                                        {item.joined === 'Yes' ? 'Yes' : 'No'}
                                    </div>
                                </div>
                                {index === allParticpantData.length-1 ? " "  : <div className="h-0.5 bg-gray-200 m-3"> </div> }
                                
                            </div>
                        </div>
                        
                    </>
                    ))
                    }
                </>
                </div>
                </div>
                
        </>
    )
    function FieldTitle({ title }: { title: string }) {
        return <>{title} </>
      }
}

export default ViewMeetingPartcipant