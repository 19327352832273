import React from 'react';
import { useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { Action, ActionType } from '../../../Components/Action';
import Icon from '../../../Components/base/icon/icon';
import useFetchDataHooks from '../../../Hooks/FetchDataHooks';
import { useGetAllNicknameQuery } from '../../../Services/nicknameApi';
import NavRight from '../../Navbar/NavRight';
import Title from '../../Navbar/Title';
import TableFormat from '../../TableFormat';

function NickNameList() {
    const { pageData, isLoading, fetchData, pagination,error } = useFetchDataHooks(useGetAllNicknameQuery)

    const defaultColumn = React.useMemo(() => ({
    }), []);

    const columns = useMemo(() => [{
        Header: <div className="p-2 hover:bg-gray-200 rounded-full"><Icon icon="FILTER" /></div>,
        accessor: 'filter',
        width: 25,
    }, {
        Header: 'ID',
        accessor: 'id',
        width: 75,
    }, {
        Header: 'Name',
        accessor: 'name',
        width: 150,
    }, {
        Header: "Actions",
        Cell: ({ row: { original } }: any) => (<Action id={original.id} types={[ActionType.Edit]} />),
        width: 70,
    },
    ], []);

    if (isLoading) return <div>Loading</div>

    return (
        <div className='flex flex-col h-screen'>
            <div className='h-16 bg-gray-200 flex items-center w-full justify-between p-2'>
                <Title title="Nick Name" prePage='/s/users' />
                <NavRight />
            </div>
            <div className="grid grid-cols-12 flex-row lg:flex-col justify-center w-full mb-4 rounded-lg bg-white h-full lg:overflow-x-hidden">
                <div className="lg:col-span-7 col-span-12 border-r">
                    <TableFormat columns={columns} data={pageData?.data} defaultColumn={defaultColumn} loading={isLoading} fetchData={fetchData}
                    page={pagination} error={error} />
                </div>
                <div className="lg:col-span-5 col-span-12 p-3"><Outlet /></div>
            </div>
        </div>
    )
}

export default NickNameList
