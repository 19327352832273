
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Action, ActionType } from '../../Components/Action';
import { ComponentType } from '../../Components/Buttons/Delete';
import useFetchDataHooks from '../../Hooks/FetchDataHooks';
import { useGetMeetingsByEventsQuery } from '../../Services/meetingDetailsApi';
import { formatDateTimeFullToTimezone } from '../../Utils/datetime';
import NavRight from '../Navbar/NavRight'
import Title from '../Navbar/Title'
import TableFormat from '../TableFormat';

function ViewEventMeetings() {
  const { id } = useParams();
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const { pageData, isLoading, fetchData, pagination, error } = useFetchDataHooks<any>(useGetMeetingsByEventsQuery, { id: id ? id : '' } as any);

  const defaultColumn = React.useMemo(() => ({
    width: 150,
  }), []);

  const data = useMemo(() => {
    const displayData: any[] = [];
    pageData?.data?.forEach((history: any) => {
      const tempData = { ...history };
      displayData.push({
        col1: tempData.updatedAt,
        col2: tempData.meetingDetails.title,
        col3: tempData?.fromDateTime,
        col4: ' ',
        col5: ' ',
        col6: tempData?.timezone,
        col7: tempData?.noOfParticipants,
        col8: tempData.id,
        col9: tempData.id,
       
      });
    });
    return displayData;
  }, [pageData]);

 const columns = React.useMemo(() => [ {
    Header: 'Host Name',
    accessor: 'firstName',
    Cell: ({ row }: any) => row.original.user?.firstName,
    width: 100,
  }, {
    Header: 'Event Title',
    accessor: 'eventTitle',
    Cell: ({ row }: any) => row.original.meetingDetails.title,
    width: 100,
  }, {
    Header: 'UTC Date & Time',
    accessor: 'fromDateTime',
    width: 100,
  }, {
    Header: 'Host Date & Time',
    accessor: 'createdAt',
    Cell: ({ row }: any) =>( row.original?.createdAt?.replace("T", " ")),
    width: 100,
  }, {
    Header: 'Local Date & Time',
    accessor: 'localDateTime',
    Cell: ({ row }: any) => formatDateTimeFullToTimezone(timezone, row.original?.createdAt?.toString()),
    width: 100,
  }, {
    Header: 'Host Timezone',
    accessor: 'col6',
    Cell: ({ row }: any) => row.original.user.preference.timezone,
    width: 100,
  }, {
    Header: 'Participants',
    accessor: 'noOfParticipants',
    width: 100,
  }, {
    Header: 'Action',
    accessor: 'id',
    Cell: ({ row: { original } }: any) => (<Action id={original.id} types={[ActionType.View]} navigation='s/viewMeeting' title="view" componenetType={ComponentType.Button}/>),
    width: 150,
  }, {
    Header: 'Meeting History',
    accessor: 'meetingId',
    Cell: ({ row: { original } }: any) => (<Action id={original.id} types={[ActionType.View]} navigation='s/meetingHistory' title = "view History" componenetType={ComponentType.Button}/>),
    width: 150,
  },
  ], []);

  if (isLoading){ 
    return <>Loading...</>
  }
    
  return (
    <div className='flex flex-col h-screen'>
      <div className='h-16 bg-gray-200 flex items-center w-full justify-between p-2'>
        <Title title="View Meetings By Events" prePage='/s/events' />
        <NavRight />
      </div>
      <div className="items-center justify-center h-screen w-full font-semibold mb-4 rounded-lg bg-white overflow-auto overflow-x-hidden">
        {pageData?.data?.length === 0 && <div className="h-full flex justify-center items-center text-lg">No Data to Display</div>}
        {pageData?.data?.length > 0 && <TableFormat columns={columns} data={pageData?.data} defaultColumn={defaultColumn} fetchData={fetchData} loading={isLoading} page={pagination} error={error}/>}
      </div>
    </div>
  )

}
export default ViewEventMeetings;