import { Menu, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { useDispatch } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom'
import Icon from '../../Components/base/icon/icon'
import { clearUser } from '../../Services/adminUserReducer';
import { logoutToken } from '../../Services/tokenReducer';
import { store } from '../../Store';

export default function ProfileDropdown() {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logout = () => {
    dispatch(logoutToken());
    dispatch(clearUser());
    navigate('/');

  }
  let currentUser = store.getState().adminUser.value;
  if (!currentUser) return <div>Loading...</div>
  return (
    <div className="mt-1.5">
      <Menu as="div" className="relative inline-block text-left">
        <Menu.Button>
          <div className="p-1 hover:bg-slate-100 rounded-full"><Icon icon='AVATAR_PLACEHOLDER' size='medium' /></div>
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 mt-2 w-44 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="px-1 py-1 ">
              <Menu.Item>
                <NavLink to={`/s/adminUser/userView/` + currentUser?.id} >
                  <div className="group flex w-full items-center rounded-md px-2 py-2 text-sm text-black hover:bg-BeeMG-yellow font-semibold">Your Profile</div>
                </NavLink>
              </Menu.Item>
              <Menu.Item>
                <div className="group flex w-full items-center rounded-md px-2 py-2 text-sm text-black hover:bg-BeeMG-yellow font-semibold" onClick={() => logout()}>Log Out</div>
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  )
}
