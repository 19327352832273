import { useEffect, useMemo } from 'react';
import { useFlexLayout, usePagination, useSortBy, useTable, useFilters } from 'react-table';
import { Pagination } from '../Models/pagination.model';
import loader from '../Assets/img/beemg_logo.gif';
import Icon from '../Components/base/icon/icon';
import { matchSorter } from 'match-sorter';

export function TextColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
}: { column: any }) {
    const count = preFilteredRows.length

    return (
        <div className='block'>
            <input
                value={filterValue || ''}
                onChange={e => {
                    setFilter(e.target.value || undefined)
                }}
                placeholder={`Search ${count} records...`}
                className="w-full h-8 px-2 pr-7 rounded-lg z-0 focus:outline-none focus:border-BeeMG-yellow border text-sm"
            />
        </div>

    )
}

export function SelectColumnFilter({
    column: c,
}: { column: any }) {
    const { filterValue, setFilter, preFilteredRows, id } = c;
    const options = useMemo(() => {
        const options = new Set<any>()
        preFilteredRows?.forEach((row: any) => {
            const value = row.values[id];
            if (value instanceof Array) {
                options.add(row.values[id]?.join(','))
            } else {
                options.add(row.values[id])
            }
        })
        return [...Array.from(options.values())]
    }, [id, preFilteredRows])

    return (
        <select
            className="w-32 h-8 px-2 pr-7 rounded-lg z-0 focus:outline-none border-gray  border text-sm text-transform: capitalize"
            value={filterValue}
            onChange={e => {
                setFilter(e.target.value || undefined)
            }}
        >
            <option value="">All</option>
            {options.map((option, i) => (
                <option key={i} value={option}>
                    {option}
                </option>
            ))}
        </select>
    )
}

export function NumberRangeColumnFilter({
    column: { filterValue = [], preFilteredRows, setFilter, id },
}: { column: any }) {
    const [min, max] = useMemo(() => {
        let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
        let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
        preFilteredRows.forEach((row: any) => {
            min = Math.min(row.values[id], min)
            max = Math.max(row.values[id], max)
        })
        return [min, max]
    }, [id, preFilteredRows])

    return (
        <div
            style={{
                display: 'flex',
            }}
        >
            <input
                value={filterValue[0] || ''}
                type="number"
                onChange={e => {
                    const val = e.target.value
                    setFilter((old = []) => [val ? parseInt(val, 10) : undefined, old[1]])
                }}
                placeholder={`Min (${min})`}
                style={{
                    width: '70px',
                    marginRight: '0.5rem',
                }}
            />
            to
            <input
                value={filterValue[1] || ''}
                type="number"
                onChange={e => {
                    const val = e.target.value
                    setFilter((old = []) => [old[0], val ? parseInt(val, 10) : undefined])
                }}
                placeholder={`Max (${max})`}
                style={{
                    width: '70px',
                    marginLeft: '0.5rem',
                }}
            />
        </div>
    )
}


function fuzzyTextFilterFn(rows: any, id: any, filterValue: any) {
    return matchSorter(rows, filterValue, { keys: [(row: any) => row.values[id]] })
}

fuzzyTextFilterFn.autoRemove = (val: any) => !val

function TableFormat({
    columns, data, defaultColumn, fetchData, loading, page: pageData, tab, error
}: { columns: any, data: any, defaultColumn: any, fetchData?: any, loading: boolean, page: Pagination, tab?: string, error: any }) {
    const filterTypes = useMemo(
        () => ({
            fuzzyText: fuzzyTextFilterFn,
            text: (rows: any, id: any, filterValue: any) => {
                return rows.filter((row: any) => {
                    const rowValue = row.values[id]
                    return rowValue !== undefined
                        ? String(rowValue)
                            .toLowerCase()
                            .startsWith(String(filterValue).toLowerCase())
                        : true
                })
            },
        }),
        []
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        nextPage,
        gotoPage,
        previousPage,
        state: { pageIndex, pageSize },
    } = useTable({
        columns,
        data,
        defaultColumn,
        filterTypes,
        manualPagination: true,
        manualSortBy: true,
        autoResetPage: false,
        autoResetSortBy: false,
        pageCount: pageData?.totalPages,
        initialState: { pageIndex: pageData?.pageIndex, pageSize: pageData?.pageSize },
    }, useFlexLayout,
        useFilters,
        useSortBy,
        usePagination,
    );
    const style = 'w-6 h-6 border rounded-full bg-BeeMG-yellow  hover:shadow-md shadow-sm text-gray-500 font-bold flex items-center justify-center disabled:cursor-not-allowed  disabled:bg-BeeMG-yellow/50';

    useEffect(() => {
        if ((pageIndex !== undefined || pageIndex !== null) && (pageSize !== undefined || pageSize !== null)) {
            fetchData({ pageIndex, pageSize })
        }
    }, [fetchData, pageIndex, pageSize])


    if (loading) {
        return <>
            <div className='flex justify-center items-center'>
                <img src={loader} alt="Loading..." className='opacity-50 w-9/12' />
            </div>
        </>
    }

    if (error) {
        return <>
            <div className='flex justify-center items-center'>
                {error.data?.message}
            </div>
        </>
    }

    return (
        <>
            {pageData?.totalElements !== 0 && pageData?.totalPages !== 0 &&
                <>
                    <table {...getTableProps()} className="w-full">
                        <thead >
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()} className="h-18 border-b py-2 items-center font-bold text-sm px-3">
                                    {headerGroup.headers.map(column => (

                                        <th {...column.getHeaderProps(column.getSortByToggleProps())} className="">
                                            <div className="flex justify-start items-center gap-1">
                                                {column.render('Header')}
                                                {/* <div className='block '>
                                                    {column.isSorted
                                                        ? column.isSortedDesc
                                                            ? <Icon icon="DOWN" size='x-small' />
                                                            : <Icon icon="UP" size='x-small' />
                                                        : <Icon icon="UP_AND_DOWN" size='x-small' />}
                                                </div> */}
                                            </div>
                                            <div className='block flex flex-wrap py-2'>{column.canFilter && column.Filter ? column.render('Filter') : null}</div>
                                        </th>
                                    ))}
                                </tr>

                            ))}
                        </thead>

                        <tbody {...getTableBodyProps()}>
                            {page.map((row: any, i: any) => {
                                prepareRow(row)
                                return (
                                    <tr
                                        {...row.getRowProps()}
                                        className="h-2.6 border-b items-center font-semibold text-sm px-3 hover:shadow-md"
                                    >
                                        {row.cells.map((cell: any) => {
                                            return (
                                                <td {...cell.getCellProps()} className="truncate">{cell.render('Cell')}</td>
                                            )
                                        })}
                                    </tr>
                                )
                            })}

                        </tbody>
                    </table>

                    <div className="flex justify-between px-10 items-center text-BeeMG-dark-gray text-sm h-12 ">
                        <div>
                            <strong>{pageIndex == 0 ? 1 : ((pageIndex) * 15) + 1} - {((pageIndex + 1) * 15) > pageData?.totalElements ? pageData?.totalElements : ((pageIndex + 1) * 15)} of ~  {pageData?.totalElements}{' '}{tab}</strong>
                        </div>
                        <div className="pagination flex gap-x-2 ">
                            <button
                                onClick={() => gotoPage(0)} disabled={!canPreviousPage} className={style}
                            >
                                <Icon icon="DOUBLE_DOWN" size="small" className="rotate-90" />
                            </button>{" "}
                            <button
                                onClick={() => previousPage()} disabled={!canPreviousPage} className={style}
                            >
                                <Icon icon="LEFT" size="x-small" className="p-0.5" />
                            </button>{" "}
                            <div>Page{' '}<strong>{pageIndex + 1} of {pageOptions.length}</strong>{' '}</div>{' '}
                            <button
                                onClick={() => nextPage()} disabled={!canNextPage} className={style}
                            >
                                <Icon icon="RIGHT" size="x-small" className="p-0.5" />
                            </button>{" "}
                            <button
                                onClick={() => gotoPage(pageOptions.length - 1)} disabled={!canNextPage} className={style}
                            >
                                <Icon icon="DOUBLE_DOWN" size="small" className="-rotate-90" />
                            </button>{" "}
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default TableFormat;
