
import { useParams } from 'react-router-dom';
import { Button } from '../../Components';
import { useGetMeetingDetailsByIdQuery } from '../../Services/meetingDetailsApi';
import { useGetSettingValue } from '../../Services/settingReducer';
import { getUTFormattedDate, getUTFormattedTime } from '../../Utils/datetime';
import NavRight from '../Navbar/NavRight'
import Title from '../Navbar/Title'

function ViewEvents() {
    const { id } = useParams();
    const baseImageURL = useGetSettingValue('IMAGE_URL');
    const { data: eventData, isLoading: eventLoading } = useGetMeetingDetailsByIdQuery(id ? id : '', { skip: !id })
    if (eventLoading) {
        return <div>Loading...</div>

    }
    return (
        <div className='flex flex-col h-screen'>
            <div className='h-16 bg-gray-200 flex items-center w-full justify-between p-2'>
                <Title title="View Events" prePage='/s/events' />
                <NavRight />
            </div>
            <div className="flex flex-col  h-screen w-full px-5  bg-gray-200 overflow-hidden ">
                <div className="bg-white h-full w-full p-10 mb-4 rounded-lg overflow-y-auto">

                    <div className="grid grid-cols-12 gap-y-6">
                        <div className="col-span-1 font-semibold">
                            <span>Event Title</span>

                        </div>
                        <div className="col-span-1 font-semibold text-center">:</div>
                        <div className="col-span-10 w-fit pl-2">
                            {eventData?.title}
                        </div>
                        <div className="col-span-1 font-semibold">
                            <span>Hosted By </span>
                        </div>
                        <div className="col-span-1 font-semibold text-center">:</div>
                        <div className="col-span-10 w-fit pl-2">
                            {eventData?.host?.firstName} {eventData?.host?.lastName}
                            {/* {getUTFormattedDate(feedback.createdAt.toString())} */}
                        </div>
                        <div className="col-span-1 font-semibold">
                            <span>Created At </span>
                        </div>
                        <div className="col-span-1 font-semibold text-center">:</div>
                        <div className="flex-wrap col-span-10 pl-2">
                            {eventData && getUTFormattedDate(eventData.createdAt.toString()) + " " + getUTFormattedTime(eventData.createdAt.toString())}

                        </div>
                        <div className="col-span-1 font-semibold">
                            <span>Categories </span>
                        </div>
                        <div className="col-span-1 font-semibold text-center">:</div>
                        <div className="col-span-10 w-fit ">
                            {eventData?.categories?.map((item) => (
                                <Button className="px-4 pr-4 py-1 normal-case bg-BeeMG-yellow cursor-default" size='sm'><span className="font-bold">{item}</span></Button>
                            ))}
                        </div>
                        <div className="col-span-1 font-semibold">
                            <span>Kids Friendly </span>
                        </div>
                        <div className="col-span-1 font-semibold text-center">:</div>
                        <div className="col-span-10 w-fit pl-2.5">
                            {eventData?.kidFriendly ? 'Yes' : 'No'}
                        </div>
                        <div className="col-span-1 font-semibold">
                            <span>Kids Only </span>
                        </div>
                        <div className="col-span-1 font-semibold text-center">:</div>
                        <div className="col-span-10 w-fit pl-2.5 ">
                            {eventData?.kidFriendly ? 'Yes' : 'No'}
                        </div>
                        <div className="col-span-1 font-semibold">
                            <span>Auto Delete</span>
                        </div>
                        <div className="col-span-1 font-semibold text-center">:</div>
                        <div className="col-span-10 w-fit pl-2.5 ">
                            {eventData?.autoDelete ? 'Yes' : 'No'}
                        </div>
                        <div className="col-span-1 font-semibold">
                            <span>Anonymous Events</span>
                        </div>
                        <div className="col-span-1 font-semibold text-center">:</div>
                        <div className="col-span-10 w-fit pl-2.5">
                            {eventData?.isAnonymous ? 'Yes' : 'No'}
                        </div>
                        <div className="col-span-1 font-semibold">
                            <span>Audio/Video Event</span>
                        </div>
                        <div className="col-span-1 font-semibold text-center">:</div>
                        <div className="col-span-10 w-fit pl-2.5 ">
                            {eventData?.videoType}
                        </div>
                        <div className="col-span-1 font-semibold">
                            <span>Free Event</span>
                        </div>
                        <div className="col-span-1 font-semibold text-center">:</div>
                        <div className="col-span-10 w-fit pl-2.5 ">
                            {eventData?.isFreeAudioMeeting ? 'Yes' : 'No'}
                        </div>
                        <div className="col-span-1 font-semibold">
                            <span>Cancellation Policy</span>
                        </div>
                        <div className="col-span-1 font-semibold text-center">:</div>
                        <div className="col-span-10 w-fit pl-2.5 ">
                            {eventData?.isFreeAudioMeeting? 'No' : eventData?.cancellationPolicy?.policyType }
                        </div>                       
                        {eventData?.image && (eventData?.image.length >= 1) &&
                            <>
                                <div className="col-span-1 font-semibold">
                                    <span>Meeting Images</span>
                                </div>
                                <div className="col-span-1 font-semibold text-center">:</div>
                                <div className="col-span-10 w-fit flex flex-row ">
                                    {eventData?.image.map((image: any) => (
                                        <img src={baseImageURL + '/' + image.imageName} alt="meeting" className="w-20 h-20 px-2" />
                                    ))}
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
export default ViewEvents;