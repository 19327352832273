import { Fragment, useState } from 'react'
import { Combobox, Dialog, Transition } from '@headlessui/react'
import Icon from '../../Components/base/icon/icon'
import { Group, Name } from '../../Models/group.model'
import Field from '../../Components/base/field/field'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { SpecificPercentageValidationSchema } from '../../Utils/validation'
import { Button } from '../../Components'
import { toastError, toastSuccess } from '../../Utils/toast'

interface ISpecific {
    commissionPercentage: number;
}

function DropDown({ items, group, updateCommissionPercentage }: { items: string[], group: Group, updateCommissionPercentage: any }) {

    const tGroup = Object.assign({}, group)
    const [isOpen, setIsOpen] = useState(false);

    const { register, handleSubmit, reset, formState: { errors } } = useForm<ISpecific>({ resolver: yupResolver(SpecificPercentageValidationSchema) });

    const [selected, setSelected] = useState(group?.name)
    const [query, setQuery] = useState('')

    const filtereditems =
        query === ''
            ? items
            : items.filter((groupname) =>
                groupname
                    .toLowerCase()
                    .replace(/\s+/g, '')
                    .includes(query.toLowerCase().replace(/\s+/g, ''))
            )

    const handleChange = (item: any) => {
        if (item !== Name.Specific) {
            tGroup.name = item
            updateCommissionPercentage(tGroup).then((res:any) => {
                if(res?.error) {
                    toastError(res.error.data.message)
                }
                else {
                    toastSuccess("Commission Percentage updated!")
                    setIsOpen(false)
                }
            })
        }
        else {
            setIsOpen(true)
        }
    }
    const handleSpecificChange = (val: any) => {
        tGroup.name = Name.Specific
        tGroup.commissionPercentage = parseFloat(val.commissionPercentage)
        updateCommissionPercentage(tGroup).then((res:any) => {
            if(res?.error) {
                toastError(res.error.data.message)
            }
            else {
                toastSuccess("Commission Percentage updated!")
                setIsOpen(false)
            }
        })
    }

    return (
        <div className="flex flex-col col-span-7">
            <Combobox value={selected} onChange={(item: any) => { setSelected(item); handleChange(item) }}>
                <div className="relative mt-1">
                    <Combobox.Input
                        className=" leading-5 relative flex flex-1 w-full rounded-lg p-input bg-white text-black-700 md:text-base p-2 placeholder:text-sm placeholder-gray-400 text-sm focus:outline-none focus:ring-1 focus:ring-BeeMG-yellow focus:border-transparent border border-gray-300 h-8"
                        displayValue={(groupname: any) => groupname}
                        onChange={(event) => setQuery(event.target.value)}
                    />
                    <Combobox.Button className="absolute top-1 right-1 flex items-center">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 21L6.75 15.75L7.8075 14.6925L12 18.8775L16.1925 14.6925L17.25 15.75L12 21ZM12 3L17.25 8.25L16.1925 9.3075L12 5.1225L7.8075 9.3075L6.75 8.25L12 3Z" fill="#929292" />
                        </svg>
                    </Combobox.Button>
                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        afterLeave={() => setQuery('')}
                    >
                        <Combobox.Options className="relative cursor-default select-none p-1 h-fit bg-white border rounded-lg border-t-0 rounded-t-none overflow-y-auto Srounded-lg ${active ? 'bg-BeeMG-yellow text-black' : 'text-black-900">
                            {filtereditems.length === 0 && query !== '' ? (
                                <div className="relative cursor-default select-none py-2 px-4 text-black-700">
                                    Nothing found.
                                </div>
                            ) : (
                                filtereditems.map((groupname, index) => (
                                    <Combobox.Option
                                        key={index}
                                        className={({ active }) =>
                                            `relative cursor-default select-none py-2 px-5  mx-1 rounded-lg ${active ? 'bg-BeeMG-yellow text-black' : 'text-gray-900'
                                            }`
                                        }
                                        value={groupname}
                                    >
                                        {({ selected, active }) => (
                                            <>
                                                <span className={`block truncate pl-3 ${selected ? 'font-medium' : 'font-normal'}`}>
                                                    {groupname}
                                                </span>
                                                {selected ? (
                                                    <span className={`absolute inset-y-0 left-0 ml-2 flex items-center ${active ? 'text-white' : 'text-teal-600'}`}>
                                                        <Icon icon="TICK" />
                                                    </span>
                                                ) : null}
                                            </>
                                        )}
                                    </Combobox.Option>
                                ))
                            )}
                        </Combobox.Options>
                    </Transition>
                </div>
            </Combobox>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={()=>setIsOpen(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                        Enter your commission percentage
                                    </Dialog.Title>
                                    <div className="mt-2">
                                        <form onSubmit={handleSubmit(handleSpecificChange)} id="my-form">
                                            <Field
                                                {...register('commissionPercentage')} error={errors?.commissionPercentage?.message} type="text"
                                                placeholder={'Commission Percentage'} id="commissionPercentage" className='p-2 h-9'
                                            />
                                        </form>
                                    </div>
                                    <div className="mt-4 flex justify-end gap-3">
                                        <button
                                            type="button"
                                            className="inline-flex rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                            onClick={() => { setIsOpen(false); reset() }}
                                        >
                                            Cancel
                                        </button>
                                        <Button
                                            className="inline-flex justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 text-sm font-medium text-red-900 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                                            form='my-form'
                                            submit
                                        > Submit </Button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div>
    )
}
export default DropDown