

import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom';
import useFetchDataHooks from '../../Hooks/FetchDataHooks';
import { useGetMeetingDetailsByUserIdQuery } from '../../Services/meetingDetailsApi';
import NavRight from '../Navbar/NavRight'
import Title from '../Navbar/Title'
import TableFormat from '../TableFormat';
import Icon from '../../Components/base/icon/icon';
import { Action, ActionType } from '../../Components/Action';

function ViewUserEvents() {

  const { id } = useParams();
  const { pageData, isLoading, fetchData, pagination, error } = useFetchDataHooks<any>(useGetMeetingDetailsByUserIdQuery, { id: id ? id : '' } as any);

  const defaultColumn = useMemo(() => ({}), []);


  const columns = useMemo(() => [{
    Header: <div className="p-2 hover:bg-gray-200 rounded-full"><Icon icon="FILTER" /></div>,
    accessor: 'filter',
    width: 30,
  },
  {
    Header: 'Event Title',
    accessor: 'title',
  }, {
    Header: 'Audio/Video Type',
    accessor: 'videoType',
  }, {
    Header: 'Free Meeting',
    accessor: 'isFreeAudioMeeting',
    Cell: ({ row }: any) => row.original.isFreeAudioMeeting ? 'Yes' : 'No',
  }, {
    Header: 'Kids Only',
    accessor: 'kidsOnly',
  }, {
    Header: 'Categories',
    accessor: 'categories',
  },
  {
    Header: 'Meeting Details Id',
    accessor: 'meetingDetailsId',
  },
  {
    Header: 'Action',
    accessor: 'action',
    Cell: ({ row: { original } }: any) => <Action id={original.id} types={[ActionType.View]} navigation='s/eventHistory' />,
  },

  ], []);

  if (isLoading){ 
    return <>Loading...</>
  }

  return (
    <div className='flex flex-col h-screen'>
      <div className='h-16 bg-gray-200 flex  w-full justify-between p-2'>
        <Title title="User Events" prePage={`/s/users/userView/${id}`} />
        <NavRight />
      </div>
      <div className="items-center justify-center h-screen w-full font-semibold mb-4 rounded-lg bg-white overflow-auto overflow-x-hidden">
        <>
        {((pageData?.data?.length === 0) || pageData === undefined) && <div className="h-full flex justify-center items-center text-lg">No Data to Display</div>}
        {pageData?.data?.length > 0 && <TableFormat columns={columns} data={pageData?.data} defaultColumn={defaultColumn} fetchData={fetchData} loading={isLoading} page={pagination} error={error}/>}
        </>
      </div>
    </div>
  )
}

export default ViewUserEvents