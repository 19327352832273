import React from 'react'
import { useParams } from 'react-router-dom';
import { useGetAllTransactionsByIdQuery } from '../../Services/transactionApi';
import { getUTFormattedDate, getUTFormattedTime } from '../../Utils/datetime';
import NavRight from '../Navbar/NavRight'
import Title from '../Navbar/Title'

function ViewTransaction() {
    const { id } = useParams();
    const { data: transData } = useGetAllTransactionsByIdQuery(id ? id : '', { skip: !id })

    return (
        <div className='flex flex-col h-screen'>
            <div className='h-16 bg-gray-200 flex items-center w-full justify-between p-2'>
                <Title title="View Transaction" prePage='/s/transactions' />
                <NavRight />
            </div>
            <div className="flex flex-col  h-screen w-full px-5  bg-gray-200 overflow-hidden">
                <div className="bg-white h-full w-full p-10 mb-4 rounded-lg overflow-y-auto">
                    <div className="grid grid-cols-12 gap-y-6">
                        <div className="col-span-3 font-semibold">
                            <span>Event Title</span>
                        </div>
                        <div className="col-span-1 font-semibold text-center">:</div>
                        <div className="col-span-7 w-fit">
                            {transData?.meeting?.meetingDetails?.title}
                        </div>
                        <div className="col-span-3 font-semibold">
                            <span>Guest Count </span>
                        </div>
                        <div className="col-span-1 font-semibold text-center">:</div>
                        <div className="col-span-7 w-fit">
                            {transData?.guestCount}
                        </div>
                        <div className="col-span-3 font-semibold">
                            <span>Transaction Date </span>
                        </div>
                        <div className="col-span-1 font-semibold text-center">:</div>
                        <div className="col-span-7 w-fit">
                            {transData && getUTFormattedDate(transData.transactionDateTime.toString()) + " " + getUTFormattedTime(transData.transactionDateTime.toString())}
                        </div>
                        <div className="col-span-3 font-semibold">
                            <span>Transaction Description </span>
                        </div>
                        <div className="col-span-1 font-semibold text-center">:</div>
                        <div className="col-span-7 w-fit ">
                            {transData?.transactionDescription}
                        </div>
                        <div className="col-span-3 font-semibold">
                            <span>Transaction Type </span>
                        </div>
                        <div className="col-span-1 font-semibold text-center">:</div>
                        <div className="col-span-7 w-fit ">
                            {transData?.transactionType}
                        </div>
                        <div className="col-span-3 font-semibold">
                            <span>Transaction Status </span>
                        </div>
                        <div className="col-span-1 font-semibold text-center">:</div>
                        <div className="col-span-7 w-fit ">
                            {transData?.transactionStatus}
                        </div>
                        {transData?.previousTransactions &&
                            <><div className="col-span-3 font-semibold">
                                <span>Previous Transactions</span>
                                <span> Transaction Id</span>
                            </div><div className="col-span-1 font-semibold text-center">:</div><div className="col-span-7 w-fit ">
                                    {transData?.previousTransactions?.id}
                                </div><div className="col-span-3 font-semibold">
                                    <span>Transaction Amount</span>
                                </div><div className="col-span-1 font-semibold text-center">:</div><div className="col-span-7 w-fit ">
                                    {transData?.previousTransactions?.transactionAmount}
                                </div><div className="col-span-3 font-semibold">
                                    <span>Transaction Id</span>
                                </div><div className="col-span-1 font-semibold text-center">:</div><div className="col-span-7 w-fit ">
                                    {transData?.previousTransactions?.transactionId}
                                </div><div className="col-span-3 font-semibold">
                                    <span>Original Guest Count</span>
                                </div><div className="col-span-1 font-semibold text-center">:</div><div className="col-span-7 w-fit ">
                                    {transData?.previousTransactions?.originalGuestCount}
                                </div></>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewTransaction