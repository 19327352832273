import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { Button } from '../../Components'
import Field from '../../Components/base/field/field'
import Delete from '../../Components/Buttons/Delete'
import UpdateButton from '../../Components/Buttons/UpdateButton'
import { useUpdateFeedbackCategoryMutation, useDeleteFeedbackCategoryMutation, useGetFeedbackCategoryByIdQuery, useCreateFeedbackCategoryMutation } from '../../Services/contactFeedbackCategoryApi'
import { toastError, toastSuccess } from '../../Utils/toast'
import { feedbackCategoryValidationSchema } from '../../Utils/validation'

interface IEditFeedBackCategory {
    id: string;
    categoryName: string;
}

function EditFeedBackCategory() {

    const navigate = useNavigate();
    const { id } = useParams();

    const [serverError, setServerError] = useState<string>('');

    const { register, handleSubmit, setValue, getValues, reset, trigger, formState: { errors } } = useForm<IEditFeedBackCategory>({ resolver: yupResolver(feedbackCategoryValidationSchema) });

    const [createFeedbackCategory] = useCreateFeedbackCategoryMutation();
    const [updateFeedbackCategory] = useUpdateFeedbackCategoryMutation();
    const [deleteFeedbackCategory, { isSuccess: deleteFeedbackCategorySuccess }] = useDeleteFeedbackCategoryMutation();

    const { data: feedbackCategory } = useGetFeedbackCategoryByIdQuery(id ? id : '', { skip: !id });

    const submitedValue = (values: any) => {

        if (feedbackCategory?.id && id) {
            updateFeedbackCategory(values).then((res: any) => {
                if (res?.error) {
                    toastError(res.error.data.message)
                    setServerError(res.error.data.message);
                } else {
                    toastSuccess("Feedback Category updated!")
                    navigate('/s/feedbacks/feedbackCategory');
                }
            });
        } else if (!id) {
            createFeedbackCategory(values).then((res: any) => {
                if (res?.error) {
                    toastError(res.error.data.message)
                    setServerError(res.error.data.message);
                } else {
                    toastSuccess("Feedback Category created!")
                    reset()
                }
            })
        }
    }

    useEffect(() => {
        if (feedbackCategory && id) {
            setValue('id', feedbackCategory.id);
            setValue('categoryName', feedbackCategory.categoryName);
            trigger()
        } else {
            setValue('id', '');
            setValue('categoryName', '');
        }

    }, [feedbackCategory, id]);

    useEffect(() => {
        if (deleteFeedbackCategorySuccess) {
            toastSuccess("Feedback Category deleted!")
            navigate("/s/feedbacks/feedbackCategory")
        }
    }, [deleteFeedbackCategorySuccess]);

    return (
        <div className='w-full flex flex-col items-center'>
            <div className="font-bold">{feedbackCategory?.id && id ? "Edit Feedback Category" : "Create Feedback Category"}</div>
            <div className='w-8/12'>
                <form onSubmit={handleSubmit(submitedValue)} id="feedbackCategoryForm">
                    <div className="flex flex-col gap-y-3">
                        <div>
                            <label htmlFor="categoryId" className='text-xs uppercase font-semibold'>ID</label>
                            <Field
                                {...register('id')} error={errors?.id?.message} type="text"
                                placeholder={'ID'} id="categoryId" className='p-2 h-9' disabled={feedbackCategory?.id && id ? true : false}
                            />
                        </div>
                        <div>
                            <label htmlFor="categoryName" className='text-xs uppercase font-semibold'>Category Name</label>
                            <Field
                                {...register('categoryName')} error={errors?.categoryName?.message} type="text"
                                placeholder={`Category Name`} id="categoryName" className='p-2 h-9'
                            />
                        </div>
                    </div>
                    <div className='flex flex-col lg:flex-row gap-4 mt-5 justify-center'>
                        {feedbackCategory?.id && id &&
                            <>
                                <Button className="shadow-md text-sm py-1 bg-BeeMG-yellow" id="saveAndUpload" onClick={() => navigate("/s/feedbacks/feedbackCategory")}>
                                    <div className='whitespace-nowrap'>Create New</div>
                                </Button>
                                <UpdateButton formName='feedbackCategoryForm' title={feedbackCategory.categoryName} mode='Feedback Category' value={'Update'} />
                                <Delete mode="Feedback Category"
                                    deleteFunction={deleteFeedbackCategory}
                                    id={getValues('id')}
                                    title={getValues('categoryName')}
                                    success={deleteFeedbackCategorySuccess}
                                    afterSuccess="/s/feedbacks/feedbackCategory" />
                            </>
                        }
                        {!id &&
                            <Button className="shadow-md text-sm py-1 bg-BeeMG-yellow" id="saveAndUpload" submit>
                                <div className='whitespace-nowrap'>Create New</div>
                            </Button>
                        }
                    </div>
                </form>
            </div>
        </div>
    )
}

export default EditFeedBackCategory