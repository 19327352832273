import { PagedData } from '../Models/pagedData.model';
import { EmailTemplate } from "../Models/emailTemplate.model";
import { allApis } from "./allApis";

export const emailTemplate = allApis.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
        getAllEmailTemplate: builder.query<PagedData<EmailTemplate>, any>({
            query: page => {
                return {
                    url: "emailTemplate",
                    params: page,
                };
            }
        }),
        getEmailTemplateById: builder.query<EmailTemplate, any>({
            query: id => {
                return {
                    url: `emailTemplate/viewMeeting/${id}`,
                };
            }
        }),
    })
});

export const { useGetAllEmailTemplateQuery, useGetEmailTemplateByIdQuery } = emailTemplate;
