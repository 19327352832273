import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom'
import { Button } from '../../../Components';
import Field from '../../../Components/base/field/field';
import UpdateButton from '../../../Components/Buttons/UpdateButton';
import { Nickname } from '../../../Models/nickname.model';
import { useCreateNicknameMutation, useGetNicknameByIdQuery, useUpdateNicknameMutation } from '../../../Services/nicknameApi';
import { toastError, toastSuccess } from '../../../Utils/toast';
import { EditNickNameValidationSchema, } from '../../../Utils/validation';

function EditNickName() {
    const navigate = useNavigate();
    const { id } = useParams();

    const [serverError, setServerError] = useState<string>('');

    const [updateNickName, { isSuccess: updateNickNameSuccess }] = useUpdateNicknameMutation();
    const [createNickName, { isSuccess: createNickameSuccess }] = useCreateNicknameMutation();

    const { data: nickName, isSuccess: nickNameSuccess } = useGetNicknameByIdQuery(id ? id : '', { skip: !id });
    const { register, handleSubmit, setValue, reset, trigger, formState: { errors } } = useForm<Nickname>({ resolver: yupResolver(EditNickNameValidationSchema) });

    useEffect(() => {
        if (nickName && id) {
            setValue("id", nickName.id);
            setValue("name", nickName.name)
            trigger()
        } else {
            setValue("id", "");
            setValue("name", "")
        }
    }, [nickName, id]);

    const onSubmit = (values: any) => {
        if (nickName?.id && id) {
            updateNickName(values).then((res: any) => {
                if (res?.error) {
                    toastError(res.error.data.message)
                    setServerError(res.error.data.message);
                } else {
                    toastSuccess("Nickname updated!")
                    navigate('/s/users/nickName');
                }
            });
        } else if (!id) {
            createNickName(values).then((res: any) => {
                if (res?.error) {
                    toastError(res.error.data.message)
                    setServerError(res.error.data.message);
                } else {
                    toastSuccess("Nickname created!")
                    reset()
                }
            })
        }

    }

    const navigateToList = () => navigate("/s/users/nickName");

    return (
        <div className="flex flex-col items-center">
            <div className="font-bold">
                {nickName?.id && id && <span>Edit NickName</span>}
                {!id && <span>Create NickName</span>}
            </div>
            <div className='w-80'>
                <form onSubmit={handleSubmit(onSubmit)} id="NickNameForm">
                    <div className="flex flex-col gap-y-3">
                        <div className="pt-5 text-sm font-semibold">
                            <label htmlFor="input_ID" >ID</label>
                            <Field
                                {...register('id')}
                                dot={false}
                                error={errors?.id?.message}
                                type="id"
                                name="id"
                                placeholder={'ID *'} id="input_ID" className='p-1.5'
                                disabled={nickName?.id && id ? true : false}
                            />
                        </div>
                        <div>

                            <div className=" pt-4 text-sm font-semibold">
                                <label htmlFor="input_name" >NickName</label>
                                <Field
                                    {...register('name')}
                                    dot={false}
                                    error={errors?.name?.message}
                                    type="name"
                                    name="name"
                                    placeholder={'NickName *'}
                                    id="input_NickName"
                                    className='p-1.5'
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col lg:flex-row gap-4 mt-5 justify-center">
                        {!id &&
                            <Button className="bg-amber-400 hover:bg-yellow-600 rounded px-4 py-1 text-sm font-semibold" submit>
                                {!id && "Create"}
                            </Button>}

                        {nickName?.id && id && (
                            <UpdateButton formName='NickNameForm' title={nickName.name} mode='NickName' value={nickName?.id && id ? 'Update' : 'Create'} />
                        )}

                        {nickName?.id && id && (
                            <Button className="bg-amber-400 hover:bg-yellow-600 rounded px-4 py-1 text-sm font-semibold" onClick={navigateToList}>
                                CANCEL
                            </Button>)}

                    </div>
                </form>
            </div>
        </div>
    )
}

export default EditNickName
