import { AdminUser } from './../Models/adminUser.model';
import { createSlice} from "@reduxjs/toolkit";
import { RootState } from "../Store";

const initialStateValue = "";

export const adminUserSlice = createSlice({
    name: "adminUser",
    initialState: { 
        value: initialStateValue
    },
    reducers: {
        changeUser: (state, action) => {
            state.value = action.payload;
        },
        clearUser: (state) => {
            state.value = initialStateValue;
        },
    }
});

export const getUser = (state: RootState): AdminUser => state.adminUser?.value

export const { changeUser, clearUser } = adminUserSlice.actions;
export default adminUserSlice.reducer;
