import { Avatar } from "../Models/avatar.model";
import { PagedData } from "../Models/pagedData.model";
import { allApis } from "./allApis";

export const avatarsTransform = (avatars: PagedData<Avatar>) => {
    if (avatars.data.length > 0) {
        avatars.data = avatars.data.map(avatar => {
            return avatarTransform(avatar)
        })
    }
  
    return avatars;
}

const avatarTransform = (avatar: Avatar) => {
    avatar.action = avatar.id;
    return avatar;
}

export const avatarApi = allApis.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
        getAllAvatarNames: builder.query<PagedData<Avatar>, any>({
            query: page => {
                return {
                    url: "avatar/avatarNameListPagination",
                    params: page
                };
            },
            transformResponse: (response: PagedData<Avatar>) => avatarsTransform(response),
            providesTags: ['Avatar']
        }),
        getById: builder.query<Avatar, string>({
            query: id => {
                return {
                    url: `avatar/${id}`,
                };
            }
        }),
        uploadAvatarFile: builder.mutation<Avatar, any>({
            query: (args) => {
                const { file, id, name } = args;
                let testData: FormData = new FormData();
                testData.append('file', file, name);

                return {
                    url: `avatar/file/upload/${id}/${name}`,
                    method: 'POST',
                    body: testData
                };
            },
            invalidatesTags: ['Avatar']
        }),
        deleteAvatar: builder.mutation<boolean, any>({
            query: (args) => {
                const {id} = args;
                return {
                    url: `avatar/${id}`,
                    method: 'DELETE'
                };
            },
            invalidatesTags: ['Avatar'],
        }),
    })
});

export const { useGetAllAvatarNamesQuery, useUploadAvatarFileMutation, useDeleteAvatarMutation, useGetByIdQuery } = avatarApi;