import { PagedData } from './../Models/pagedData.model';
import { Meeting } from "../Models/meeting.model";
import { allApis } from "./allApis";

export const meetingApi = allApis.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
        getAllMeetings: builder.query<PagedData<Meeting>, any>({
            query: page => {
                return {
                    url: "meeting/all/meeting",
                    params: page,
                };
            }
        }),
        getMeetingById: builder.query<Meeting, any>({
            query: id => {
                return {
                    url: `meeting/viewMeeting/${id}`,
                };
            },
            providesTags: ['meetingData'],
        }),
        
        getMeetingHistoryById: builder.query<PagedData<Meeting>, any>({
            query: page => {
                return {
                    url: `meetingHistory/viewHistory/${page.id}`,
                    params: page,
                };
            }
        }),
        deleteMeetingData: builder.mutation<Meeting, any>({
            query: (args) => {
                const {id} = args;
                return {
                    url: `meeting/deleteByAdmin/${id}`,
                    method: 'DELETE',
                };
            },
            invalidatesTags: ['meetingData'],
        }),
        saveMeeting: builder.mutation<Meeting, any>({
            query: (meeting) => {
                return {
                    url: `meeting/updateMeetingDetails/${meeting.id}`,
                    method: 'PUT',
                    body: meeting,
                };
            },
            // invalidatesTags: ['saveMeeting'],
        }),
    })
});

export const { useGetAllMeetingsQuery, useGetMeetingByIdQuery, useGetMeetingHistoryByIdQuery, useDeleteMeetingDataMutation, useSaveMeetingMutation } = meetingApi;
